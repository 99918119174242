import { Form, Input, Table, Typography } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

interface Item {
  _id: string;
  language: string;
  title: string;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = <Input />;
  const { t } = useTranslation();
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0, padding: 0 }}
          rules={[
            {
              required: true,
              message: t('please-input-title', { title: title }),
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Translations = (params: { setTranslations: any; data?: any }) => {
  const table = useRef(null);
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);
  const [languagesLocal, setLanguagesLocal] = useState([] as any);
  const { deployment } = useContext(AppBuilderContext);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const { t } = useTranslation();

  ///start inline edit
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: any) => record.language === editingKey;

  const edit = (record: Partial<any> & { key: React.Key }) => {
    form.setFieldsValue({ title: '', ...record });
    setEditingKey(record.language ?? '');
  };
  const save = async (rowItem) => {
    try {
      const row = (await form.validateFields()) as Item;
      const newData = [...languagesLocal];
      const index = newData.findIndex((item) => item.language === rowItem);

      
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          title: row.title,
        });
        console.log(newData);
        setLanguagesLocal(newData);
        params.setTranslations(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setLanguagesLocal(newData);
        params.setTranslations(newData);
        setEditingKey('');
      }
    } catch (errInfo) {}
  };

  const LanguagesColumns = [
    {
      title: t('language'),
      dataIndex: 'language',
      width: '20%',
    },
    {
      title: t('title'),
      dataIndex: 'title',
      editable: true,
      width: '60%',
    },
    {
      title: t('action'),
      dataIndex: 'operation',
      width: 100,
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.language)} style={{ marginRight: 8 }}>
              {t('save')}
            </Typography.Link>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            {t('edit')}
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = LanguagesColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        // onBlur: () => save(record.language as any),
        // onClick: () => edit(record as any),
      }),
    };
  });

  useEffect(() => {
    setIsLoadingLanguages(true);
    var languagesIn = languagesDeployment as any;
    var languages = [] as any;
    languagesIn.forEach((language: any, index: any) => {
      var title = params.data?.find((l) => l.language === language.name)?.title ?? t('edit-to-add-your-text');
      languages.push({
        language: language.name,
        title: title,
      });
    });
    params.setTranslations(languages);
    setLanguagesLocal(languages);
    setIsLoadingLanguages(false);
  },[params.data]);

  return (
    <div>
      <div className="row mt-3">
        <div className="col-md-12">
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              rowClassName="editable-row"
              rowKey={(languagesLocal) => languagesLocal.language}
              loading={isLoadingLanguages}
              columns={mergedColumns}
              dataSource={languagesLocal}
              key={JSON.stringify(languagesLocal)}
              pagination={{
                position: ['bottomLeft'],
                pageSize: 10,
                hideOnSinglePage: true,
                showTotal: (total, range) => {
                  return `${range[0]}-${range[1]} of ${total} items`;
                },
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};
export default Translations;
