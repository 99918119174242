import { Button, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

type UpdateModalProps = {
  visible: boolean;
  onSubmit: (values: any, transcoder: any, row: any) => void;
  onCancel: () => void;
  row: any;
  isUpdating: boolean;
};
const UpdateModal = (props: UpdateModalProps) => {
  const { visible, onSubmit, onCancel, row, isUpdating } = props;
  const { t } = useTranslation();
  const [transcoder, setTranscoder] = useState('');
  let [form] = useForm();
  useEffect(() => {
    setTranscoder(row.type);
    form.setFieldsValue(row);
  }, [row]);

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={800} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="mb-4">{t('update-transcoder')}</h4>
            <Form
              initialValues={{
                name: '',
                quantity: 0,
                type: '',
              }}
              form={form}
              onFinish={(values) => onSubmit(values, transcoder, row)}
              onFinishFailed={(errorInfo) => {}}
            >
              <div className="row-md-12 ml-4">
                <label className="col-md-5 col-form-label"></label>
                <div className="col">
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-name')}</label>
                    <Form.Item
                      className="col-md-6"
                      name={['name']}
                      rules={[
                        {
                          required: true,
                          message: t('name-is-required'),
                        },
                      ]}
                    >
                      <Input className="form-control bg-white font-size-14 text-default" placeholder={t('like-usa-one')} />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-url')}</label>
                    <Form.Item
                      className="col-md-6"
                      name={['url']}
                      rules={[
                        {
                          required: true,
                          message: t('url-is-required'),
                        },
                      ]}
                    >
                      <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder={'https://transcoder.com'} />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-username')}</label>
                    <Form.Item className="col-md-6" name={['user']}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-password')}</label>
                    <Form.Item className="col-md-6" name={['pass']}>
                      <Input type={'password'} className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-retention')}</label>
                    <Form.Item extra={t('number-of-days-the-recording-stays-on-the-server-recordings-set-by-customers-older-than-the-set-retention-will-automatically-be-deleted')} className="col-md-6" name={['retention']}>
                      <Input type={'number'} className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-type')}</label>
                    <Form.Item
                      className="col-md-4"
                      name={['type']}
                      rules={[
                        {
                          required: true,
                          message: t('version-is-required'),
                        },
                      ]}
                    >
                      <Select defaultValue={transcoder} onChange={(value) => setTranscoder(value)} className="default-select" placeholder={t('select-type')}>
                        <Select.Option value={'Flussonic V2'} key={'Flussonic V2'}>
                          {t('flussonic-v2')}
                        </Select.Option>
                        <Select.Option value={'Flussonic V3'} key={'Flussonic V3'}>
                          {t('flussonic-v3')} version before 24.11
                        </Select.Option>
                        <Select.Option value={'Flussonic V3+'} key={'Flussonic V3'}>
                          {t('flussonic-v3')} version from 24.11
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="col-md-12 p-0">
                <Button loading={isUpdating} disabled={isUpdating} htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('update-transcoder')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateModal;
