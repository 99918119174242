import { Button, Form, Input, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import PayPerView from '../../../../../../common/components/Content/PayPerView/PayPerView';
import Translations from '../../../../../../common/components/Content/Translations/Translations';
import ImageUploader from '../../../../../../common/components/ImageUploader/ImageUploader';
import { Translations as TRLNS } from '../../../../../../interfaces/responses/areas/content/shared/translations';
import cmsService from '../../../../../../services/areas/content/games/games';
import { IMSNameParams } from '../../../../../dashboard';
import Tags from '../../../../../../common/components/Content/Tags/Tags';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { STORAGE_ENDPOINTS } from '../../../../../../services/storage_paths';
import { Games } from '../../../../../../interfaces/forms/areas/content/games/games';
import Placement from '../../../../../../common/components/Content/Placement/Placement';
import Providers from '../../../../../../common/components/Content/Providers/Providers';
import { ErrorModal } from '../../../../../../common/components/ErrorModal/ErrorModal';

import { AuthContext } from '../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../constants/TVMSRoles';

const AddNewMusicAlbum = () => {
  const history = useHistory();
  const params = useParams<IMSNameParams & { deployment: string }>();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const [isAdding, setIsAdding] = useState(false);
  const [translations, setTranslations] = useState<TRLNS[]>([]);
  const [payperview, setPayPerView] = useState([] as any);
  const [widescreen, setWidescreen] = useState([] as any);
  const [placement, setPlacement] = useState([] as any);
  const [tags, setTags] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();
  const [provider, setProvider] = useState('No Provider');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;

  const handleSubmit = async (values: Games) => {
    setIsAdding(true);


    values = {
      name: values.name,
      placement: placement,
      game_id: values.game_id,
      game_slug: '',
      game_provider:'',
      game_affiliate: values.game_affiliate,
      translations: translations,
      tags: tags,
      provider: {
        name: isProvider ? AuthState?.user?.name : provider,
      },
      provider_status: isProvider ? 'validation' : '',
      images: {
        widescreen: widescreen.url.replace('https://cloudtv.akamaized.net', ''),
      },
      query_params: {
        instance: clientName,
        cms: params.deployment ?? '',
      },
    };

    let response = await cmsService.Add_Games(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-games-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-games')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('create-your-movie-collection-and-offer-it-bundled-in-packages-to-your-customers')}</p>
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          setModalData(errorInfo?.errorFields);
          setOpenErrorModal(true);
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('games')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('games-name')} />
                </Form.Item>
              </div>

              <div className="row">
                <label className="col-md-3 col-form-label">{t('game-id')}</label>
                <Form.Item className="col-md-7" name="game_id">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="DD9DSF&" />
                </Form.Item>
              </div>
      
     
              <div className="row">
                <label className="col-md-3 col-form-label">{t('game-affiliate')}</label>
                <Form.Item className="col-md-7" name="game_affiliate">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="D9SDKDF" />
                </Form.Item>
              </div>


            </div>

            {!isProvider &&
            <Providers  setProvider={setProvider} />
}
            <Tags setTags={setTags} />
            <Translations data={translations} service_name={service?.name ?? ''} clientName={clientName} setTranslations={setTranslations} />



            <Placement setPlacement={setPlacement} />

            {/* <PayPerView service_name={service?.name ?? ''} clientName={clientName} setPayPerView={setPayPerView} /> */}

            <ImageUploader
              onSubmit={(values: any) => {
                setWidescreen(values);
              }}
              initialData={{
                uploadName: t('games-widescreen'),
                uploadType: 'widescreen',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.GAMES.IMAGES,
              }}
            />
      
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-games')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
