import { Button, Form, Input, notification } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Translations from '../../../../../../common/components/Content/Translations/Translations';
import ImageUploader from '../../../../../../common/components/ImageUploader/ImageUploader';
import { Translations as TRLNS } from '../../../../../../interfaces/responses/areas/content/shared/translations';
import cmsService from '../../../../../../services/areas/content/courses/courses';
import { IMSNameParams } from '../../../../../dashboard';
import Teachers from '../../../../../../common/components/Content/Teachers/Teachers';
import SubContent from '../../../../../../common/components/Content/Content/SubContent';
import { Courses } from '../../../../../../interfaces/forms/areas/content/courses/courses';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { STORAGE_ENDPOINTS } from '../../../../../../services/storage_paths';
import Tags from '../../../../../../common/components/Content/Tags/Tags';
import Ads from '../../../../../../common/components/Content/Ads/Ads';
import Security from '../../../../../../common/components/Content/Security/Security';
import PayPerView from '../../../../../../common/components/Content/PayPerView/PayPerView';
import ParentalControl from '../../../../../../common/components/Content/Parental/Parental';
import Placement from '../../../../../../common/components/Content/Placement/Placement';
import Providers from '../../../../../../common/components/Content/Providers/Providers';
import { ErrorModal } from '../../../../../../common/components/ErrorModal/ErrorModal';

import { AuthContext } from '../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../constants/TVMSRoles';

const AddNewMusicAlbum = () => {
  const history = useHistory();
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [translations, setTranslations] = useState<TRLNS[]>([]);
  const [poster, setPoster] = useState([] as any);
  const [backdrop, setBackdrop] = useState([] as any);
  const [widescreen, setWidescreen] = useState([] as any);
  const [teachers, setTeachers] = useState([] as any);
  const [lessons, setLessons] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();
  const [placement, setPlacement] = useState([] as any);
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const [parental, setParental] = useState({
    enabled: false,
    rating: [] as any,
  });
  const [ads, setAds] = useState([] as any);
  const [tags, setTags] = useState([] as any);
  const [security, setSecurity] = useState([] as any);
  const [payperview, setPayPerView] = useState([] as any);
  const [provider, setProvider] = useState('No Provider');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);
  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;

  const handleSubmit = async (values: Courses) => {
    setIsAdding(true);
    var payperview_ = [] as any;
    if (payperview.rule?.access_length == undefined) {
      var curs = [] as any;
      deployment?.currencies.forEach((cur, index) => {
        curs.push({
          iso3: cur.isoCurrency,
          credits_rented: 0,
          credits_purchased: 0,
          key: index,
        });
      });
      payperview_ = {
        enable: payperview.enable,
        rule: undefined,
        currencies: curs,
      };
    } else {
      payperview_ = payperview;
    }
    var translations_ = [] as any;
    translations.map((translation: any) => {
      var new_ = {
        name: translation.name,
        description: translation.description,
      };
      translations_.push(new_);
    });
    values = {
      name: values.name,
      difficulty: values.difficulty,
      released: values.released,
      language: values.language,
      placement: placement,
      ads: ads,
      parental: parental,
      provider: {
        name: isProvider ? AuthState?.user?.name : provider,
      },
      provider_status: isProvider ? 'validation' : '',
      security: security,
      payperview: payperview_,
      tags: tags,
      lessons: lessons,
      teachers: teachers,
      translations: translations_,
      images: {
        poster: poster?.url?.replace('https://cloudtv.akamaized.net', ''),
        background: backdrop?.url?.replace('https://cloudtv.akamaized.net', ''),
        widescreen: widescreen.url.replace('https://cloudtv.akamaized.net', ''),
      },
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Add_Course(values as any);
    if (response.status_code === StatusCodes.OK) {
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-course-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-course')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('create-your-course-collection-and-offer-it-bundled-in-packages-to-your-customers')}</p>
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          setModalData(errorInfo?.errorFields);
          setOpenErrorModal(true);
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('details')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-2 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('course-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('released')}</label>
                <Form.Item
                  className="col-md-7"
                  name="released"
                  rules={[
                    {
                      required: true,
                      message: t('released-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="1999" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('difficulty')}</label>
                <Form.Item
                  className="col-md-7"
                  name="difficulty"
                  rules={[
                    {
                      required: true,
                      message: t('dificulty-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('moderate')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('course-language')}</label>
                <Form.Item
                  className="col-md-7"
                  name="language"
                  rules={[
                    {
                      required: true,
                      message: t('language-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('english')} />
                </Form.Item>
              </div>
            </div>

            {!isProvider &&
            <Providers  setProvider={setProvider} />
}
            <Tags setTags={setTags} />
            <Ads setAds={setAds} />
            <PayPerView service_name={service?.name ?? ''} clientName={clientName} setPayPerView={setPayPerView} />
            <Security setSecurity={setSecurity} />
            <ParentalControl setParentalControl={setParental} />

            <Placement setPlacement={setPlacement} />
            <Translations data={translations} service_name={service?.name ?? ''} clientName={clientName} setTranslations={setTranslations} />

            <Teachers setTeachers={setTeachers} />

            <SubContent setData={setLessons} type={"Lessons"} data={lessons} />
            <ImageUploader
              onSubmit={(values: any) => {
                setPoster(values);
              }}
              initialData={{
                uploadName: t('course-poster'),
                uploadType: 'poster',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.COURSES.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setWidescreen(values);
              }}
              initialData={{
                uploadName: t('course-widescreen'),
                uploadType: 'widescreen',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.COURSES.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setBackdrop(values);
              }}
              initialData={{
                uploadName: t('course-background'),
                uploadType: 'background',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.COURSES.IMAGES,
              }}
            />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-course')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
