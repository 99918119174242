import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Security from '../../../../../../common/components/Content/Security/Security';
import PayPerView from '../../../../../../common/components/Content/PayPerView/PayPerView';
import ImageUploader from '../../../../../../common/components/ImageUploader/ImageUploader';
import cmsService from '../../../../../../services/areas/content/channels/channels';
import { IMSNameParams } from '../../../../../dashboard';
import Ads from '../../../../../../common/components/Content/Ads/Ads';
import ParentalControl from '../../../../../../common/components/Content/Parental/Parental';
import InteractiveTV from '../../../../../../common/components/Content/InteractiveTV/InteractiveTV';
import ChannelStreams from '../../../../../../common/components/Content/ChannelStreams/ChannelStreams';
import Epg from '../../../../../../common/components/Content/Epg/Epg';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { STORAGE_ENDPOINTS } from '../../../../../../services/storage_paths';
import Placement from '../../../../../../common/components/Content/Placement/Placement';
import Tags from '../../../../../../common/components/Content/Tags/Tags';
import transcoderService from '../../../../../../services/areas/content/transcoders/transcoders/index';
import { useEffectOnce } from 'react-use';
import Providers from '../../../../../../common/components/Content/Providers/Providers';
import { ErrorModal } from '../../../../../../common/components/ErrorModal/ErrorModal';
import paramsService from '../../../../../../services/areas/content/params/index';

import { AuthContext } from '../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../constants/TVMSRoles';


const AddNewMusicAlbum = () => {
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const [placement, setPlacement] = useState([] as any);
  const history = useHistory();
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [security, setSecurity] = useState([] as any);
  const [payperview, setPayPerView] = useState([] as any);
  const [icon, setIcon] = useState([] as any);
  const [tile, setTile] = useState([] as any);
  const [parental, setParental] = useState({
    enabled: false,
    rating: [] as any,
  });
  const [ads, setAds] = useState([] as any);
  const [interactivetv, setInteractiveTV] = useState([] as any);
  const [tags, setTags] = useState([] as any);
  const [transcoders, setTranscoders] = useState([] as any);
  const [provider, setProvider] = useState('No Provider');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);
  const [params_, setParams_] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();
const { state: AuthState } = useContext(AuthContext);
const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;


  const getParams = async () => {
    let response = await paramsService.List_Params({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data != undefined && response.data.data != undefined) {
        setParams_(response.data.data);
      }
    }
  };

  useEffectOnce(() => {
    getParams();
  });

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    let payperview_ = [] as any;
    if (payperview.rule?.access_length === undefined) {
      let curs = [] as any;
      deployment?.currencies.forEach((cur, index) => {
        curs.push({
          iso3: cur.isoCurrency,
          credits_rented: 0,
          credits_purchased: 0,
          key: index,
        });
      });
      payperview_ = {
        enable: payperview.enable,
        rule: undefined,
        currencies: curs,
      };
    } else {
      payperview_ = payperview;
    }
    let transcoder = undefined;
    if (interactivetv.transcoder !== undefined) {
      const test = transcoders.find((t) => t._id === interactivetv.transcoder);
      if (test !== undefined) {
        transcoder = test;
      }
    }

    var paramsIn = undefined;
    var par = params_.find((p) => p._id == values?.streams?.params?.value);
    if(par != undefined){
      paramsIn = par
    }

    values = {
      ...values,
      name: values.name,
      number: values.number,
      meta: values.meta,
      tags: tags,
      placement: placement,
      provider: {
        name: isProvider ? AuthState?.user?.name : provider,
      },
      provider_status: isProvider ? 'validation' : '',
      interactivetv: {
        catchuptv_enabled: interactivetv.catchuptv_enabled,
        recordings_enabled: interactivetv.recordings_enabled,
        pausetv_enabled: interactivetv.pausetv_enabled,
        alternative_url: values.interactivetv?.alternative_url,
        catchuptv_days: values.interactivetv?.catchuptv_days,
        transcoder: transcoder,
      },
      streams: {
        channels:values?.streams?.channels,
        params: paramsIn
      },
      parental: parental,
      security: security,
      payperview: payperview_,
      ads: {
        vast_url: values.ads?.vast_url,
      },
      images: {
        square: icon.url.replace('https://cloudtv.akamaized.net', ''),
        tile: tile?.url?.replace('https://cloudtv.akamaized.net', ''),
      },
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Add_Channel(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  const getTranscoders = async () => {
    let response = await transcoderService.List_Transcoders({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      setTranscoders(response.data.data);
    }
  };

  useEffectOnce(() => {
    getTranscoders();
  });

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-channels-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-channel')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('create-your-channels-collection-and-offer-it-bundled-in-packages-to-your-customers')}</p>
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          setModalData(errorInfo?.errorFields);
          setOpenErrorModal(true);
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('channel')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-5"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('channel-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('number')}</label>
                <Form.Item
                  className="col-md-5"
                  name="number"
                  rules={[
                    {
                      required: true,
                      message: t('number-is-required'),
                    },
                  ]}
                >
                  <Input defaultValue={0} type={'number'} className="form-control bg-white font-size-14 text-default p-3rem" placeholder="101" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('notes')}</label>
                <Form.Item className="col-md-5" name="meta">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('use-this-to-add-notes-to-your-channel')} />
                </Form.Item>
              </div>
            </div>

            <Providers setProvider={setProvider} />

            <ChannelStreams params={params_} />

            <InteractiveTV setInteractiveTV={setInteractiveTV} />

            <ParentalControl setParentalControl={setParental} />

            <Placement setPlacement={setPlacement} />

            <Tags setTags={setTags} />
            <Security setSecurity={setSecurity} />

            <Epg />

            <Ads setAds={setAds} />

            <PayPerView service_name={service?.name ?? ''} clientName={clientName} setPayPerView={setPayPerView} />

            <ImageUploader
              onSubmit={(values: any) => {
                setIcon(values);
              }}
              initialData={{
                uploadName: t('channel-image'),
                uploadType: 'square',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CHANNELS.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setTile(values);
              }}
              initialData={{
                uploadName: t('channel-tile'),
                uploadType: 'tile',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CHANNELS.IMAGES,
              }}
            />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-channel')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
