import { Button, Col, Modal, Row, Tooltip } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ICMS_Lists } from '../../../../interfaces/forms/common/ICMS_Lists';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import ReactBCTable from '../../../../common/components/ReactBCTable';
import { IReactBCTableColumn } from '../../../../common/components/ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { faArrowsUpDown, faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import chartDesignService from '../../../../services/areas/reporting/chartdesigns/chartDesignService';
import ReactDragListView from 'react-drag-listview';
import { AvailableChartEntities, AvailableChartTypes } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignConstants';
import dayjs from 'dayjs';
import dashboardService from '../../../../services/areas/reporting/dashboards/dashboardService';

const Table = (params: { deployment: any; service: any; clientName: any; type: any }) => {
  const { confirm } = Modal;
  const { url } = useRouteMatch();
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation();
  const tableSearchState = {
    pageNumber: 1,
    pageSize: 50,
    query_params: {
      instance: params.clientName,
      crm: params.service.name ?? '',
      cms: params.deployment.name,
    },
  } as ICMS_Lists;
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);

  useEffectOnce(() => {
    getChartDesigns(tableSearchState);
  });

  const getContent = (tableSearchState) => {
    switch (params.type) {
      case 'chartdesigns':
        (async () => await getChartDesigns(tableSearchState))();
        break;
    }
  };

  useEffectOnce(() => {
    getChartDesigns(tableSearchState);
  });

  const deleteChartDesign = async (_id: string) => {
    setIsLoading(true);
    let response = await chartDesignService.Delete_ChartDesigns({
      _id: _id,
      query_params: {
        crm: params.service.name,
        cms: params.deployment.name,
        instance: params.clientName,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getChartDesigns(tableSearchState);

      const dashboardResponse = await dashboardService.Generate({
        query_params: {
          crm: params.service.name,
          cms: params.deployment.name,
          instance: params.clientName,
          area: "reporting",
          },
      });
  
      if (dashboardResponse.status_code !== StatusCodes.OK) {
        console.log(JSON.stringify(dashboardResponse));
      }
    }
    setIsLoading(false);
  };

  const getChartDesigns = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await chartDesignService.List_ChartDesigns(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data.sort((a, b) => {
        if (a?.order == null && b?.order == null) return 0;
        if (a?.order == null) return 1;
        if (b?.order == null) return -1;
        return a?.order - b?.order;
      }));
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  const updateManyChartDesigns = async (chartDesigns: any[]) => {
    let response = await chartDesignService.UpdateMany_ChartDesigns({
      chartDesigns: chartDesigns,
      query_params: {
        crm: params.service.name,
        cms: params.deployment.name,
        instance: params.clientName,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getChartDesigns(tableSearchState);

      const dashboardResponse = await dashboardService.Generate({
        query_params: {
          crm: params.service.name,
          cms: params.deployment.name,
          instance: params.clientName,
          area: "reporting",
          },
      });
  
      if (dashboardResponse.status_code !== StatusCodes.OK) {
        console.log(JSON.stringify(dashboardResponse));
      }

    }
    setIsLoading(false);
  };

  const columns: IReactBCTableColumn<any>[] = [
    {
      title: t('drag-and-drop'),
      dataKey: 'operate',
      width: '8%',
      cell: (row: any) => (
        <Tooltip placement="top" title={t('drag-and-drop')} key={'move'}>
          <div className="text-center">
            <FontAwesomeIcon className="action-icon drag-handle" color={'#999'} size={'xl'} icon={faArrowsUpDown} />
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('name'),
      dataKey: 'name',
    },
    {
      title: t('chart-design-entity-type'),
      dataKey: 'entityType',
      width: '12%',
      cell: (row: any) => {
        const translationKey = AvailableChartEntities.find(entityType => entityType.name === row.entityType)?.translationKey ?? "";

        return (
          <span>{t(translationKey)}</span>
        );
      },
    },
    {
      title: t('chart-design-chart-type'),
      dataKey: 'chartType',
      width: '12%',
      cell: (row: any) => {
        const translationKey = AvailableChartTypes.find(chartType => chartType.name === row.chartType)?.translationKey ?? "";

        return (
          <span>{t(translationKey)}</span>
        );
      },
    },
    {
      title: t('chart-design-date-created'),
      dataKey: 'createdAt',
      width: '12%',
      cell: (row: any) => {
        const displayValue = dayjs(row.createdAt).format("MMMM D, YYYY");

        return (
          <span>{displayValue}</span>
        );
      },
    },
    {
      dataKey: 'actions',
      title: 'Actions',
      width: '12%',
      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
            <Row className="align-items-center">
              <div onClick={() => {
                  confirm({
                    title: t('chart-design-delete-confirm'),
                    okText: t('yes'),
                    okType: 'danger',
                    cancelText: t('no'),
                    onOk() {
                      deleteChartDesign(row._id);
                    },
                    onCancel() {},
                  });
                }}
              >
                <Tooltip placement="top" title={t('delete')}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                    <div>{t('delete')}</div>
                  </Button>
                </Tooltip>
              </div>
            </Row>
            <Row className="align-items-center">
              <a href={`${url}/details/${row._id}`}>
                <Tooltip placement="top" title={t('open-details')}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faMemo} />
                    <div>{t('open-details')}</div>
                  </Button>
                </Tooltip>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];

  const dragProps = {
    onDragEnd(fromIndex: any, toIndex: any) {
      data.sort((a, b) => a.order > b.order ? 1 : a.order < b.order ? -1 : 0);

      const item = data.splice(fromIndex, 1);
      data.splice(toIndex, 0, ...item);

      const rearrangedData = data.map((chartDesign, index) => {
        chartDesign.order = index;
        return chartDesign;
      });

      setIsLoading(true);
      updateManyChartDesigns(rearrangedData);
    },
    lineClassName: 'global-drag-line',
    handleSelector: '.drag-handle',
  };

  return (
    <div>
      <ReactDragListView {...dragProps}>
        <ReactBCTable onChange={(pageNumber, pageSize, pageSort, filters) => getContent({ pageNumber, pageSize, pageSort, filters })} isLoading={isLoading} totalRecords={pagination.count} pageSize={pagination.pageSize} currentPage={pagination.pageNumber} tableId={'cms_table'} columns={columns} data={data} />
      </ReactDragListView>
    </div>
  );
};
export default Table;
