import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { baseOptions } from './BaseOptions';
import { ChartInput } from '@mwaretv/database/build/backend/util/queryGenerator';

export const PieChart = ({ chartInput }: { chartInput: ChartInput }) => {

  const labels = chartInput.series[0].data?.map((item) => {
    return item.key;
  });

  const options = {
    options: {
      chart: {
        type: 'pie',
      },
      responsive: [
        {
          options: {},
        },
      ],
    },
    labels: labels,
    tooltip: {
      enabled: true,
      y: {
        title: {
          formatter: (value) => {
            if (chartInput.series[0].dataLabel != null) {
              return chartInput.series[0].dataLabel + ": " + value + ": ";
            }
            return value + ": ";
          }
        }
      }
    }
  };

  const chartOptions: ApexOptions = {
    ...baseOptions,
    ...options,
  };

  const series = chartInput.series[0].data?.map((item) => {
    return item.value;
  }) ?? [];

  return <ReactApexChart options={chartOptions} series={series} type="pie" width={400} />;
};
