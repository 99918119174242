import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomersContext } from '../../../../../contexts/customersContext';
import { message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const ProfileTable = ({ initialData }) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(CustomersContext);
  const [profiles, setProfiles] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [times, setTimestamp] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/api/v1/customers/profiles?client=${clientName}&deployment=${deployment?.name}&service=${service?.name}&customer=${initialData?.username}`)
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Something went wrong');
          })
          .then((responseJson) => {
            setProfiles(responseJson);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {}
    };

    fetchData();
  }, [clientName, deployment]);

  const deleteProfile = async (index) => {
    profiles.splice(index, 1);
    setProfiles(profiles);
    setTimestamp(moment().unix().toLocaleString());
    updateJson(profiles);
  };

  const toAlphaNumeric = (input) => {
    if (input == '' || input == undefined || input == null) {
      return '';
    }
    input = input.toString().replace(/\s/g, '');
    return input.toString().replace(/[^A-Za-z0-9]/g, '');
  };

  const updateJson = (json) => {
    let path = clientName + '/' + deployment?.name + '/' + service?.name + '/users/' + toAlphaNumeric(initialData?.username).split('').join('/');
    fetch('https://jsonstorage.io/upload?path=' + encodeURIComponent(path) + '&name=profiles.json', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    }).catch((error) => {});
  };

  return (
    <>
      {contextHolder}
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', border: '1px solid #d1d1d1', borderRadius: 5, padding: 10 }}>
        {profiles.length == 0 && <div style={{ color: '#999' }}>{t('there-are-no-profiles')}</div>}
        {profiles.map((profile: any, index) => {
          return (
            <>
              <div style={{ backgroundColor: '#e9e9e9', padding: 10, borderRadius: 5, color: '#555', marginBottom: 5 }}>
                <b>Profile: {profile.name}</b>
              </div>
              <div
                key={index}
                style={{
                  borderBottom: index < profiles.length - 1 ? '1px solid #d1d1d1' : 'none',
                  display: 'flex',
                  width: '100%',
                  borderRadius: 5,
                  flexDirection: 'column',
                }}
              >
                <div style={{ padding: 10, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', alignItems: 'center', padding: 20, width: '100%', flexDirection: 'row' }}>
                      <div style={{ marginRight: 40 }}>
                        <img src={profile.avatar} alt="" style={{ width: 150, height: 150, border: '2px solid #d1d1d1', borderRadius: 5 }}></img>
                      </div>
                      <div>
                        <table>
                          <tr>
                            <td style={{ width: 150, fontWeight: 'bold' }}>{t('clock-setting')}</td>
                            <td>{profile.clock}</td>
                          </tr>
                          <tr>
                            <td style={{ width: 150, fontWeight: 'bold' }}>{t('parental-code')}</td>
                            <td>{profile.code}</td>
                          </tr>
                          <tr>
                            <td style={{ width: 150, fontWeight: 'bold' }}>{t('aspect-ratio')}</td>
                            <td>{profile.aspectRatio}</td>
                          </tr>
                          <tr>
                            <td style={{ width: 150, fontWeight: 'bold' }}>{t('video-subtitles')}</td>
                            <td>{profile.subtitles}</td>
                          </tr>
                          <tr>
                            <td style={{ width: 150, fontWeight: 'bold' }}>{t('audio-language')}</td>
                            <td>{profile.audio}</td>
                          </tr>
                          <tr>
                            <td style={{ width: 150, fontWeight: 'bold' }}>{t('app-language')}</td>
                            <td>{profile.language}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div style={{ display: 'flex', padding: 20, justifyContent: 'center', alignItems: 'center' }}>
                      <div onClick={() => deleteProfile(index)}>
                        <FontAwesomeIcon color={'#999'} size={'1x'} icon={faTrash} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default ProfileTable;
