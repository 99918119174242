import { useContext } from 'react';
import { Card } from 'antd';
import { IMSNameParams } from '../../../dashboard';
import { useParams, useRouteMatch } from 'react-router-dom';
import * as TransactionService from '../../../../services/areas/billing/transactionService';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { BillingContext } from '../../../../contexts/billingContext';
import { BackButton } from '../../../../common/components/BackButton';
import { Spin } from '../../../../common/components/spinner';
import { Transaction } from '../../../../interfaces/forms/areas/billing/transaction/transaction';
import { useQuery } from '@tanstack/react-query';
import { Item, Input } from '../../../../common/components/form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const TransactionDetails = () => {
  const { id } = useParams<IMSNameParams & { id: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const { url } = useRouteMatch();
  const {t} = useTranslation();

  const { isLoading, isError, data } = useQuery(
    ['transaction', id],
    async () => {
      return (
        await TransactionService.get({
          params: {
            cms: deployment?.name ?? '',
            crm: service?.name || '',
            instance: clientName,
          },
          id,
        })
      ).data;
    },
    { retry: 3 }
  );

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-transaction-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mt-3 mb-3">
          <PageTitle title={t('transaction-details')} subtitle={''} />
        </div>
      </div>
      {isError && <Error />}
      {isLoading && <Loading />}
      {!isLoading && data && <Details {...{ data }} />}
    </ContainerWrapper>
  );
};

const Details = ({ data }: { data: Transaction }) => {
  const {t} = useTranslation();
  return (
    <>
      <Card>
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5  pb-4  mb-3">
              <h4 className="text-primary mt-5 mb-3">{t('details')}</h4>
              <Item className="col-md-12" label={t('transaction-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{moment.unix(data.date_unix).format('lll')}</div>
              </Item>
              <Item className="col-md-12" label={t('transaction-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.transaction_type}</div>
              </Item>
              <Item className="col-md-12" label={t('source')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.source}</div>
              </Item>
            </div>
            <div className="card-bg-light pl-5  pb-4  mb-3">
              <h4 className="text-primary mt-5 mb-3">{t('financial')}</h4>
              <Item className="col-md-12" label={t('amount-including-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.amount_inc_vat}</div>
              </Item>
              <Item className="col-md-12" label={t('amount-excluding-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.amount_ex_vat}</div>
              </Item>
              <Item className="col-md-12" label={t('amount-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.amount_vat}</div>
              </Item>
              <Item className="col-md-12" label={t('payment-provider')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.payment_provider}</div>
              </Item>
              <Item className="col-md-12" label={t('payment-reference-id')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.payment_provider_ref_id}</div>
              </Item>
            </div>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: 16 }}>
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5  pb-4  mb-3">
              <h4 className="text-primary mt-5 mb-3">{t('customer')}</h4>
              <Item className="col-md-12" label={t('first-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.customer?.first_name}</div>
              </Item>
              <Item className="col-md-12" label={t('last-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.customer?.last_name}</div>
              </Item>
              <Item className="col-md-12" label={t('username')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.customer?.username}</div>
              </Item>
              <Item className="col-md-12" label={t('email')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.customer?.email}</div>
              </Item>
              <Item className="col-md-12" label={t('account-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.customer?.account_type}</div>
              </Item>
              <Item className="col-md-12" label={t('status')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.customer?.status}</div>
              </Item>
            </div>
          </div>
        </div>
      </Card>
      {data.products?.length > 0 && (
        <Card style={{ marginTop: 16 }}>
          <div className="card mt-4 pb-4">
            <div className="card-body card-ch-padding">
              <div className="card-bg-light pl-5  pb-4  mb-3 pr-2">
                <h4 className="text-primary mt-5 mb-3">{t('products')}</h4>
                {data.products?.map((product) => {
                  return (
                    <Card style={{ marginTop: 16 }}>
                      <div>
                        <div className="card-bg-light pl-5  pb-4  mb-3">
                          <h4 className="text-primary mt-5 mb-3">{product.name}</h4>

                          <Item className="col-md-12" label={t('type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <div style={{ fontWeight: 'bold' }}>{product.type}</div>
                          </Item>
                          <Item className="col-md-12" label={t('variant')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <div style={{ fontWeight: 'bold' }}>{product.variant}</div>
                          </Item>
                          <Item className="col-md-12" label={t('amount-including-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <div style={{ fontWeight: 'bold' }}>{product.amount_inc_vat}</div>
                          </Item>
                          <Item className="col-md-12" label={t('amount-excluding-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <div style={{ fontWeight: 'bold' }}>{product.amount_ex_vat}</div>
                          </Item>
                          <Item className="col-md-12" label={t('amount-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <div style={{ fontWeight: 'bold' }}>{product.amount_vat}</div>
                          </Item>
                          <Item className="col-md-12" label={t('plan-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <div style={{ fontWeight: 'bold' }}>{product.plan?.name}</div>
                          </Item>
                          <Item className="col-md-12" label={t('duration-plan')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <div style={{ fontWeight: 'bold' }}>{product.plan?.duration}</div>
                          </Item>
                          <Item className="col-md-12" label={t('duration-period-plan')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <div style={{ fontWeight: 'bold' }}>{product.plan?.duration_period_in}</div>
                          </Item>
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

const Loading = () => {
  return (
    <div className="row text-center h-100 w-100">
      <div className="col-12 align-self-center">
        <Spin size="large" spinning={true} />
      </div>
    </div>
  );
};

const Error = () => {
  const {t} = useTranslation();
  return <div>{t('something-went-wrong-sad-smiley')}</div>;
};
