import { Form, Input, Modal, Select, Table, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { useTranslation } from 'react-i18next';

interface Item {
  _id: string;
  language: string;
  title: string;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = <Input />;
  const {t} = useTranslation();
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0, padding: 0 }}
          rules={[
            {
              required: true,
              message: t('please-input-title', { title: title }),
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Survey = (props) => {
  const { visible, onSubmit, onCancel, initialData, editQuestionIndex } = props;
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);
  const [languagesLocal, setLanguagesLocal] = useState([] as any);
  const { deployment } = useContext(AppBuilderContext);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const [surveyType, setSurveyType] = useState('');

  ///start inline edit
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const {t} = useTranslation();
  const isEditing = (record: any) => record.language === editingKey;
  const edit = (record: Partial<any> & { key: React.Key }) => {
    form.setFieldsValue({ title: '', ...record });
    setEditingKey(record.language ?? '');
  };
  const save = async (language: any) => {
    try {
      const row = (await form.validateFields()) as Item;
      const newData = [...languagesLocal];
      const index = newData.findIndex((item) => language === item.language);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setLanguagesLocal(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setLanguagesLocal(newData);
        setEditingKey('');
      }
    } catch (errInfo) {

    }
  };
  const LanguagesColumns = [
    {
      title: t('language'),
      dataIndex: 'language',
      width: '20%',
    },
    {
      title: t('question'),
      dataIndex: 'title',
      editable: true,
      width: '60%',
    },
    {
      title: t('action'),
      dataIndex: 'operation',
      width: 100,
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.language)} style={{ marginRight: 8 }}>
              {t('save')}
            </Typography.Link>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            {t('edit')}
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = LanguagesColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        onBlur: () => save(record.language),
        onClick: () => edit(record as any),
      }),
    };
  });

  useEffect(() => {
    if (initialData != undefined && initialData.question != undefined && initialData.question.length != 0 && initialData.question[0].title != '') {
      setIsLoadingLanguages(true);
      setLanguagesLocal(initialData.question);
      setSurveyType(initialData.type);
      setIsLoadingLanguages(false);
    } else {
      setIsLoadingLanguages(true);
      var languagesIn = languagesDeployment as any;
      let languages = languagesIn.map((language: any, index: any) => {
        language.language = language.name;
        language.title = t('edit-to-add-your-question');
        return language;
      });
      setLanguagesLocal(languages);
      setIsLoadingLanguages(false);
    }
    form.setFieldsValue(initialData);
  }, [initialData]);

  const onSubmitLocal = () => {
    onSubmit({
      question: languagesLocal,
      type: surveyType,
      editQuestionIndex: editQuestionIndex,
    });
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={720} footer={false} closable={true} onCancel={onCancel}>
      <div>
        <div className="row mt-3 p-5">
          <div className="col-md-12 pt-3">
            <h4 className="text-primary pb-3">{t('question')}</h4>
            <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                rowKey={(languagesLocal) => languagesLocal.language}
                loading={isLoadingLanguages}
                columns={mergedColumns}
                dataSource={languagesLocal}
                key={JSON.stringify(languagesLocal)}
                pagination={{
                  position: ['bottomLeft'],
                  pageSize: 10,
                  hideOnSinglePage: true,
                  showTotal: (total, range) => {
                    return `${range[0]}-${range[1]} of ${total} items`;
                  },
                }}
              />

                <Form.Item name={'type'} className="form-group pb-0">
                  <div className={'mt-5'}>
                    <h4 className="text-primary pb-2">{t('question-type')}</h4>
                  </div>
                  <Select loading={isLoadingLanguages} onChange={setSurveyType} className="default-select" placeholder={t('select-type')}>
                    <Select.Option value={"Rate"} key={'Rate'}>
                      {t('rate-selection')}
                    </Select.Option>
                    <Select.Option value={"Text"} key={'Text'}>
                      {t('text-entry')}
                    </Select.Option>
                  </Select>
                </Form.Item>
   
              <div className="col-md-12 p-0 pt-5">
                <button type={'submit'} className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48 mb-3">
                  {t('add-update-question')}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default Survey;
