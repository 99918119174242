import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { baseOptions } from './BaseOptions';
import { ChartInput } from '@mwaretv/database/build/backend/util/queryGenerator';

export const BarChart = ({ chartInput }: { chartInput: ChartInput }) => {
  
  const options = {
    chart: {
      animations: {
        enabled: true,
      },
      background: '',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#5868bd'],
    xaxis: {
      categories: chartInput.series[0].data?.map((item) => {
        return item.key;
      }) ?? [],
    },
  }
  
  const chartOptions: ApexOptions = {
    ...baseOptions,
    ...options,
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        barHeight: '10px',
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      ...options.xaxis,
    },
    yaxis: {
      decimalsInFloat: chartInput.series[0].decimals ?? 0,
    },
    legend: {
      show: false,
      markers: {
        radius: 50,
      },
    },
  };

  const series = [
    {
      name: chartInput.series[0].dataLabel,
      data: chartInput.series[0].data?.map((item) => {
        return item.value;
      }) ?? [],
    },
  ];

  return <ReactApexChart options={chartOptions} series={series} type="bar" height="300" />;
};
