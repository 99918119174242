import { useContext, useState } from 'react';
import { Form, Input, InputNumber, Table, Typography } from 'antd';
import { IMSNameParams } from '../../../../dashboard';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import axios from 'axios';
import translationService from '../../../../../services/areas/billing/translations/index';
import { BillingContext } from '../../../../../contexts/billingContext';
import { useTranslation } from 'react-i18next';

interface Item {
  id: string;
  key: string;
  value: string;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  const {t} = useTranslation();
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: t('please-input-title', { title: title }),
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TranslationEdit = () => {
  const [translations, setTranslations] = useState([] as any);
  const { _id } = useParams<IMSNameParams>();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  ///start inline edit
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: any) => record.id === editingKey;
  const edit = (record: Partial<any> & { key: React.Key }) => {
    form.setFieldsValue({ value: '', ...record });
    setEditingKey(record.id ?? '');
  };
  const save = async (id: any) => {
    try {
      const row = (await form.validateFields()) as Item;
      const newData = [...translations];
      const index = newData.findIndex((item) => editingKey === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        const finalData = { language: newData.reduce((prev, next) => { prev[next.key] = next.value; return prev; }, {})};
        setTranslations(newData);
        editTranslation(finalData);
        setEditingKey('');
      } else {
        newData.push(row);
        const finalData = { language: newData.reduce((prev, next) => { prev[next.key] = next.value; return prev; }, {})};
        setTranslations(newData);
        editTranslation(finalData);
        setEditingKey('');
      }
    } catch (errInfo) {
      
    }
  };

  const LanguagesColumns = [
    {
      title: t('key'),
      dataIndex: 'key',
    },
    {
      title: t('value'),
      dataIndex: 'value',
      editable: true,
    },
    {
      title: t('action'),
      dataIndex: 'operation',
      render: (index: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.name)} style={{ marginRight: 8 }}>
              {t('save')}
            </Typography.Link>
          </span>
        ) : (
          <>
            {record.key !== '_language' && (
              <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                {t('edit')}
              </Typography.Link>
            )}
          </>
        );
      },
    },
  ];

  const mergedColumns = LanguagesColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffectOnce(() => {
    getLanguageFileFromServer();
  });

  const getLanguageFileFromServer = async () => {
    await axios.get('https://cloudtv.akamaized.net/' + clientName + '/' + deployment?.name + '/' + service?.name + '/carts/translations/' + _id + '.json').then((response: any) => {
      var translations_ = [] as any;
      var base = response.data.language;
      Object.keys(base).forEach(function (key, index) {
        translations_.push({
          id: index,
          key: key,
          value: base[key],
        });
      });
      translations_.sort((a, b) => a.key.localeCompare(b.key));
      setTranslations(translations_);
      setLoading(false);
    });
  };

  const editTranslation = async (newTranslation) => {
    setLoading(true);
    await translationService
      .Edit_Translation({
        language: _id,
        base: newTranslation,
        client: clientName,
        deployment: deployment?.name,
        service: service?.name,
      })
      .then((response) => {
        setLoading(false);
      });
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}`}>{t('back-to-translations')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-5">{t('translations-for')} {_id} </h2>
          <p className="h6 font-weight-normal line-height-26">{t('manage-your-translations-click-edit-to-make-changes-your-changes-are-auto-saved-and-ready-to-be-used-in-the-carts-and-portals')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row">
        <div className="col-12 mt-5 card-bg-light p-5">
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              rowClassName="editable-row"
              rowKey={(translations) => translations.id}
              loading={loading}
              columns={mergedColumns}
              dataSource={translations}
              key={JSON.stringify(translations)}
              pagination={{
                position: ['bottomLeft'],
                pageSize: 1000,
                hideOnSinglePage: true,
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default TranslationEdit;
