import { Button, Col, Form, Modal, notification, Table, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { ContainerWrapper } from '../../../../../common/components/containerWrapper';
import { CardForm, FormHeading, ModalFormWrapper, PrimaryButton } from '../../../../../common/components/form';
import { PageTitle } from '../../../../../common/components/pageTitle';
import { BillingContext } from '../../../../../contexts/billingContext';
import { RenewalReminder } from '../../../../../interfaces/forms/areas/billing/renewalReminder';
import { useRenewalReminderList, useRenewalReminderAdd, useRenewalReminderPut, useRenewalReminderDelete } from '../../../../../services/areas/billing/renewalReminderService';
import FormFields from './shared/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToLine, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Spin } from '../../../../../common/components/spinner';
import { useSmsTemplateList } from '../../../../../services/areas/billing/smsTemplate';
import { useEmailTemplateList } from '../../../../../services/areas/billing/emailTemplate';
import { EmailTemplate } from '../../../../../interfaces/forms/areas/billing/emailTemplate';
import { SmsTemplate } from '../../../../../interfaces/forms/areas/billing/smsTemplate';
import { useAppTemplateList } from '../../../../../services/areas/billing/appTemplate';
import { AppTemplate } from '../../../../../interfaces/forms/areas/billing/appTemplate';
import { useTranslation } from 'react-i18next';

type ReminderType = RenewalReminder;

const List = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const { isLoading, isError, data, refetch } = useRenewalReminderList({ params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' } });
  const {t} = useTranslation();
  
  const { data: smsTemplates } = useSmsTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: emailTemplates } = useEmailTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: appTemplates } = useAppTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('renewals')} subtitle={t('renewal-messages-are-being-sent-to-the-customer-to-make-them-renew-their-subscription-before-it-expires')} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 offset-md-7">
          <ActionBar onChangesMade={() => refetch()} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {isLoading && <Spin />}
          {isError && <span>{t('something-went-wrong-fetching-reminders')}</span>}
          {!isLoading && !isError && <RenewalReminderTable reminders={data.data} onChangesMade={() => refetch()} appTemplates={appTemplates?.data.docs ?? []} smsTemplates={smsTemplates?.data.docs ?? []} emailTemplates={emailTemplates?.data.docs ?? []} />}
        </div>
      </div>
    </ContainerWrapper>
  );
};

type RenewalReminderTableProps = {
  reminders: ReminderType[];
  onChangesMade: () => void;
  smsTemplates: SmsTemplate[];
  emailTemplates: EmailTemplate[];
  appTemplates: AppTemplate[];
};

const RenewalReminderTable = ({ reminders, onChangesMade, appTemplates, smsTemplates, emailTemplates }: RenewalReminderTableProps) => {
  const dataSource = reminders.map((r) => ({
    ...r,
    sms_template: smsTemplates.find((t) => t._id === r.sms_template_id),
    email_template: emailTemplates.find((t) => t._id === r.email_template_id),
    app_template: appTemplates.find((t) => t._id === r.app_template_id),
  }));
  const {t} = useTranslation();

  return (
    <Table
      dataSource={dataSource}
      columns={[
        { title: 'time', dataIndex: 'time', width: '10%' },
        { title: t('period'), dataIndex: 'period', width: '10%' },
        { title: t('email-template'), dataIndex: ['email_template', 'name'], width: '20%' },
        { title: t('sms-template'), dataIndex: ['sms_template', 'name'], width: '20%' },
        { title: t('app-template'), dataIndex: ['app_template', 'name'], width: '20%' },
        { title: t('actions'), render: (row) => <TableActions {...{ onChangesMade }} reminder={row} /> },
      ]}
    />
  );
};

type TableActionsProps = {
  reminder: ReminderType;
  onChangesMade: () => void;
};

const TableActions = ({ reminder, onChangesMade }: TableActionsProps) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const {t} = useTranslation();

  const { isLoading, mutate } = useRenewalReminderDelete(
    { params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('message-deleted') });
        onChangesMade();
      },
      onError: () => {
        notification.success({ message: t('message-could-not-be-deleted-please-try-again')});
      },
    }
  );

  return (
    <>
      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
        <Tooltip placement="top" title={t('details')}>
          <Button
            type="text"
            className="mx-2"
            disabled={isLoading}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <FontAwesomeIcon className="action-icon" icon={faArrowRightToLine} />
          </Button>
        </Tooltip>
        {reminder._id !== undefined && (
          <Tooltip placement="top" title={t('delete')}>
            <Button
              type="text"
              className="mx-2"
              disabled={isLoading}
              onClick={() => {
                mutate(reminder._id as string);
              }}
            >
              <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
              <div>{t('delete')}</div>
            </Button>
          </Tooltip>
        )}
      </Col>
      <EditFormModal
        {...{ reminder }}
        open={modalOpen}
        onCancel={(refetch) => {
          setModalOpen(false);
          if (refetch) onChangesMade();
        }}
      />
    </>
  );
};

type ActionBarProps = {
  onChangesMade: () => void;
};

const ActionBar = ({ onChangesMade }: ActionBarProps) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const {t} = useTranslation();
  return (
    <>
      <div className="d-flex justify-content-center justify-content-md-end align-items-start">
        <PrimaryButton className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" onClick={() => setModalOpen(true)}>
          {t('add-message')}
        </PrimaryButton>
      </div>
      <AddFormModal
        open={modalOpen}
        onCancel={(refetch) => {
          setModalOpen(false);
          if (refetch) onChangesMade();
        }}
      />
    </>
  );
};

type AddFormModalProps = {
  open: boolean;
  onCancel: (refetch: boolean) => void;
};

const AddFormModal = ({ open, onCancel }: AddFormModalProps) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const [form] = Form.useForm();
  const {t} = useTranslation();

  const { data: smsTemplates } = useSmsTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: emailTemplates } = useEmailTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: appTemplates } = useAppTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { isLoading, mutate } = useRenewalReminderAdd(
    { params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('message-added') });
        form.resetFields();
        onCancel(true);
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-adding-the-reminder-please-try-again') });
      },
    }
  );

  return (
    <Modal width={720} {...{ open }} onCancel={() => onCancel(false)} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
      <ModalFormWrapper>
        <CardForm
          {...{ form }}
          disabled={isLoading}
          onFinish={(values) => {
            mutate(values);
          }}
        >
          <FormHeading>{t('new-renewal')}</FormHeading>
          <FormFields appTemplates={appTemplates?.data.docs ?? []} smsTemplates={smsTemplates?.data.docs ?? []} emailTemplates={emailTemplates?.data.docs ?? []} />
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};

type EditFormModalProps = {
  open: boolean;
  onCancel: (refetch: boolean) => void;
  reminder: ReminderType;
};

const EditFormModal = ({ open, onCancel, reminder }: EditFormModalProps) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const [form] = Form.useForm();
  const {t} = useTranslation();

  const { data: smsTemplates } = useSmsTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });
  const { data: emailTemplates } = useEmailTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });
  const { data: appTemplates } = useAppTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { isLoading, mutate } = useRenewalReminderPut(
    { params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' }, id: reminder._id as string },
    {
      onSuccess: () => {
        notification.success({ message: t('message-updated') });
        onCancel(true);
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-updating-the-reminder-please-try-again') });
      },
    }
  );

  return (
    <Modal width={720} {...{ open }} onCancel={() => onCancel(false)} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
      <div className="mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <CardForm
              {...{ form }}
              disabled={isLoading}
              initialValues={reminder}
              onFinish={(values) => {
                mutate(values);
              }}
            >
              <FormHeading>{t('new-renewal')}</FormHeading>
              <FormFields appTemplates={appTemplates?.data.docs ?? []} smsTemplates={smsTemplates?.data.docs ?? []} emailTemplates={emailTemplates?.data.docs ?? []} />
            </CardForm>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default List;
