import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export type IAddSongModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  reset: boolean;
  dataIn?: [];
};
const PodcastModal = (props: IAddSongModalProps) => {
  const { visible, onSubmit, onCancel, dataIn } = props;
  const [data, setData] = useState(dataIn) as any;
  const [date, setDate] = useState('00:00');
  const [loading, isLoading] = useState(true);
  const [form] = useForm();
const { t } = useTranslation();

  const handleSubmit = (values: any) => {
    onSubmit({
      update: data.name != undefined ? true : false,
      name: values.name,

      producer: values.producer,
      writer: values.writer,
      duration: values.duration,
      streams: {
        podcasts: {
          url: values.streams.podcasts.url,
        },
      },
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [props.reset]);

  useEffect(() => {
    if (data.name != undefined) {
      setDate(data.duration);
      form.setFieldsValue(data);
    }
    isLoading(false);
  }, [data]);

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary mb-5">{t('add-episode')}</h4>
            {!loading && (
              <Form
                form={form}
                initialValues={{
                  name: '',
                  url: '',
                  add_to_top: false,
                }}
                onFinish={handleSubmit}
                onFinishFailed={(errorInfo) => {}}
              >
                <label className="col-form-label">{t('podcast-name')}</label>
                <Form.Item
                  className="form-group pb-0"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('please-enter-song-name'),
                    },
                  ]}
                >
                  <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('podcast-name')} />
                </Form.Item>
                <label className="col-form-label">{t('duration-mins')}</label>
                <Form.Item className="form-group pb-0" name="duration">
                  <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('enter-a-podcast-writer')} />
                </Form.Item>
                <label className="col-form-label">Writer(s)</label>
                <Form.Item className="form-group pb-0" name="writer">
                  <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('enter-a-podcast-writer')} />
                </Form.Item>
                <label className="col-form-label">Producer(s)</label>
                <Form.Item className="form-group pb-0" name="producer">
                  <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('enter-a-podcast-producer')} />
                </Form.Item>
                <label className="col-form-label">{t('podcast-url')}</label>
                <Form.Item
                  name={['streams', 'podcasts', 'url']}
                  rules={[
                    {
                      required: true,
                      message: t('please-enter-podcast-url'),
                    },
                    {
                      type: 'url',
                      message: t('please-enter-correct-url'),
                    },
                  ]}
                >
                  <Input type="url" className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('enter-podcast-url')} />
                </Form.Item>
                <div className="col-md-12 p-0">
                  {data.translations == undefined && (
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                      {t('add-podcast')}
                    </button>
                  )}
                  {data.translations != undefined && (
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                      {t('update-podcast')}
                    </button>
                  )}
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PodcastModal;
