import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { IMSNameParams } from '../../../dashboard';
import { Button, Col, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemo } from '@fortawesome/pro-regular-svg-icons';
import * as CommunicationService from '../../../../services/areas/billing/communicationService';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import ReactBCTable from '../../../../common/components/ReactBCTable';
import { BillingContext } from '../../../../contexts/billingContext';
import { CommunicationType } from '../../../../interfaces/forms/areas/billing/communication';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const CommunicationList = () => {
  const { url } = useRouteMatch();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const history = useHistory();
  const [pagination, setPagination] = useState<{ limit: number; page: number }>({ limit: 50, page: 1 });
  const { t } = useTranslation();
  
  const query = useQuery(
    ['transactions', pagination.page, pagination.limit],
    async () => {
      return (
        await CommunicationService.list({
          params: {
            cms: deployment?.name || '',
            crm: service?.name || '',
            instance: clientName,
            page: pagination.page,
            limit: pagination.limit,
          },
        })
      ).data;
    },
    { retry: 3 }
  );

  const { isLoading, isError, data } = query;

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('communications')} subtitle={t('manage-communications-list-for-audit-or-check')} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {isError && <div>{t('something-went-wrong-sad-smiley')}</div>}
          {!isError && data && (
            <ReactBCTable
              isLoading={isLoading}
              totalRecords={data.totalDocs}
              pageSize={data.limit}
              tableId={'billing_services_table'}
              columns={[
                {
                  title: t('timestamp'),
                  dataKey: 'timestamp',
                  width: '20%',
                  cell: (row) => {
                    return <div>{moment.unix(row.timestamp).format('lll')}</div>;
                  },
                },
                {
                  title: t('source'),
                  dataKey: 'source',
                  width: '10%',
                  cell: (row) => {
                    return <div>{row.source}</div>;
                  },
                },
                {
                  title: t('type'),
                  dataKey: 'type',
                  width: '10%',
                  cell: (row) => {
                    return <div>{row.type}</div>;
                  },
                },
                {
                  title: t('type'),
                  dataKey: 'message_type',
                  width: '20%',
                  cell: (row) => {
                    return <div>{row.type}</div>;
                  },
                },
                {
                  title: t('username'),
                  dataKey: 'customer_name',
                  width: '20%',
                  cell: (row) => {
                    return <div>{row.customer_name}</div>;
                  },
                },
                {
                  title: t('email-sms'),
                  dataKey: '',
                  width: '15%',
                  cell: (row) => {
                    return <div>{row.type === CommunicationType.EMAIL ? row.email : row.mobile}</div>;
                  },
                },
                {
                  title: t('actions'),
                  dataKey: 'actions',
                  width: '5%',
                  cell: (row) => {
                    return (
                      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                        <Tooltip placement="top" title={t('open-details')}>
                          <Button
                            type="text"
                            className="mx-2"
                            onClick={() => {
                              history.push(`${url}/${row._id}`);
                            }}
                          >
                            <FontAwesomeIcon className="action-icon" icon={faMemo} />
                            <div>{t('details')}</div>
                          </Button>
                        </Tooltip>
                      </Col>
                    );
                  },
                },
              ]}
              data={data.docs || []}
              currentPage={data.page}
              onChange={(page) => {
                setPagination({ ...pagination, page });
              }}
            />
          )}
        </div>
      </div>
    </ContainerWrapper>
  );
};
