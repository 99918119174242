import { Form, Input, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

const Streams = () => {
  const { t } = useTranslation();
  return (
    <div className="m-3">
      <h4 className="text-primary mt-5 mb-3">{t('streams')}</h4>
      <div className="row">
        <div className="col-md-12 card-bg-light p-5">
          <div className="row">
            <label className="col-md-4 col-form-label">{t('episode-url')}</label>
            <Form.Item
              className="col-md-8"
              name={['streams', 'series', 'url']}
              extra={t('we-recommend-to-use-mp4-or-dash')}
              rules={[
                {
                  required: true,
                  message: t('url-is-required'),
                },
              ]}
            >
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-4 col-form-label">{t('episode-thumbnail-vtt')}</label>
            <Form.Item className="col-md-8" name={['streams', 'series', 'thumbnails']} extra={t('we-recommend-to-use-mp4-or-dash')}>
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-4 col-form-label">{t('episode-subtitle-vtt')}</label>
            <Form.Item className="col-md-8" name={['streams', 'series', 'subtitles']} extra={t('we-recommend-to-use-mp4-or-dash')}>
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-4 col-form-label">{t('allow-download')}</label>
            <Form.Item extra={t('give-customers-the-option-to-download-the-content-on-their-mobile-phones-and-play-back-offline')} className="col-md-5" name={['streams', 'series', 'allow_downloads']} valuePropName="checked">
              <Switch className="ch-switch mt-3" />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-4 col-form-label">{t('expiration-download')}</label>
            <Form.Item extra={t('specify-the-duration-in-days-during-which-downloaded-content-items-will-be-automatically-deleted-from-the-customers-device')} className="col-md-5" name={['streams', 'series', 'expiration_downloads']}>
              <Input type={'number'} min={1} className="form-control bg-white font-size-14 text-default" placeholder="1" />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Streams;
