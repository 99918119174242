import { Form, Input, Modal, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import Currencies from './Currencies';
import { StatusCodes } from 'http-status-codes';
import ppvService from '../../../../services/areas/content/ppv/ppv';
import { IMSNameParams } from '../../../../pages/dashboard';
import { useParams } from 'react-router-dom';

const PayPerView_ = (params: { service_name: any; clientName: any; setPayPerView: any; data?: any }) => {
  let [form] = useForm();
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const [ppvRulesList, setPpvRulesList] = useState([] as any);
  const [currencies, setCurrencies] = useState([] as any);
  const [currenciesOut, setCurrenciesOut] = useState([] as any);
  const [isChecked, setChecked] = useState(false);
  const [payPerViewRule, setPayPerViewRule] = useState("");
  const {t} = useTranslation();
  
  const getPPV = async () => {
    let response = await ppvService.List_PPV_Rules({
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
      pageNumber: 1,
      pageSize: 1000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      
      setPpvRulesList(response.data.data);
    }
  };

  useEffectOnce(() => {
    getPPV();
  });

  useEffect(() => {
    if (params.data != undefined) {
      // form.setFieldsValue(params.data);
      setChecked(params.data.enable);
      setCurrenciesOut(params.data.currencies);
      if (params.data.rule) {
        setPayPerViewRule(params.data.rule.name);
      }
    }
  }, [params.data]);

  const setCurrenciesLocal = (values: any) => {
    setCurrencies(values);
    params.setPayPerView({
      rule: payPerViewRule == "" ? undefined : payPerViewRule,
      enable: isChecked,
      currencies: values,
    });
  };
  const setPayPerViewRuleLocal = (value: any) => {
    setPayPerViewRule(value);
    var rule = ppvRulesList.find((p) => p.name == value);
    params.setPayPerView({
      rule: rule == "" ? undefined : rule,
      enable: isChecked,
      currencies: currenciesOut,
    });
  };

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('pay-per-view')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light">
          <div className="col-md-12 mb-3 pt-3 mt-0 ">
            <div className="">
              <div className="card-body card-col-width rounded">
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('enable-ppv')}</label>
                  <Form.Item className="col-md-5 pb-0 mb-0" name="enable">
                    <Switch
                      defaultChecked={false}
                      onChange={(checked) => {
                        form.setFieldsValue({
                          enable: checked,
                        });
                        setChecked(checked);
                        params.setPayPerView({
                          rule: payPerViewRule,
                          enable: checked,
                          currencies: currencies,
                        });
                      }}
                      className="mt-2"
                      checked={isChecked}
                    />
                  </Form.Item>
                </div>
                {isChecked && (
                  <div className="row mt-3 mb-5">
                    <label className="col-md-3 col-form-label">{'Renting Rule'}</label>
                    <Form.Item className="col-md-9 pb-0 mb-0" name={['rule']}>
                      <Select defaultValue={payPerViewRule} onChange={(value) => setPayPerViewRuleLocal(value)} className="default-select" placeholder={t('select-rule')}>
                        {ppvRulesList.map((item) => (
                          <Select.Option key={item._id} value={item.name}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                )}
                {isChecked && (
                  <div>
                    <h4 className="text-primary mt-5 mb-3">{t('ppv-prices')}</h4>
                    <div className="row">
                      <div className="col-md-12 mb-1">
                        <Currencies service_name={service_name} clientName={clientName} setCurrencies={setCurrenciesLocal} data={currenciesOut}></Currencies>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayPerView_;
