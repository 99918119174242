import { Button, DatePicker, Form, Input, Modal, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import Translations from '../Categories/Translations';
import { IMSNameParams } from '../../../../pages/dashboard';
import { ContentContext } from '../../../../contexts/contentContext';
import { useParams } from 'react-router-dom';
import Paths from '../Paths/Paths';
import nestsService from '../../../../services/areas/content/nests/index';
import { useEffectOnce } from 'react-use';
import { StatusCodes } from 'http-status-codes';
import { ColorPicker } from '../../ColorPicker';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import dayjs from 'dayjs'

type IUpdatePackageModalProps = {
  visible: boolean;
  onSubmit: (values: any, newCat) => void;
  onCancel: () => void;
  initialData: {
    _id?: string;
    name?: string;
    translations: [];
    nested: [];
    mappings: any;
    external: boolean;
    styling: any;
    type: string;
  };
};

const CategoryModal = (props: IUpdatePackageModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [translations, setTranslations] = useState([] as any);
  const [nests, setNests] = useState([] as any);
  const [newCat, setNewCat] = useState(false);
  const [externalEnabled, setExternalEnabled] = useState(false);
  const { t } = useTranslation();
  const [background1, setBackground1] = useState('#000000');
  const [background1Picker, setBackground1Picker] = useState(false);
  const [background2, setBackground2] = useState('#999999');
  const [background2Picker, setBackground2Picker] = useState(false);
  const [image, setImage] = useState([] as any);

  const [type, setType] = useState('');
  let [form] = useForm();

  const getNests = async () => {
    let response = await nestsService.List_Nests({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data != undefined && response.data.data != undefined) {
        setNests(response.data.data);
      }
    }
  };

  useEffectOnce(() => {
    getNests();
  });
  useEffect(() => {
    if (initialData.nested != undefined && nests.length > 0) {
      var index_ = 0;
      var saved = initialData.nested as any;
      if (saved != undefined && saved.length > 0) {
        nests.forEach((nest, index) => {
          nest.translations.forEach((translation) => {
            if (translation?.description != undefined) {
              if (translation.description == saved[0].description) {
                index_ = index;
              }
            }
          });
        });
        form.setFieldValue('nested', index_);
      }
    }
  }, [initialData, nests]);

  useEffect(() => {
    async function fetchData() {
      form.setFieldsValue(initialData.mappings);

      setIsLoading(true);

      setType(initialData.type);
      setSelectedContentTypeMappings(initialData.type);
      if (initialData.translations != undefined && initialData.translations.length > 0) {
        setTranslations(initialData.translations);
        setExternalEnabled(initialData.external ?? false);
        if (initialData.styling != undefined) {
          setImage(initialData.styling?.image);
          setBackground1(initialData.styling?.background1);
          setBackground2(initialData.styling?.background2);
        }
        if (initialData.mappings != undefined) {
          if (initialData.mappings.base != undefined) {
            let response = await fetch(initialData.mappings.api);
            if (response.status == 200) {
              let data = await response.json();
              var base = initialData.mappings.base;
              base = base.slice(0, -1);
              setMappingsData([]);
              setIterationPath('');
              setResponse(data[base]);
              var paths = Object.keys(data[base]);
              setPath(paths);
              setBasePath(initialData.mappings.base);

              if (initialData.mappings.iteration_path != undefined) {
                setIterationPath(initialData.mappings.iteration_path);
              }

              var mappingsJson = JSON.parse(initialData.mappings.mappings);
              setMappingsData(mappingsJson);
            }
          }
          var mappingsJson = JSON.parse(initialData.mappings.mappings);
          setMappings(mappingsJson);
        }
      } else {
        setNewCat(true);
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const loadApiForMapping = async (api, base) => {
    let response = await fetch(api);
    if (response.status == 200) {
      let data = await response.json();
      if (base != undefined && base != '') {
        setBasePath(base);
        base = base.slice(0, -1);
        setResponse(data[base]);
        var paths_ = Object.keys(data[base]);
        setPath(paths_);
      } else {
        var paths = Object.keys(data);
        setPath(paths);
        setResponse(data);
      }
      setIsLoading(false);
    }
  };

  const onSubmitLocal = (values) => {
    options.forEach((option) => {
      if (option.name == 'Monetizing') {
        setMappings({
          ...mappings,
          vast_url_overide: values.vast_url_overide,
        });
      }
      if (option.name == 'Security') {
        setMappings({
          ...mappings,
          drm_enabled_overide: values.drm_enabled_overide,
        });

        setMappings({
          ...mappings,
          flussonic_token_override: values.flussonic_token_override,
        });

        setMappings({
          ...mappings,
          legacy_token_override: values.legacy_token_override,
        });

        setMappings({
          ...mappings,
          drm_provider_override: values.drm_provider_override,
        });
      }
      if (option.name == 'Widescreen & Backdrop Images') {
        setMappings({
          ...mappings,
          prefix_url: values.prefix_url,
        });
      }
      if (option.name == 'Parental Control') {
        setMappings({
          ...mappings,
          parental_control_overide: values.parental_control_overide,
        });
      }
      if (option.name == 'Placement') {
        setMappings({
          ...mappings,
          placement_end_overide: values.placement_end_overide,
        });

        setMappings({
          ...mappings,
          placement_start_overide: values.placement_start_overide,
        });

        setMappings({
          ...mappings,
          placement_supress_on_trial_override: values.placement_supress_on_trial_override,
        });
      }
    });
    var id = initialData._id;
    
    onSubmit(
      {
        ...values,
        translations,
        nested: values.nested != 999999 && nests[values.nested] != undefined ? nests[values.nested].translations : undefined,
        external: externalEnabled,
        styling: {
          background1: background1,
          background2: background2,
          image: image.url === undefined ? (!Array.isArray(image) ? image : '') : image.url,
        },
        mappings: {
          schedule: values.schedule ?? '',
          api: values.api,
          base: basePath,
          iteration_path: iterationPath,
          mappings: JSON.stringify(mappings),
        },
        id,
      },
      newCat
    );
  };
  //external code

  const getUploadPath = () => {
    switch (type) {
      case 'Albums':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.ALBUMS.IMAGES;
      case 'Cams':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CAMS.IMAGES;
      case 'Channels':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CHANNELS.IMAGES;
      case 'Radios':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.RADIOS.IMAGES;
      case 'Movies':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.MOVIES.IMAGES;
      case 'Shorts':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SHORTS.IMAGES;
      case 'Series':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES;
      case 'Podcasts':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.PODCASTS.IMAGES;
      case 'Courses':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.COURSES.IMAGES;
      default:
        return '';
    }
  };

  const [shortsKeys] = useState([{ name: 'Name' }, { name: 'Studio' }, { name: 'Tags' }]);
  const [shortsOptions] = useState([{ name: 'Monetizing' }, { name: 'Security' }, { name: 'Widescreen & Backdrop Images' }, { name: 'Parental Control' }, { name: 'Placement' }, { name: 'Short Streams' }, { name: 'Descriptions' }]);

  const [movieKeys] = useState([{ name: 'Name' }, { name: 'Released' }, { name: 'Runtime' }, { name: 'Writers' }, { name: 'Language' }, { name: 'Studio' }, { name: 'Rating' }, { name: 'Actors' }, { name: 'Tags' }]);
  const [movieOptions] = useState([{ name: 'Monetizing' }, { name: 'Security' }, { name: 'Poster & Backdrop Images' }, { name: 'Parental Control' }, { name: 'Placement' }, { name: 'Movie Streams' }, { name: 'Descriptions' }]);

  const [courseKeys] = useState([{ name: 'Name' }, { name: 'Released' }, { name: 'Difficulty' }, { name: 'Language' }, { name: 'Teachers' }]);
  const [courseOptions] = useState([{ name: 'Monetizing' }, { name: 'Security' }, { name: 'Parental Control' }, { name: 'Placement' }, { name: 'Poster, Backdrop & Widescreen Images' }, { name: 'Descriptions' }]);
  const [lessonKeys] = useState([{ name: 'Course' }, { name: 'Name' }, { name: 'Number' }, { name: 'Duration' }]);
  const [lessonOptions] = useState([{ name: 'Lesson Stream Url' }, { name: 'Lesson Image' }, { name: 'Descriptions' }]);

  const [albumKeys] = useState([{ name: 'Name' }, { name: 'Artist' }, { name: 'Tags' }]);
  const [albumOptions] = useState([{ name: 'Security' }, { name: 'Icon Images' }, { name: 'Monetizing' }, { name: 'Placement' }, { name: 'Descriptions' }]);
  const [songKeys] = useState([{ name: 'Album' }, { name: 'Name' }, { name: 'Producer' }, { name: 'Writer' }, { name: 'Duration' }]);
  const [songOptions] = useState([{ name: 'Song Stream Url' }]);

  const [podcastKeys] = useState([{ name: 'Name' }, { name: 'Creator' }, { name: 'Tagline' }, { name: 'Release Period' }, { name: 'Average Length' }, { name: 'Language' }, { name: 'Tags' }]);
  const [podcastOptions] = useState([{ name: 'Security' }, { name: 'Icon Images' }, { name: 'Monetizing' }, { name: 'Placement' }, { name: 'Descriptions' }]);
  const [podsKeys] = useState([{ name: 'Podcast' }, { name: 'Name' }, { name: 'Producer' }, { name: 'Writer' }, { name: 'Duration' }]);
  const [podsOptions] = useState([{ name: 'Podcast Stream Url' }]);

  const [columns1, setColumns1] = useState([] as any);
  const [path, setPath] = useState([] as any);
  const [response, setResponse] = useState([] as any);
  const [mappings, setMappings] = useState([] as any);
  const [options, setOptions] = useState([] as any);
  const [options1, setOptions1] = useState([] as any);
  const [keys, setKeys] = useState([] as any);
  const [keys1, setKeys1] = useState([] as any);
  const [basePath, setBasePath] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [iterationPath, setIterationPath] = useState('');
  const [mappingsData, setMappingsData] = useState([] as any);
  const apiUrl = Form.useWatch('api', form);

  const setSelectedContentTypeMappings = (type: any) => {
    switch (type) {
      case 'Movies':
        setKeys(movieKeys);
        setOptions(movieOptions);

        break;
      case 'Shorts':
        setKeys(shortsKeys);
        setOptions(shortsOptions);

        break;
      case 'Albums':
        setKeys(albumKeys);
        setOptions(albumOptions);
        setKeys1(songKeys);
        setOptions1(songOptions);

        break;
      case 'Podcasts':
        setKeys(podcastKeys);
        setOptions(podcastOptions);
        setKeys1(podsKeys);
        setOptions1(podsOptions);

        break;
      case 'Courses':
        setKeys(courseKeys);
        setOptions(courseOptions);
        setKeys1(lessonKeys);
        setOptions1(lessonOptions);

        break;
    }
  };

  const getDefaulValueJsonColumn = (value: any) => {
    // if (data.mappings != undefined) {
    //   // var search = data.mappings.find((m) => m.key == value);
    //   // if (search != undefined) {
    //   //   return search.value;
    //   // }
    // }
  };

  const optionsDivs = (key_: any, index: any, columns: any, addition: string) => {
    switch (key_.name) {
      case 'Guide':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
     

              <div key={index} className="row pb-5">
                <label className="col-md-4 col-form-label"></label>
                <div className="col-md-5">
                  <Form.Item name={'epg_url_id' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div key={index} className="row pb-5">
                <label className="col-md-4 col-form-label">{t('surpress-playing-on-trials')}</label>
                <div className="col-md-5">
                  <Form.Item name={'epg_url_channel' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );

      case 'Placement':
    

        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('start-time')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'placement_start_overide' + addition} initialValue={getDefaulValueJsonColumn('placement_start_overide')}>
                    <DatePicker value={dayjs()} defaultValue={dayjs()} showToday  className="form-control height-48 bg-white font-size-14 text-default" />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('end-time')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'placement_end_overide' + addition} initialValue={getDefaulValueJsonColumn('placement_end_overide')}>
                      <DatePicker value={dayjs().add(99, 'year')} defaultValue={dayjs().add(99, 'year')} showToday className="form-control height-48 bg-white font-size-14 text-default" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('surpress-playing-on-trials')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'placement_supress_on_trial_override' + addition} initialValue={getDefaulValueJsonColumn('placement_supress_on_trial_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          </div>
        );
      case 'Monetizing':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              {type != 'Albums' && type != 'Podcasts' && type != 'Radio' && (
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('vast-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'vast_url_overide' + addition} initialValue={getDefaulValueJsonColumn('vast_url_overide')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com" />
                    </Form.Item>
                  </div>
                </div>
              )}
            </>
          </div>
        );
      case 'Security':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('enable-akamai-token')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'akamai_token_override' + addition} initialValue={getDefaulValueJsonColumn('akamai_token_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('enable-flussonic-token')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'flussonic_token_override' + addition} initialValue={getDefaulValueJsonColumn('flussonic_token_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div key={index} className="row pb-5">
                <label className="col-md-4 col-form-label">{t('enable-legacy-token')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'legacy_token_override' + addition} initialValue={getDefaulValueJsonColumn('legacy_token_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('enable-drm')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'drm_enabled_overide'} initialValue={getDefaulValueJsonColumn('drm_enabled_overide')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('drm-provider')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'drm_provider_override' + addition} initialValue={getDefaulValueJsonColumn('drm_provider_override')}>
                      <Select className="default-select" placeholder="- Select Provider -" defaultActiveFirstOption={true}>
                        <Select.Option key={'buydrm'} value={'buydrm'}>
                          BuyDRM
                        </Select.Option>
                        <Select.Option key={'irdeto'} value={'irdeto'}>
                          Irdeto
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          </div>
        );
      case 'RTV Streams':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('standard')}</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream_Standard'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">iOS/tvOS</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream_Apple'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">Tizen/Webos</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream_SmartTV'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
            </>
          </div>
        );
      case 'Movie Streams':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('stream-url')}</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
            </>
          </div>
        );
      case 'Short Streams':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('stream-url')}</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
            </>
          </div>
        );

      case 'Lesson Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-4 col-form-label">{t('stream-url')}</label>
              <div className="col-md-8">
                <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case 'Episode Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-4 col-form-label">{t('stream-url')}</label>
              <div className="col-md-8">
                <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case 'Song Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-4 col-form-label">{t('stream-url')}</label>
              <div className="col-md-8">
                <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case 'Podcast Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-4 col-form-label">{t('stream-url')}</label>
              <div className="col-md-8">
                <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case 'Description Episodes':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              {/* {extraSheets.length == 0 && (
                  <div key={index} className="row pb-5">
                    <div className="row-md-12">
                      <h4 className="text-primary">{key_.name}</h4>
                      <p>Should the offered description in the Excel not match the needed Languages in the Deployment we will use this description and your chosen language to translate to the correct description, this only works if you have the AI Translation option switch on, else we will use the first description and import this.</p>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <label className="col-md-4 col-form-label">Description Episodes</label>
                        <div className="col-md-5">
                          <Form.Item name={'description_episodes' + addition}>
                            <Select className="default-select" defaultActiveFirstOption={true}>
                              {columns1.map((el: any, index: any) => {
                                return (
                                  <Select.Option value={index} key={index}>
                                    {el}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <label className="col-md-4 col-form-label">Description Language</label>
                        <div className="col-md-5">
                          <Form.Item name={'description_language_episodes' + addition}>
                            <Select className="default-select" defaultActiveFirstOption={true}>
                              {languagesDeployment.map((el: any, index: any) => {
                                return (
                                  <Select.Option value={el.name} key={el.name}>
                                    {el.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
              {/* {extraSheets.length > 0 && (
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className="text-primary">{key_.name}</h4>
                      <p>No mapping needed, the importer will automatically read your second excel sheet for the available languages and import the descriptions accordingly. Should the offered description in the Excel not match the needed languages in the Deployment we will use the first description and translate to the correct language, this is if you have the AI Translation option switch on, else we will use the first description and import this.</p>
                    </div>

                  </div>
                )} */}
            </div>
          </>
        );

      case 'Descriptions':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-4 col-form-label">{t('descriptions')}</label>
              <div className="col-md-8">
                <Paths setMappingPath={setMappingPathLocal} mapping={'Description'} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case 'InteractiveTV':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('enable-catchuptv')}</label>
                <div className="col-md-5">
                  <Form.Item name={'catchuptv_enabled' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'catchuptv_enabled_overide' + addition}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('enable-recordings')}</label>
                <div className="col-md-5">
                  <Form.Item name={'recordings_enabled' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'recordings_enabled_overide' + addition}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('transcoder-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_url' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_url_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://transcoder.com" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('transcoder-user')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_user' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_user_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('transcoder-pass')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_pass' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_pass_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('transcoder-user')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_retention' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_retention_overide' + addition}>
                      <Input type={'number'} className="form-control bg-white font-size-14 text-default" placeholder="365" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('enable-pausetv')}</label>
                <div className="col-md-5">
                  <Form.Item name={'pausetv_enabled' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'pausetv_enabled_overide' + addition}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('alternative-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'alternative_url' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'alternative_url_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com" />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          </div>
        );
      case 'RTV Images':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('square-image')}</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Square'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('tile-image')}</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Tile'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case 'Radio Images':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('square-image')}</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Square'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('tile-image')}</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Tile'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('background-image')}</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Background'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')} initialValue={getDefaulValueJsonColumn('prefix_url')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case 'Poster & Backdrop Images':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('poster-image')}</label>
                  <div className="col-md-8">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Poster'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('background-image')}</label>
                  <div className="col-md-8">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Background'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>

                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );

      case 'Widescreen & Backdrop Images':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('widescreen-image')}</label>
                  <div className="col-md-8">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('background-image')}</label>
                  <div className="col-md-8">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Background'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>

                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')} initialValue={getDefaulValueJsonColumn('prefix_url')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );

      case 'Poster, Backdrop & Widescreen Images':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('poster-image')}</label>
                  <div className="col-md-8">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Poster'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('background-image')}</label>
                  <div className="col-md-8">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Background'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('widescreen-image')}</label>
                  <div className="col-md-8">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );

      case 'Icon Images':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('square-image')}</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Square'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case 'Level Image':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('widescreen-image')}</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div key={index} className="row pb-5">
                <label className="col-md-4 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case 'Year Image':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('widescreen-image')}</label>
                <div className="col-md-8">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-4 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case 'Series Image':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('widscreen-image')}</label>
                  <div className="col-md-5">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('poster-image')}</label>
                  <div className="col-md-8">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Poster'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );
      case 'Lesson Image':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('image')}</label>
                  <div className="col-md-8">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );
      case 'Episode Image':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('image')}</label>
                  <div className="col-md-5">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-4 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );
      case 'Parental Control':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-4 col-form-label">{t('parental-control')}</label>
              <div className="col-md-5">
                <div>
                  <Form.Item name={'parental_control_overide' + addition} initialValue={getDefaulValueJsonColumn('parental_control_overide')}>
                    <Switch className="ch-switch" defaultChecked={false} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const setMappingPathLocal = (mapping) => {
    setMappings({
      ...mappings,
      ...mapping,
    });
  };

  const setMappingBasePathLocal = (mapping) => {
    setBasePath(mapping.Base);
    var base = mapping.Base;
    base = base.slice(0, -1);
    setMappingsData([]);
    setIterationPath('');
    setResponse(response[base]);
    var paths = Object.keys(response[base]);
    setPath(paths);
  };

  const setMappingIterationPathLocal = (mapping) => {
    setIterationPath(mapping.IterationPath);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={960} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 m-4">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{initialData.type} {t('category')}</h4>
            <p>{t('choose-your-category-name-carefully-cause-they-will-be-used-in-your-apps')}</p>
            <Form initialValues={initialData} form={form} onFinish={(values) => onSubmitLocal(values)} onFinishFailed={(errorInfo) => {}}>
              <div className="mt-2"></div>
              <Translations service_name={deployment} clientName={clientName} extra={initialData.name} data={translations} setTranslations={setTranslations} />
              {type != 'Channels' && (
                <div className="row mt-5">
                  <label className="col-md-4 col-form-label">{t('nested-category')}</label>
                  <div className="col">
                    <Form.Item extra={'When you select a nested it auto enables a nested category structure in the apps by combining the categories under one button.'} className="form-group pb-0" name="nested">
                      <Select className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={999999} key={999999}>
                          {t('no-nest')}
                        </Select.Option>
                        {nests.map((el: any, index: any) => {
                          return (
                            <Select.Option value={index} key={index}>
                              {el.translations.map((t) => {
                                return (
                                  <div>
                                    <b>{t.name}: </b>
                                    {t.description}
                                  </div>
                                );
                              })}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              )}
              <div className="card-bg-light pl-5 pt-3 mb-4 p-5 mt-4">
                <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                  {t('category-styling')}
                </h4>
                <p>{t('this-styling-is-applied-to-the-genre-rails-component-within-the-app-builder-you-can-choose-between-colors-or-an-image')}</p>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="col-md-6 col-form-label">{t('background-color-one')}</label>
                      <div
                        className="col-form-label "
                        onClick={() => {
                          setBackground1Picker(!background1Picker);
                        }}
                        style={{
                          backgroundColor: background1,
                          borderRadius: '5px',

                          width: '60px',
                          border: '#e9edf4 1px solid',
                          height: '48px',
                        }}
                      ></div>
                      <ColorPicker
                        theme={[]}
                        visible={background1Picker}
                        color={background1}
                        onChange={(color) => {
                          setBackground1(color);
                          setBackground1Picker(!background1Picker);
                        }}
                        setVisible={setBackground1Picker}
                      />
                    </div>
                    <div className="row mt-4">
                      <label className="col-md-6 col-form-label">{t('background-color-two')}</label>

                      <div
                        className="col-form-label "
                        onClick={() => {
                          setBackground2Picker(!background2Picker);
                        }}
                        style={{
                          backgroundColor: background2,
                          borderRadius: '5px',

                          width: '60px',
                          border: '#e9edf4 1px solid',
                          height: '48px',
                        }}
                      ></div>
                      <ColorPicker
                        theme={[]}
                        visible={background2Picker}
                        color={background2}
                        onChange={(color) => {
                          setBackground2(color);
                          setBackground2Picker(!background2Picker);
                        }}
                        setVisible={setBackground2Picker}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="text-center"
                      style={{
                        color: 'yellow',
                        paddingTop: 80,
                        border: '1px solid #000',
                        height: 180,
                        width: 320,
                        background: 'linear-gradient(180deg, ' + background1 + ' 0%, ' + background2 + ' 100%)',
                      }}
                    >
                      {t('category-background-gradient-color')}
                    </div>
                  </div>
                </div>

                <div className="row-md-12 mt-3">
                  <ImageUploader
                    onSubmit={(values: any) => {
                      setImage(values);
                    }}
                    initialData={{
                      uploadedImage: image,
                      uploadName: '',
                      uploadType: 'widescreen',
                      path: getUploadPath(),
                    }}
                  />
                </div>
              </div>

              {type == 'Shorts' && (
                <div className="row mt-5">
                  <label className="col-md-4 col-form-label">{t('external-content')}</label>

                  <div className="col">
                    <Form.Item extra={t('enabling-this-switch-allows-you-to-fill-the-content-of-this-category-via-a-3rd-party-api-the-apps-will-load-all-the-meta-data-from-the-set-api-directly-without-any-influence-from-the-tvms')} className="form-group pb-0" name="general.border_radius">
                      <Switch className="ch-switch" checked={externalEnabled} defaultChecked={true} onChange={() => setExternalEnabled(!externalEnabled)} />
                    </Form.Item>
                  </div>
                </div>
              )}
              {externalEnabled && (
                <div>
                  <div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('api-url')}</label>
                      <Form.Item
                        className="col-md-8"
                        extra={t('the-api-should-return-data-in-json-format')}
                        name="api"
                        rules={[
                          {
                            required: true,
                            message: t('api-url-is-required'),
                          },
                        ]}
                      >
                        <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="https://yourcontentapi.com" />
                      </Form.Item>
                    </div>
                    <div className="row mb-5">
                      <label className="col-md-4 col-form-label"></label>
                      <div className="col-md-8">
                        <Button onClick={() => loadApiForMapping(apiUrl, '')} className="btn btn-secondary rounded-pill width-180 height-48">
                          {t('load-api')}
                        </Button>
                      </div>
                    </div>
                    {path.length > 0 && (
                      <div className="row mb-5">
                        <label className="col-md-4 col-form-label">{t('api-base-path')}</label>
                        <div className="col-md-8">
                          <Paths setMappingPath={setMappingBasePathLocal} mapping={'Base'} response={response} path={path} datas={{ Base: basePath?.slice(0, -1) }}></Paths>
                        </div>
                      </div>
                    )}
                    {basePath != '' && (
                      <div className="row mb-5">
                        <label className="col-md-4 col-form-label">{t('api-iteration-path')}</label>
                        <div className="col-md-8">
                          <Paths setMappingPath={setMappingIterationPathLocal} mapping={'IterationPath'} response={response} path={path} datas={{ IterationPath: iterationPath?.slice(0, -1) }}></Paths>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {!isLoading && basePath != '' && (
                      <div>
                        <div className="card-bg-light p-5 mb-5 bg-white">
                          <div className="row ">
                            <div className="col-md-4">
                              <h4 className="text-primary">{t('general')}</h4>
                            </div>
                          </div>
                          <div>
                            {keys.map((key: any, index: any) => {
                              return (
                                <div className="row" key={key.name}>
                                  <label className="col-md-4 col-form-label">{key.name}</label>
                                  <div className="col-md-8">
                                    <Paths setMappingPath={setMappingPathLocal} mapping={key.name} response={response} path={path} datas={mappingsData}></Paths>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {options.map((key_: any, index: any) => {
                          return optionsDivs(key_, index, columns1, '');
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="col-md-12 p-0">
                {initialData.translations == undefined && (
                  <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-5 width-180 height-48">
                    {t('add-category')}
                  </Button>
                )}
                {initialData.translations != undefined && (
                  <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-5 width-180 height-48">
                    {t('update-category')}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CategoryModal;
