"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailableChartEntities = exports.AvailableChartTypes = exports.AvailableChartWidths = exports.AvailableChartWidth = exports.AvailableChartType = exports.AvailableSortingOperators = exports.AvailableFilterLogicOperators = exports.AvailablePercentageOperators = exports.AvailableAccumulatorOperators = exports.AvailableDateTimeOperators = exports.AvailableNumberOperators = exports.AvailableArraySizeOperators = exports.AvailableStringOperators = exports.AvailableOperator = exports.AvailableChartDesignEntity = exports.ChartDesignAvailability = exports.Choice = exports.ChartDesignField = void 0;
const chartDesignEnums_1 = require("./chartDesignEnums");
class ChartDesignField {
    constructor(name, translationKey, dataType, availableForFilter, availableForGroupBy, availableForAccumulator, availableForDistinct, elementFilterFields, choices, decimals) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "translationKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dataType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "availableForFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "availableForGroupBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "availableForAccumulator", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "availableForDistinct", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "decimals", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "elementFilterFields", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "choices", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = name;
        this.translationKey = translationKey;
        this.dataType = dataType;
        this.availableForFilter = availableForFilter;
        this.availableForGroupBy = availableForGroupBy;
        this.availableForAccumulator = availableForAccumulator;
        this.availableForDistinct = availableForDistinct;
        this.elementFilterFields = elementFilterFields;
        this.choices = choices;
        this.decimals = decimals;
    }
}
exports.ChartDesignField = ChartDesignField;
class Choice {
    constructor(name, translationKey) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "translationKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = name;
        this.translationKey = translationKey;
    }
}
exports.Choice = Choice;
class ChartDesignAvailability {
}
exports.ChartDesignAvailability = ChartDesignAvailability;
_a = ChartDesignAvailability;
Object.defineProperty(ChartDesignAvailability, "getEntities", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (countries) => {
        const entities = [
            {
                name: chartDesignEnums_1.AvailableEntitiesReporting.APP_ACTION,
                translationKey: "chart-design-appaction-entity",
                fields: [
                    new ChartDesignField("actionType", "chart-design-action-type", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.actionType).map(value => value.toString()), "action-type")),
                    new ChartDesignField("timestamp", "chart-design-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("timestamp@dayOfMonth", "chart-design-date-per-day", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@week", "chart-design-date-per-week", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@month", "chart-design-date-per-month", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@year", "chart-design-date-per-year", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("app.version", "chart-design-app-version", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, false, false, false),
                    new ChartDesignField("app.package", "chart-design-app-package", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, false, false, false),
                    new ChartDesignField("location.city", "chart-design-location-city", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("location.country", "chart-design-location-country", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getCountryChoices(countries)),
                    new ChartDesignField("location.latitude", "chart-design-location-latitude", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("location.longitude", "chart-design-location-longitude", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("network.type", "chart-design-network-type", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("network.provider", "chart-design-network-provider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.model", "chart-design-device-model", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.uuid", "chart-design-device-uuid", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, false, false, false, true),
                    new ChartDesignField("device.type", "chart-design-device-type", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.os", "chart-design-device-os", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.formFactor", "chart-design-device-formfactor", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.username", "chart-design-customer-username", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, false, false, false, true),
                    new ChartDesignField("customer.status", "chart-design-customer-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerStatus).map(value => value.toString()), "customer-status")),
                    new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.state", "chart-design-customer-state", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.city", "chart-design-customer-city", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.country", "chart-design-customer-country", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getCountryChoices(countries)),
                    new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerProductStatus).map(value => value.toString()), "customer-product-status")),
                    new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerSubscriptionStatus).map(value => value.toString()), "customer-subscription-status")),
                    new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                ],
            },
            {
                name: chartDesignEnums_1.AvailableEntitiesReporting.APP_ERROR,
                translationKey: "chart-design-apperror-entity",
                fields: [
                    new ChartDesignField("errorType", "chart-design-error-type", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("timestamp", "chart-design-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("timestamp@dayOfMonth", "chart-design-date-per-day", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@week", "chart-design-date-per-week", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@month", "chart-design-date-per-month", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@year", "chart-design-date-per-year", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("app.version", "chart-design-app-version", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, false, false, false),
                    new ChartDesignField("app.package", "chart-design-app-package", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, false, false, false),
                    new ChartDesignField("location.city", "chart-design-location-city", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("location.country", "chart-design-location-country", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getCountryChoices(countries)),
                    new ChartDesignField("network.type", "chart-design-network-type", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("network.provider", "chart-design-network-provider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.model", "chart-design-device-model", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.uuid", "chart-design-device-uuid", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, false, false, false, true),
                    new ChartDesignField("device.type", "chart-design-device-type", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.os", "chart-design-device-os", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.formFactor", "chart-design-device-formfactor", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.username", "chart-design-customer-username", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, false, false, false, true),
                    new ChartDesignField("customer.status", "chart-design-customer-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerStatus).map(value => value.toString()), "customer-status")),
                    new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.state", "chart-design-customer-state", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.city", "chart-design-customer-city", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.country", "chart-design-customer-country", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getCountryChoices(countries)),
                    new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerProductStatus).map(value => value.toString()), "customer-product-status")),
                    new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerSubscriptionStatus).map(value => value.toString()), "customer-subscription-status")),
                    new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("content.contentType", "chart-design-content-type", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.contentType).map(value => value.toString()), "content-type")),
                    new ChartDesignField("content.mainType", "chart-design-content-main-type", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.mainType).map(value => value.toString()), "content-main-type")),
                    new ChartDesignField("content.name", "chart-design-content-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("content.provider", "chart-design-content-provider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("content.subname", "chart-design-content-subname", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                ],
            },
            {
                name: chartDesignEnums_1.AvailableEntitiesReporting.APP_PAGE,
                translationKey: "chart-design-apppage-entity",
                fields: [
                    new ChartDesignField("pageType", "chart-design-page-type", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.pageType).map(value => value.toString()), "page-type")),
                    new ChartDesignField("timestamp", "chart-design-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("timestamp@dayOfMonth", "chart-design-date-per-day", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@week", "chart-design-date-per-week", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@month", "chart-design-date-per-month", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@year", "chart-design-date-per-year", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("app.version", "chart-design-app-version", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, false, false, false),
                    new ChartDesignField("app.package", "chart-design-app-package", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, false, false, false),
                    new ChartDesignField("location.city", "chart-design-location-city", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("location.country", "chart-design-location-country", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getCountryChoices(countries)),
                    new ChartDesignField("network.type", "chart-design-network-type", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("network.provider", "chart-design-network-provider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.model", "chart-design-device-model", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.uuid", "chart-design-device-uuid", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, false, false, false, true),
                    new ChartDesignField("device.type", "chart-design-device-type", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.os", "chart-design-device-os", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.formFactor", "chart-design-device-formfactor", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.username", "chart-design-customer-username", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, false, false, false, true),
                    new ChartDesignField("customer.status", "chart-design-customer-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerStatus).map(value => value.toString()), "customer-status")),
                    new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.state", "chart-design-customer-state", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.city", "chart-design-customer-city", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.country", "chart-design-customer-country", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getCountryChoices(countries)),
                    new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerProductStatus).map(value => value.toString()), "customer-product-status")),
                    new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerSubscriptionStatus).map(value => value.toString()), "customer-subscription-status")),
                    new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                ],
            },
            {
                name: chartDesignEnums_1.AvailableEntitiesReporting.CONTENT_WATCHED,
                translationKey: "chart-design-contentwatched-entity",
                fields: [
                    new ChartDesignField("duration", "chart-design-content-watched-duration", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
                    new ChartDesignField("timestamp", "chart-design-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("timestamp@dayOfMonth", "chart-design-date-per-day", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@week", "chart-design-date-per-week", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@month", "chart-design-date-per-month", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@year", "chart-design-date-per-year", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("content.contentType", "chart-design-content-type", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.contentType).map(value => value.toString()), "content-type")),
                    new ChartDesignField("content.mainType", "chart-design-content-main-type", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.mainType).map(value => value.toString()), "content-main-type")),
                    new ChartDesignField("content.name", "chart-design-content-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("content.provider", "chart-design-content-provider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("content.subname", "chart-design-content-subname", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("app.version", "chart-design-app-version", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, false, false, false),
                    new ChartDesignField("app.package", "chart-design-app-package", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, false, false, false),
                    new ChartDesignField("location.city", "chart-design-location-city", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("location.country", "chart-design-location-country", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getCountryChoices(countries)),
                    new ChartDesignField("network.type", "chart-design-network-type", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("network.provider", "chart-design-network-provider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.model", "chart-design-device-model", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.uuid", "chart-design-device-uuid", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, false, false, false, true),
                    new ChartDesignField("device.type", "chart-design-device-type", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.os", "chart-design-device-os", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("device.formFactor", "chart-design-device-formfactor", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.username", "chart-design-customer-username", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, false, false, false, true),
                    new ChartDesignField("customer.status", "chart-design-customer-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerStatus).map(value => value.toString()), "customer-status")),
                    new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.state", "chart-design-customer-state", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.city", "chart-design-customer-city", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.country", "chart-design-customer-country", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getCountryChoices(countries)),
                    new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerProductStatus).map(value => value.toString()), "customer-product-status")),
                    new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerSubscriptionStatus).map(value => value.toString()), "customer-subscription-status")),
                    new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                ],
            },
            {
                name: chartDesignEnums_1.AvailableEntitiesReporting.COMMUNICATION,
                translationKey: "chart-design-communication-entity",
                fields: [
                    new ChartDesignField("source", "chart-design-communication-source", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CommunicationSource).map(value => value.toString()), "communication-source")),
                    new ChartDesignField("type", "chart-design-communication-type", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CommunicationType).map(value => value.toString()), "communication-type")),
                    new ChartDesignField("gateway", "chart-design-gateway", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("message_type", "chart-design-message-type", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("template_name", "chart-design-template-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("timestamp", "chart-design-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("timestamp@dayOfMonth", "chart-design-date-per-day", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@week", "chart-design-date-per-week", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@month", "chart-design-date-per-month", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("timestamp@year", "chart-design-date-per-year", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("product.name", "chart-design-product-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("product.variant", "chart-design-product-variant", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.ProductVariant).map(value => value.toString()), "product-variant")),
                    new ChartDesignField("product.type", "chart-design-product-type", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.ProductType).map(value => value.toString()), "product-type")),
                    new ChartDesignField("customer.username", "chart-design-customer-username", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, false, false, false, true),
                    new ChartDesignField("customer.status", "chart-design-customer-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerStatus).map(value => value.toString()), "customer-status")),
                    new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.state", "chart-design-customer-state", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.city", "chart-design-customer-city", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.country", "chart-design-customer-country", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getCountryChoices(countries)),
                    new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerProductStatus).map(value => value.toString()), "customer-product-status")),
                    new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerSubscriptionStatus).map(value => value.toString()), "customer-subscription-status")),
                    new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                ],
            },
            {
                name: chartDesignEnums_1.AvailableEntitiesReporting.TRANSACTION,
                translationKey: "chart-design-transaction-entity",
                fields: [
                    new ChartDesignField("transaction_type", "chart-design-transaction-type", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.OrderTypes).map(value => value.toString()), "transaction-type")),
                    new ChartDesignField("source", "chart-design-transaction-source", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.TransactionSource).map(value => value.toString()), "transaction-source")),
                    new ChartDesignField("date_unix", "chart-design-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("date_unix@dayOfMonth", "chart-design-date-per-day", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("date_unix@week", "chart-design-date-per-week", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("date_unix@month", "chart-design-date-per-month", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("date_unix@year", "chart-design-date-per-year", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("amount_inc_vat", "chart-design-amount-inc-vat", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false, undefined, undefined, 2),
                    new ChartDesignField("amount_ex_vat", "chart-design-amount-ex-vat", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false, undefined, undefined, 2),
                    new ChartDesignField("amount_vat", "chart-design-amount-vat", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false, undefined, undefined, 2),
                    new ChartDesignField("amount_discount", "chart-design-amount-discount", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false, undefined, undefined, 2),
                    new ChartDesignField("products", "chart-design-products", chartDesignEnums_1.ChartDesignFilterDataTypes.ARRAY, true, false, false, false, [
                        new ChartDesignField("@size", "chart-design-product-size", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, false, false, undefined, undefined, 2),
                        new ChartDesignField("amount_inc_vat", "chart-design-amount-inc-vat", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false, undefined, undefined, 2),
                        new ChartDesignField("amount_ex_vat", "chart-design-amount-ex-vat", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false, undefined, undefined, 2),
                        new ChartDesignField("amount_vat", "chart-design-amount-vat", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false, undefined, undefined, 2),
                        new ChartDesignField("product.name", "chart-design-product-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                        new ChartDesignField("product.variant", "chart-design-product-variant", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.ProductVariant).map(value => value.toString()), "product-variant")),
                        new ChartDesignField("product.type", "chart-design-product-type", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.ProductType).map(value => value.toString()), "product-type")),
                    ]),
                    new ChartDesignField("customer.username", "chart-design-customer-username", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, false, false, false, true),
                    new ChartDesignField("customer.status", "chart-design-customer-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerStatus).map(value => value.toString()), "customer-status")),
                    new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.state", "chart-design-customer-state", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.city", "chart-design-customer-city", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.country", "chart-design-customer-country", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getCountryChoices(countries)),
                    new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerProductStatus).map(value => value.toString()), "customer-product-status")),
                    new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerSubscriptionStatus).map(value => value.toString()), "customer-subscription-status")),
                    new ChartDesignField("customer.shopping_cartName", "chart-design-customer-shoppingcart", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("coupon.name", "chart-design-coupon-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("coupon.start", "chart-design-coupon-start", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("coupon.end", "chart-design-coupon-end", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("coupon.vanity", "chart-design-coupon-vanity", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("coupon.discountAmount", "chart-design-coupon-discount-amount", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false, undefined, undefined, 2),
                    new ChartDesignField("affiliate.name", "chart-design-affiliate-name", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("affiliate.enabled", "chart-design-affiliate-enabled", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("affiliate.vanity", "chart-design-affiliate-vanity", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("affiliate.email", "chart-design-affiliate-email", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("affiliate.phone", "chart-design-affiliate-phone", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("affiliate.facebook", "chart-design-affiliate-facebook", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("affiliate.tiktok", "chart-design-affiliate-tiktok", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("affiliate.instagram", "chart-design-affiliate-instagram", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("affiliate.website", "chart-design-affiliate-website", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("affiliate.discountAmount", "chart-design-affiliate-discount-amount", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false, undefined, undefined, 2),
                    new ChartDesignField("affiliate.commission.type", "chart-design-affiliate-commission-type", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("affiliate.commission.amount", "chart-design-affiliate-commission-amount", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false, undefined, undefined, 2),
                ],
            },
            {
                name: chartDesignEnums_1.AvailableEntitiesReporting.CUSTOMER,
                translationKey: "chart-design-customer-entity",
                fields: [
                    new ChartDesignField("username", "chart-design-customer-username", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, false, false, false, true),
                    new ChartDesignField("status", "chart-design-customer-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerStatus).map(value => value.toString()), "customer-status")),
                    new ChartDesignField("zipcode", "chart-design-customer-zipcode", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("state", "chart-design-customer-state", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("city", "chart-design-customer-city", chartDesignEnums_1.ChartDesignFilterDataTypes.STRING, true, true, false, false),
                    new ChartDesignField("country", "chart-design-customer-country", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getCountryChoices(countries)),
                    new ChartDesignField("is_deleted", "chart-design-customer-isdeleted", chartDesignEnums_1.ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
                    new ChartDesignField("productStatus", "chart-design-customer-product-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerProductStatus).map(value => value.toString()), "customer-product-status")),
                    new ChartDesignField("subscriptionStatus", "chart-design-customer-subscription-status", chartDesignEnums_1.ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, _a.getChoices(Object.values(chartDesignEnums_1.CustomerSubscriptionStatus).map(value => value.toString()), "customer-subscription-status")),
                    new ChartDesignField("renewedDate", "chart-design-renewed-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("renewalDate", "chart-design-renewal-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("renewalDate@dayOfMonth", "chart-design-renewal-date-per-day", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("renewalDate@week", "chart-design-renewal-date-per-week", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("renewalDate@month", "chart-design-renewal-date-per-month", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("renewalDate@year", "chart-design-renewal-date-per-year", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                ],
            },
            {
                name: chartDesignEnums_1.AvailableEntitiesReporting.CDN_TRAFFIC,
                translationKey: "chart-design-cdn-traffic-entity",
                fields: [
                    new ChartDesignField("gbMonthlyCumulative", "chart-design-cdn-gb", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
                    new ChartDesignField("date_unix", "chart-design-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("date_unix@dayOfMonth", "chart-design-date-per-day", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("date_unix@week", "chart-design-date-per-week", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("date_unix@month", "chart-design-date-per-month", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("date_unix@year", "chart-design-date-per-year", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                ],
            },
            {
                name: chartDesignEnums_1.AvailableEntitiesReporting.CDN_STORAGE,
                translationKey: "chart-design-cdn-storage-entity",
                fields: [
                    new ChartDesignField("gbMonthlyCumulative", "chart-design-cdn-gb", chartDesignEnums_1.ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
                    new ChartDesignField("date_unix", "chart-design-date", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
                    new ChartDesignField("date_unix@dayOfMonth", "chart-design-date-per-day", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("date_unix@week", "chart-design-date-per-week", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("date_unix@month", "chart-design-date-per-month", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                    new ChartDesignField("date_unix@year", "chart-design-date-per-year", chartDesignEnums_1.ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
                ],
            },
        ];
        return entities;
    }
});
Object.defineProperty(ChartDesignAvailability, "getChoices", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (values, fieldName) => {
        const choices = values.map(value => {
            return new Choice(value, `chart-design-${fieldName}-${value.toLowerCase().replace(/ /g, '-')}`);
        });
        return choices;
    }
});
Object.defineProperty(ChartDesignAvailability, "getCountryChoices", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (countries) => {
        if (!countries) {
            return [];
        }
        const choices = countries.map(country => {
            return new Choice(country.name, `${country.emoji} ${country.name}`);
        });
        return choices;
    }
});
class AvailableChartDesignEntity {
    constructor() {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "translationKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fields", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
exports.AvailableChartDesignEntity = AvailableChartDesignEntity;
class AvailableOperator {
    constructor() {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "translationKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
exports.AvailableOperator = AvailableOperator;
exports.AvailableStringOperators = [
    {
        name: "$eq",
        translationKey: "chart-design-eq-operator",
    },
    {
        name: "$ne",
        translationKey: "chart-design-ne-operator",
    },
];
exports.AvailableArraySizeOperators = [
    {
        name: "$eq",
        translationKey: "chart-design-eq-operator",
    },
];
exports.AvailableNumberOperators = [
    {
        name: "$eq",
        translationKey: "chart-design-eq-operator",
    },
    {
        name: "$ne",
        translationKey: "chart-design-ne-operator",
    },
    {
        name: "$gt",
        translationKey: "chart-design-gt-operator",
    },
    {
        name: "$lt",
        translationKey: "chart-design-lt-operator",
    },
    {
        name: "$gte",
        translationKey: "chart-design-gte-operator",
    },
    {
        name: "$lte",
        translationKey: "chart-design-lte-operator",
    },
];
exports.AvailableDateTimeOperators = [
    {
        name: "$gt",
        translationKey: "chart-design-gt-datetime-operator",
    },
    {
        name: "$lt",
        translationKey: "chart-design-lt-datetime-operator",
    },
    {
        name: "@today",
        translationKey: "chart-design-today-operator",
    },
    {
        name: "@thisweek",
        translationKey: "chart-design-thisweek-operator",
    },
    {
        name: "@thismonth",
        translationKey: "chart-design-thismonth-operator",
    },
    {
        name: "@thisquarter",
        translationKey: "chart-design-thisquarter-operator",
    },
    {
        name: "@thisyear",
        translationKey: "chart-design-thisyear-operator",
    },
];
exports.AvailableAccumulatorOperators = [
    {
        name: "$sum",
        translationKey: "chart-design-sum-operator",
    },
    {
        name: "$avg",
        translationKey: "chart-design-avg-operator",
    },
    {
        name: "$min",
        translationKey: "chart-design-min-operator",
    },
    {
        name: "$max",
        translationKey: "chart-design-max-operator",
    },
    {
        name: "$count",
        translationKey: "chart-design-count-operator",
    },
    {
        name: "$distinctCount",
        translationKey: "chart-design-distinctcount-operator",
    },
];
exports.AvailablePercentageOperators = [
    {
        name: "total",
        translationKey: "chart-design-percentage-total",
    },
    {
        name: "target",
        translationKey: "chart-design-percentage-target",
    },
];
exports.AvailableFilterLogicOperators = [
    {
        name: "$and",
        translationKey: "chart-design-filter-logic-and",
    },
    {
        name: "$or",
        translationKey: "chart-design-filter-logic-or",
    },
];
exports.AvailableSortingOperators = [
    {
        name: "ascending",
        translationKey: "chart-design-sortby-ascending",
    },
    {
        name: "descending",
        translationKey: "chart-design-sortby-descending",
    },
    {
        name: "top",
        translationKey: "chart-design-sortby-top-results",
    },
    {
        name: "bottom",
        translationKey: "chart-design-sortby-bottom-results",
    },
];
class AvailableChartType {
    constructor() {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "translationKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
exports.AvailableChartType = AvailableChartType;
class AvailableChartWidth {
    constructor() {
        Object.defineProperty(this, "value", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "translationKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
exports.AvailableChartWidth = AvailableChartWidth;
exports.AvailableChartWidths = [
    {
        value: 12,
        translationKey: "chart-design-chart-width-full",
    },
    {
        value: 9,
        translationKey: "chart-design-chart-width-three-fourths",
    },
    {
        value: 8,
        translationKey: "chart-design-chart-width-two-thirds",
    },
    {
        value: 6,
        translationKey: "chart-design-chart-width-half",
    },
    {
        value: 4,
        translationKey: "chart-design-chart-width-one-third",
    },
    {
        value: 3,
        translationKey: "chart-design-chart-width-one-fourth",
    },
];
exports.AvailableChartTypes = [
    {
        name: chartDesignEnums_1.AvailableChartTypesReporting.BAR,
        translationKey: "chart-design-bar-chart",
    },
    {
        name: chartDesignEnums_1.AvailableChartTypesReporting.COLUMN,
        translationKey: "chart-design-column-chart",
    },
    {
        name: chartDesignEnums_1.AvailableChartTypesReporting.LINE,
        translationKey: "chart-design-line-chart",
    },
    {
        name: chartDesignEnums_1.AvailableChartTypesReporting.PIE,
        translationKey: "chart-design-pie-chart",
    },
    {
        name: chartDesignEnums_1.AvailableChartTypesReporting.PLAIN_VALUE,
        translationKey: "chart-design-plain-value-chart",
    },
    {
        name: chartDesignEnums_1.AvailableChartTypesReporting.GAUGE,
        translationKey: "chart-design-gauge-chart",
    },
];
exports.AvailableChartEntities = [
    {
        name: chartDesignEnums_1.AvailableEntitiesReporting.APP_ACTION,
        translationKey: "chart-design-appaction-entity",
    },
    {
        name: chartDesignEnums_1.AvailableEntitiesReporting.APP_ERROR,
        translationKey: "chart-design-apperror-entity",
    },
    {
        name: chartDesignEnums_1.AvailableEntitiesReporting.APP_PAGE,
        translationKey: "chart-design-apppage-entity",
    },
    {
        name: chartDesignEnums_1.AvailableEntitiesReporting.CONTENT_WATCHED,
        translationKey: "chart-design-contentwatched-entity",
    },
    {
        name: chartDesignEnums_1.AvailableEntitiesReporting.COMMUNICATION,
        translationKey: "chart-design-communication-entity",
    },
    {
        name: chartDesignEnums_1.AvailableEntitiesReporting.TRANSACTION,
        translationKey: "chart-design-transaction-entity",
    },
    {
        name: chartDesignEnums_1.AvailableEntitiesReporting.CUSTOMER,
        translationKey: "chart-design-customer-entity",
    },
    {
        name: chartDesignEnums_1.AvailableEntitiesReporting.CDN_TRAFFIC,
        translationKey: "chart-design-cdn-traffic-entity",
    },
    {
        name: chartDesignEnums_1.AvailableEntitiesReporting.CDN_STORAGE,
        translationKey: "chart-design-cdn-storage-entity",
    },
];
