import { Button, Form, InputNumber, Modal, Table, Typography, Input } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { IFetchedLanguage } from '../../../../interfaces/responses/areas/content/settings/languages/IFetchedLanguagesResponse';
import { useEffectOnce } from 'react-use';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import { ContentContext } from '../../../../contexts/contentContext';
import { useTranslation } from 'react-i18next';

interface Item {
  _id: string;
  name: string;
  language: string;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  const {t} = useTranslation();
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0, padding: 0 }}
          rules={[
            {
              required: true,
              message: t('please-input-title', { title: title }),
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Tag = (params: { service_name: any; clientName: any; setTranslations: any; onCancel: any; onSubmit: any; visible: any; data?: any }) => {
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);
  const [languagesLocal, setLanguagesLocal] = useState([] as any);
  const { deployment } = useContext(ContentContext);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const {t} = useTranslation();
  ///start inline edit
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: any) => record.name === editingKey;
  const edit = (record: Partial<any> & { key: React.Key }) => {
    form.setFieldsValue({ language: '', ...record });
    setEditingKey(record.name ?? '');
  };
  const save = async (name: any) => {
    try {
      const row = (await form.validateFields()) as Item;
      const newData = [...languagesLocal];
      const index = newData.findIndex((item) => name === item.name);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        //setData(newData);
        setLanguagesLocal(newData);
        params.setTranslations(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        // setData(newData);
        setLanguagesLocal(newData);
        params.setTranslations(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      
    }
  };

  const LanguagesColumns = [
    {
      title: t('language'),
      dataIndex: 'name',
    },
    {
      title: t('description'),
      dataIndex: 'description',
      editable: true,
    },
    {
      title: t('action'),
      dataIndex: 'operation',
      width: 100,
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.name)} style={{ marginRight: 8 }}>
              {t('save')}
            </Typography.Link>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            {t('edit')}
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = LanguagesColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const getCMSLanguages = async () => {
    setIsLoadingLanguages(true);
    var languagesIn = languagesDeployment as any;
    let languages = languagesIn.map((language: any, index: any) => {
      language.name = language.name;
      language.description = language.description == undefined ? t('edit-to-add-your-tag-name') : language.description;
      language.key = language._id;
      return language;
    });
    params.setTranslations(languages);
    setLanguagesLocal(languages);
    setIsLoadingLanguages(false);
  };

  useEffect(() => {
    if (params.data != undefined) {
      setLanguagesLocal(params.data);
    }
  }, [params.data]);

  useEffectOnce(() => {
    getCMSLanguages();
  });

  return (
    <Modal visible={params.visible} style={{ border: '1px solid #ebebeb' }} width={640} footer={false} closable={true} onCancel={params.onCancel}>
      <h4 className="text-primary mt-3 mb-3">{t('tags')}</h4>
      <div className="row">
        <div className="col-md-12">
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              rowClassName="editable-row"
              rowKey={(languagesLocal) => languagesLocal.name}
              loading={isLoadingLanguages}
              columns={mergedColumns}
              dataSource={languagesLocal}
              key={JSON.stringify(languagesLocal)}
              pagination={{
                position: ['bottomLeft'],
                pageSize: 10,
                hideOnSinglePage: true,
                showTotal: (total, range) => {
                  return `${range[0]}-${range[1]} of ${total} items`;
                },
              }}
            />
            <Button onClick={() => params.onSubmit(languagesLocal)} className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48 mt-3">
              {t('save-tag')}
            </Button>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
export default Tag;
