import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
type IUpdatePackageModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialData: {
    _id?: string;
    name?: string;
    type: string;
  };
};

const PackageModal = (props: IUpdatePackageModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  const { t } = useTranslation();
  let [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(initialData);
  }, []);
  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{initialData.type} {t('list')}</h4>
            <p>{t('choose-an-easy-to-work-with-name-for-your-list-it-will-only-be-used-in-internally-during-setup-of-your-apps')}</p>
            <Form initialValues={initialData} form={form} onFinish={(values) => onSubmit(values)} onFinishFailed={(errorInfo) => {}}>
              <label className="col-form-label">{t('name')}</label>
              <Form.Item
                name="name"
                className="form-group mb-0"
                rules={[
                  {
                    required: true,
                    message: t('please-enter-list-name'),
                  },
                ]}
              >
                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('enter-list-name')} />
              </Form.Item>
              <Form.Item name="_id">
                <Input hidden value={initialData?._id}></Input>
              </Form.Item>
              <div className="col-md-12 p-0">
                {initialData._id == undefined && (
                  <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('add-list')}
                  </Button>
                )}
                {initialData._id != undefined && (
                  <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('update-list')}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PackageModal;
