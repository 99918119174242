import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { baseOptions } from './BaseOptions';
import { ChartInput } from '@mwaretv/database/build/backend/util/queryGenerator';

export const AreaChart = ({ chartInput }: { chartInput: ChartInput }) => {

  const options = {
    chart: {
      animations: {
        enabled: true,
      },
      background: '',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#5868bd'],
    xaxis: {
      categories: chartInput.series[0].data?.map((item) => {
        return item.key;
      }) ?? [],
    },
    yaxis: {
      decimalsInFloat: chartInput.series[0].decimals ?? 0,
    },
  }
  
  const chartOptions: ApexOptions = {
    ...baseOptions,
    ...options,
    chart: {
      ...options.chart,
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: [5, 5, 4],
      curve: 'straight',
    },
    fill: {
      gradient: {
        opacityFrom: 0.8,
        opacityTo: 0,
      },
    },
    markers: {
      size: 5,
      colors: ['#919fec'],
      strokeWidth: 3,
    },
    legend: {
      show: false,
      markers: {
        radius: 50,
      },
    },
  };

  const series = [
    {
      name: chartInput.series[0].dataLabel,
      data: chartInput.series[0].data?.map((item) => {
        return item.value;
      }) ?? [],
    },
  ];

  return <ReactApexChart options={chartOptions} series={series} type="area" height="350" />;
};
