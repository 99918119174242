import { faAndroid, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faBrush, faCog, faTableColumns, faBlockBrick, faShieldKeyhole, faListTree, faTrash, faCopy, faBrowser, faFillDrip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Image, Button, Modal, notification, Alert } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { SortingRule } from 'react-table';
import ReactBCTable from '../../../../common/components/ReactBCTable';
import { IReactBCTableColumn } from '../../../../common/components/ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IABP_Lists } from '../../../../interfaces/forms/common/IABP_Lists';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import user_interfaces from '../../../../services/areas/appbuilder/applications/application';
import { IMSNameParams } from '../../../dashboard';
import Clone from '../../../../common/components/AppBuilder/Clone/Clone';
import appService from '../../../../services/areas/appbuilder/applications/application';
import { CustomNotification } from '../../../../common/components/Notification';
import { useEffectOnce } from 'react-use';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { useTranslation } from 'react-i18next';
import { VisibleForRole } from '../../../../common/components/VisibleForRole';
import { TVMSRoles } from '../../../../constants/TVMSRoles';

const UserInterfaces = () => {
  const { confirm } = Modal;
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [application, setApplication] = useState([] as any);
  const [showCloningModal, setShowCloningModal] = useState(false);
  const [guiTemplates, setGUITemplates] = useState([] as any);
  const [fetchingUserInterfaces, setFetchingUserInterfaces] = useState<boolean>(false);
  const rbcColumns: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'branding.template_image',
      width: '20%',
      cell: (row) => (
        <div className="text-center">
          {row.branding.template_image != undefined && row.branding.template_image != '' && <Image width="240px" height="135px" src={'https://cloudtv.akamaized.net/' + row.branding.template_image.replace('https://cloudtv.akamaized.net/', '')}></Image>}
          {(row.branding.template_image == undefined || row.branding.template_image == '') && (
            <div>
              <div style={{ position: 'absolute', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 135, width: 240 }}>
                <Image preview={false} src={'https://cloudtv.akamaized.net/' + row.branding.logos.logo.replace('https://cloudtv.akamaized.net/', '')}></Image>
              </div>
              <div>
                <Image preview={false} style={{ borderRadius: 5 }} width="240px" height="135px" src={'https://cloudtv.akamaized.net/' + row.branding.background_images.landscape.replace('https://cloudtv.akamaized.net/', '')}></Image>
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      title: t('name'),
      dataKey: 'branding.name',
      width: '20%',
      cell: (row) => {
        return (
          <div>
            {t('app-name-colon')} <b>{row.branding.name}</b>
            <br />
            {t('package-id-colon')} <b>{row.branding.packageid}</b>
            <br />
            {t('service-name')} <b>{row.branding.service}</b>
            <br />
            {t('app-id-colon')} <b>{row.branding.appid}</b>
          </div>
        );
      },
    },
    {
      dataKey: 'manage',
      title: t('actions'),
      width: '60%',
      cell: (row) => {
        return (
          <>
            <Row style={{ minHeight: 60, justifyContent: 'flex-end' }}>
              <div className={'ant-card-hoverable'} onClick={(event) => (window.location.href = `${url}/details/branding/${row._id}`)} style={{ cursor: 'pointer', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff', border: '2px solid #efefef' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={faBrush} />
                    </div>
                    <div className="text-center" style={{ color: '#485Fe0', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                      {t('branding')}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'ant-card-hoverable'} onClick={(event) => (window.location.href = `${url}/details/settings/${row._id}`)} style={{ cursor: 'pointer', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff', border: '2px solid #efefef' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={faCog} />
                    </div>
                    <div className="text-center" style={{ color: '#485Fe0', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7, paddingLeft: 5, paddingRight: 5 }}>
                      {t('settings')}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'ant-card-hoverable'} onClick={(event) => (row.settings != undefined ? (window.location.href = `${url}/details/components/${row._id}`) : null)} style={{ cursor: row.settings != undefined ? 'pointer' : 'none', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff', border: '2px solid #efefef' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: row.settings != undefined ? '#485Fe0' : '#999' }} size={'xl'} icon={faBlockBrick} />
                    </div>
                    <div className="text-center" style={{ color: row.settings != undefined ? '#485Fe0' : '#999', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7, paddingLeft: 5, paddingRight: 5 }}>
                      {t('components')}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'ant-card-hoverable'} onClick={(event) => (row.components != undefined ? (window.location.href = `${url}/details/theme/${row._id}`) : null)} style={{ cursor: row.components != undefined ? 'pointer' : 'none', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff', border: '2px solid #efefef' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: row.components != undefined ? '#485Fe0' : '#999' }} size={'xl'} icon={faFillDrip} />
                    </div>
                    <div className="text-center" style={{ color: row.components != undefined ? '#485Fe0' : '#999', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7, paddingLeft: 5, paddingRight: 5 }}>
                      {t('theme')}
                    </div>
                  </div>
                </div>
              </div>

              <div className={'ant-card-hoverable'} onClick={(event) => (row.theme != undefined ? (window.location.href = `${url}/details/navigators/${row._id}`) : null)} style={{ cursor: row.theme != undefined ? 'pointer' : 'none', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff', border: '2px solid #efefef' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: row.theme != undefined ? '#485Fe0' : '#999' }} size={'xl'} icon={faListTree} />
                    </div>
                    <div className="text-center" style={{ color: row.theme != undefined ? '#485Fe0' : '#999', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7, paddingLeft: 5, paddingRight: 5 }}>
                      {t('navigation')}
                    </div>
                  </div>
                </div>
              </div>

              <div className={'ant-card-hoverable'} onClick={(event) => (row.components != undefined ? (window.location.href = `${url}/details/authentication/${row._id}`) : null)} style={{ cursor: row.components != undefined ? 'pointer' : 'none', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff', border: '2px solid #efefef' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: row.components != undefined ? '#485Fe0' : '#999' }} size={'xl'} icon={faShieldKeyhole} />
                    </div>
                    <div className="text-center" style={{ color: row.components != undefined ? '#485Fe0' : '#999', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                      {t('auth-flow')}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'ant-card-hoverable'} onClick={(event) => (row.components != undefined ? (window.location.href = `${url}/details/players/${row._id}`) : null)} style={{ cursor: row.components != undefined ? 'pointer' : 'none', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff', border: '2px solid #efefef' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: row.components != undefined ? '#485Fe0' : '#999' }} size={'xl'} icon={faYoutube} />
                    </div>
                    <div className="text-center" style={{ color: row.components != undefined ? '#485Fe0' : '#999', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                      {t('players')}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'ant-card-hoverable'} onClick={(event) => (row.components != undefined ? (window.location.href = `${url}/details/pages/${row._id}`) : null)} style={{ cursor: row.components != undefined ? 'pointer' : 'none', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff', border: '2px solid #efefef' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: row.components != undefined ? '#485Fe0' : '#999' }} size={'xl'} icon={faTableColumns} />
                    </div>
                    <div className="text-center" style={{ color: row.components != undefined ? '#485Fe0' : '#999', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                      {t('pages')}
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Row style={{ minHeight: 60, justifyContent: 'flex-end' }}>
              <div className={'hover'} onClick={(event) => (row.components != undefined ? (window.location.href = `${url}/details/webtv/${row._id}`) : null)} style={{ cursor: row.components != undefined ? 'pointer' : 'none', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff', border: '2px solid #efefef' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: row.components != undefined ? '#485Fe0' : '#999' }} size={'xl'} icon={faBrowser} />
                    </div>
                    <div className="text-center" style={{ color: row.components != undefined ? '#485Fe0' : '#999', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                      WebTV
                    </div>
                  </div>
                </div>
              </div>
              <div className={'ant-card-hoverable'} onClick={(event) => (row.components != undefined ? (window.location.href = `${url}/details/appupdates/${row._id}`) : null)} style={{ cursor: row.components != undefined ? 'pointer' : 'none', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff', border: '2px solid #efefef' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: row.components != undefined ? '#485Fe0' : '#999' }} size={'xl'} icon={faAndroid} />
                    </div>
                    <div className="text-center" style={{ color: row.components != undefined ? '#485Fe0' : '#999', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                      OTA
                    </div>
                  </div>
                </div>
              </div>
              <div className={'ant-card-hoverable'} onClick={() => (row.components != undefined ? cloneApplication(row) : null)} style={{ cursor: row.components != undefined ? 'pointer' : 'none', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff', border: '2px solid #efefef' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: row.components != undefined ? '#485Fe0' : '#999' }} size={'xl'} icon={faCopy} />
                    </div>
                    <div className="text-center" style={{ color: row.components != undefined ? '#485Fe0' : '#999', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                      {t('clone')}
                    </div>
                  </div>
                </div>
              </div>
              <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                <div className={'ant-card-hoverable'} style={{ cursor: 'pointer', width: 64, border: '2px solid #efefef', margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff' }}>
                  <Button
                    style={{ padding: 0 }}
                    onClick={() => {
                      confirm({
                        title: 'Are you sure you want to delete this app?',
                        okText: 'Yes',
                        okType: 'danger',
                        cancelText: 'No',
                        onOk() {
                          deleteApplication(row);
                        },
                        onCancel() {},
                      });
                    }}
                    className="mx-2 cursor-pointer"
                    type="text"
                  >
                    <div className="text-center col-4 justify-content-center" style={{ width: 64, height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                      <div>
                        <div className="text-center">
                          <FontAwesomeIcon style={{ color: 'crimson' }} size={'xl'} icon={faTrash} />
                        </div>
                        <div className="text-center" style={{ color: 'crimson', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                          {t('delete-app')}
                        </div>
                      </div>
                    </div>
                  </Button>
                </div>
              </VisibleForRole>
            </Row>
          </>
        );
      },
    },
  ];

  const deleteApplication = async (row: any) => {
    setFetchingUserInterfaces(true);
    let response = await user_interfaces.Delete_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', row.branding.packageid).APPBUILDER.APP,
      },
      _id: row._id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('app-deleted-successfully'),
      });

      let response_ = await user_interfaces.List_Applications(tableSearchState);
      if (response_ && response_.status_code === StatusCodes.OK) {
        if (response_.data.data.length > 0) {
          setGUITemplates(response_.data.data.filter((d) => d.deleted != true));
          setPagination(response_.data);
        }
      }
    } else CustomNotification({ error: response.errors });
    setFetchingUserInterfaces(false);
  };

  const [tableSearchState, setTableSearchState] = useState<IABP_Lists>({
    pageNumber: 1,
    pageSize: 10,
    query_params: {
      cms: deployment?.name ?? '',
      instance: clientName,
    },
  });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);
  const getUserInterfacesList = async (tableSearchState: IABP_Lists) => {
    setFetchingUserInterfaces(true);
    let response = await user_interfaces.List_Applications(tableSearchState);
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data.data.length > 0) {
        setGUITemplates(response.data.data.filter((d) => d.deleted != true));
        setPagination(response.data);
      }
    }
    setFetchingUserInterfaces(false);
  };

  useEffectOnce(() => {
    getUserInterfacesList(tableSearchState);
  });

  const cloneApplication = async (row: any) => {
    setFetchingUserInterfaces(true);
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: row._id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setApplication(resp);
      setShowCloningModal(true);
    }
    setFetchingUserInterfaces(false);
  };
  const cloningFinished = () => {
    setShowCloningModal(false);
    history.push(url);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('your-apps')}</h2>
          <p> {t('create-your-own-multidevice-apps-for-ios-tvos-android-androidtv-firetv-tizen-smarttv-lg-smarttv-and-webtv-with-our-award-wining-app-builder')}</p>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start">
          <span className="ml-3 ml-md-4 "></span>
        </div>
        <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start">
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={{ pathname: `${url}/add` }}>
            {t('add-app')}
          </Link>
        </div>
      </div>
      <div className="row">
        {showCloningModal && <Clone application={application} cloningFinished={cloningFinished} onCancel={() => setShowCloningModal(false)}></Clone>}
        <div className="col-12 mt-4">
          <Alert className="mt-4 mb-4" message={t('webtv-version')} showIcon description={t('the-current-version-of-webtv-is-10-0-87-therefore-make-sure-your-iframe-configuration-is-up-to-date-and-check-it')} type="info" />
          <ReactBCTable isLoading={fetchingUserInterfaces} totalRecords={pagination.count} pageSize={pagination.pageSize} tableId={'user_interfaces_tables'} columns={rbcColumns} data={guiTemplates} />
        </div>
      </div>
    </div>
  );
};

export default UserInterfaces;
