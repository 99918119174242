import { Button, Form, Input, notification } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cmsService from '../../../../../../../services/areas/content/cams/cams';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import ParentalControl from '../../../../../../../common/components/Content/Parental/Parental';
import { Cams } from '../../../../../../../interfaces/forms/areas/content/cams/cams';
import { useEffectOnce } from 'react-use';
import Placement from '../../../../../../../common/components/Content/Placement/Placement';
import Tags from '../../../../../../../common/components/Content/Tags/Tags';
import Providers from '../../../../../../../common/components/Content/Providers/Providers';

import { AuthContext } from '../../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../../constants/TVMSRoles';

const DetailsCam = (data: any) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [placement, setPlacement] = useState([] as any);
  const [isAdding, setIsAdding] = useState(false);
  const [tags, setTags] = useState([] as any);
  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;

  const [parental, setParental] = useState({
    enabled: false,
    rating: [] as any,
  });
  const [form] = useForm();
const { t } = useTranslation();
  const [provider, setProvider] = useState('');

  useEffect(() => {
    setPlacement(data.data.placement);
    setParental(data.data.parental);
    setTags(data.data.tags);
    setProvider(data.data.provider?.name);
    form.setFieldsValue(data.data);
  }, [data]);

  const handleSubmit = async (values: Cams) => {
    setIsAdding(true);
    if (data.data.payperview != undefined) {
      if (data.data.payperview.currencies != undefined) {
        if (data.data.payperview.currencies.length > 0) {
          if (data.data.payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          }
        }
      }
    }
    values = {
      _id: _id,
      ...data.data,
      ...values,
      parental: parental,
      placement: placement,
      provider: {
        name: isProvider ? AuthState?.user?.name : provider,
      },
      tags: tags,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    
    let response = await cmsService.Update_Cam(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('update-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  useEffectOnce(() => {
    form.setFieldsValue(data);
  });

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('details')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-2 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('cam-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('number')}</label>
                <Form.Item className="col-md-7" name="number">
                  <Input defaultValue={0} type={'number'} className="form-control bg-white font-size-14 text-default p-3rem" placeholder="101" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('notes')}</label>
                <Form.Item className="col-md-7" name="meta">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('use-this-to-add-notes-to-your-cam')} />
                </Form.Item>
              </div>
            </div>
            {!isProvider &&
            <Providers data={provider} setProvider={setProvider} />
}
            <ParentalControl data={parental} setParentalControl={setParental} />
            <Placement data={placement} setPlacement={setPlacement} />
            <Tags data={tags} setTags={setTags} />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-cam')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default DetailsCam;
