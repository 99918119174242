import { Button, Form, Image, Modal, Select, Switch, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';
import Providers from '../../../../../../common/components/Content/Providers/Providers';
import MovieStreams from '../../../../../../common/components/Content/MovieStreams/MovieStreams';
import ParentalControl from '../../../../../../common/components/Content/Parental/Parental';
import Placement from '../../../../../../common/components/Content/Placement/Placement';
import Security from '../../../../../../common/components/Content/Security/Security';
import Ads from '../../../../../../common/components/Content/Ads/Ads';
import PayPerView from '../../../../../../common/components/Content/PayPerView/PayPerView';
import { IMSNameParams } from '../../../../../dashboard';
import { useParams } from 'react-router-dom';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { StatusCodes } from 'http-status-codes';
import { STORAGE_ENDPOINTS } from '../../../../../../services/storage_paths';
import cmsMovies from '../../../../../../services/areas/content/movies/movies';
import cmsMoviesBundles from '../../../../../../services/areas/content/movies/bundles';
import { MW_BASE_DB_CURRENCY, MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base';
import { getActorsContent, getCurrenciesContent, getDescriptionsContent, getTagsContent } from '../../../actions/importer/helper';

type IUpdateLanguageModalProps = {
  visible: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  initialData: any;
};

const MovieModal = (props: IUpdateLanguageModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  let [form] = useForm();
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const currenciesDeployment = (deployment?.currencies as unknown as MW_BASE_DB_CURRENCY[]) || [];
  const [security, setSecurity] = useState([] as any);
  const [payperview, setPayPerView] = useState([] as any);
  const [placement, setPlacement] = useState([] as any);
  const [parental, setParental] = useState({
    enabled: false,
    rating: [] as any,
  });
  const [ads, setAds] = useState([] as any);
  const [provider, setProvider] = useState('No Provider');
  const [isAdding, setIsAdding] = useState(false);
  const [data, setData] = useState([] as any);
const {t} = useTranslation();
  const [bundles, setBundles] = useState([] as any);
  const [addToCats, setAddToCats] = useState(false);
  const [createCats, setCreateCats] = useState(false);


  const addMovies = async (values) => {
    setIsAdding(true);

    let tags = getTagsContent(true, languagesDeployment, [], 0, true, data, '');
    let tagArray = tags.tags;

    let actors = [] as any;
    if (data.crew != null) {
      var crew = [] as any;
      var crewIn = data.crew.actor;
      if (crewIn != undefined) {
        crewIn.forEach((element, index) => {
          if (index < 5) {
            crew.push({
              name: element.name,
              image: element.image,
            });
          }
        });
        actors = crew;
      } else {
        actors = [];
      }
    } else {
      actors = [];
    }

    let currenciesOut = getCurrenciesContent([], currenciesDeployment, 0, data, [], values);
    let descriptionsOut = getDescriptionsContent([], true, true, data, languagesDeployment, 0, true);

    var valuesIn: any = {
      name: data.title,
      released: data.year,
      runtime: data.runTime,
      writers: data.crew?.writers ?? '',
      language: '',
      studio: data.productionCompanies[0] ?? '',
      rating: data.rating,
      imdbid: data.imdbID,
      tmdbid: data.tmdbID,
      provider: {
        name: provider,
      },
      translations: descriptionsOut,
      tags: tagArray,
      actors: actors,
      images: {
        poster: data.images.poster ?? '',
        background: data.images.backdrop ?? '',
        //logo: getImage(row, values.logo, values.prefix_url, columns[values.logo] == 'IMDB_Auto_Fill', 'logo', movieIMDB),
      },
      placement: placement,
      parental: parental,
      security: security,
      payperview: {
        enable: values.payperview_enable,
        rule: values.payperview_ruleid,
        currencies: currenciesOut,
      },
      ads: ads,
      streams: values.streams,
      addcats: addToCats,
      createcats: createCats,
      bundles: values.bundles,
      avoidDoubles: false,
    };

    values = {
      movies: [valuesIn],
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.MOVIES.IMAGES,
        remove: false,
        overwrite: false,
        update: false,
        extend: false,
      },
    };

    let response = await cmsMovies.Import_Movies(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
    onSubmit();
  };

  useEffectOnce(() => {
    setData(initialData);
    getMovieBundles();
  });

  const getMovieBundles = async () => {
    let response = await cmsMoviesBundles.List_Movie_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };
  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={960} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{t('add-movie')}</h4>
            <Form form={form} onFinish={(values) => addMovies(values)} onFinishFailed={(errorInfo) => {}}>
              <div className="mt-3">
                <Image width="100px" src={initialData?.images?.poster}></Image>
              </div>
              <div className="card-bg-light pl-5 pt-4 mt-4 pb-5">
                <h4 className="text-primary mb-2 mt-4">{t('automation')}</h4>
                <p>{t('select-the-bundles-you-want-to-add-your-content-to-and-if-categories-should-be-generated-based-on-the-tags')}</p>
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('bundle-selection')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'bundles'}>
                      <Select
                        showSearch
                        mode="multiple"
                        className="default-select"
                        allowClear
                        filterOption={(input, option) => {
                          return ((option?.name as string) ?? '').toLowerCase().includes(input.toLowerCase());
                        }}
                        options={bundles}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('add-to-categories')}</label>
                  <div className="col-md-5">
                    <Form.Item extra={t('add-your-imported-content-to-the-selected-bundles-categories-based-on-the-imported-tags')} name={'add_content_bundle'}>
                      <Switch className="ch-switch" checked={addToCats} defaultChecked={true} onChange={setAddToCats} />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('create-categories')}</label>
                  <div className="col-md-5">
                    <Form.Item extra={t('create-categories-in-the-selected-bundles-from-the-imported-tags-and-add-the-imported-content-to-it')} name={'create_categories_tags'}>
                      <Switch className="ch-switch" checked={createCats} defaultChecked={true} onChange={setCreateCats} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <Providers setProvider={setProvider} />
              <MovieStreams />
              <ParentalControl setParentalControl={setParental} />
              <Placement setPlacement={setPlacement} />
              <Security setSecurity={setSecurity} />
              <Ads setAds={setAds} />
              <PayPerView service_name={deployment ?? ''} clientName={clientName} setPayPerView={setPayPerView} />
              <div className="col-md-12 p-0 pt-5">
                <Button loading={isAdding} htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('add-movie')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MovieModal;
