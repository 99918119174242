import { Button, DatePicker, Form, Input, Select, Switch, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import cmsService from '../../../../../../services/areas/content/automations/automations';
import { IMSNameParams } from '../../../../../dashboard';
import { ContentContext } from '../../../../../../contexts/contentContext';
import dayjs from 'dayjs';
import Translations from '../../../../../../common/components/Content/Ribbons/Translations';

import cmsAlbums from '../../../../../../services/areas/content/albums/bundles';
import cmsPodcasts from '../../../../../../services/areas/content/podcasts/bundles';
import cmsMovies from '../../../../../../services/areas/content/movies/bundles';
import cmsShorts from '../../../../../../services/areas/content/shorts/bundles';
import cmsSeries from '../../../../../../services/areas/content/series/bundles';
import cmsChannels from '../../../../../../services/areas/content/channels/bundles';
import cmsRadios from '../../../../../../services/areas/content/radio/bundles';
import cmsCams from '../../../../../../services/areas/content/cams/bundles';
import cmsCourses from '../../../../../../services/areas/content/courses/bundles';

import cmsAlbumsList from '../../../../../../services/areas/content/albums/lists';
import cmsPodcastsList from '../../../../../../services/areas/content/podcasts/lists';
import cmsMoviesList from '../../../../../../services/areas/content/movies/lists';
import cmsShortsList from '../../../../../../services/areas/content/shorts/lists';
import cmsSeriesList from '../../../../../../services/areas/content/series/lists';
import cmsChannelsList from '../../../../../../services/areas/content/channels/lists';
import cmsRadiosList from '../../../../../../services/areas/content/radio/lists';
import cmsCamsList from '../../../../../../services/areas/content/cams/lists';
import cmsCoursesList from '../../../../../../services/areas/content/courses/lists';

import { ICMS_Lists } from '../../../../../../interfaces/forms/common/ICMS_Lists';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import { useEffectOnce, useSetState } from 'react-use';
import AutomationValues from '../../../../../../common/components/Content/AutomationValues/AutomationValues';

const AddAlbum = () => {
  const history = useHistory();
  const params = useParams<IMSNameParams & { deployment: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const [isAdding, setIsAdding] = useState(false);
  const [form] = useForm();
const { t } = useTranslation();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const { deployment } = useContext(ContentContext);
  const [selectedContentType, setSelectedContentType] = useState('');
  const [selectedRuleType, setSelectedRuleType] = useState('');
  const [selectedRuleOrigin, setSelectedRuleOrigin] = useState('');
  const [startDate1, setStartDate1] = useState(dayjs());
  const [endDate1, setEndDate1] = useState(dayjs().add(99 , 'year'));
  const [startDate, setStartDate] = useState(dayjs().unix());
  const [endDate, setEndDate] = useState(dayjs().add(99 , 'year').unix());
  const [enableRibbon, setEnableRibbon] = useState(false);
  const [translations, setTranslations] = useState([] as any);
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);
  const [bundles, setBundles] = useState([] as any);
  const [lists, setLists] = useState([] as any);
  const [categories, setCategories] = useState([] as any);
  const [selectedLists, setSelectedLists] = useState([] as any);
  const [selectedBundles, setSelectedBundles] = useState([] as any);
  const [selectedCategories, setSelectedCategories] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [storedLists, setStoredLists] = useState([] as any);
  const [storedBundles, setStoredBundles] = useState([] as any);
  const [storedCategories, setStoredCategories] = useState([] as any);
  const [ruleValues, setRuleValues] = useState([] as any);
  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 1,
    pageSize: 10,
    query_params: {
      instance: clientName,
      cms: deployment?.name ?? '',
    },
  });

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    values = {
      _id: params._id,
      name: values.name,
      content_type: selectedContentType,
      rule_type: selectedRuleType,
      rule_values: ruleValues,
      rule_origin: selectedRuleOrigin,
      ut_start: startDate,
      ut_end: endDate,
      lists: selectedLists,
      bundles: selectedCategories,
      ribbon_enable: enableRibbon,
      ribbon_translations: translations,
      ribbon_days: values.ribbon_days,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        _id: params._id,
      },
    };
    

    let response = await cmsService.Update_Automation(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      var resp = response.data as any;
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };
  const getAutomationDetails = async () => {
    let response = await cmsService.Details_Automation({
      query_params: {
        instance: clientName,
        cms: params.deployment ?? '',
      },
      _id: params._id,
    });
    if (response.status_code === StatusCodes.OK) {
      const resp = response.data as any;

      await getLists(resp.content_type);
      await getBundles(resp.content_type);

      form.setFieldsValue(resp);

      setEnableRibbon(resp.ribbon_enable);
      setTranslations(resp.ribbon_translations);
      setRuleValues(resp.rule_values);
      setSelectedRuleOrigin(resp.rule_origin);
      setSelectedRuleType(resp.rule_type);
      setSelectedContentType(resp.content_type);

      var storedLists_ = [] as any;
      resp.lists.forEach((list_) => {
        setSelectedListsLocal(list_.list_id);
        storedLists_.push(list_.list_id);
      });

      var storedBundles_ = [] as any;
      resp.bundles.forEach((bundle_) => {
        var test = storedBundles_.find((s) => s == bundle_.bundle_id);
        if (test == undefined) {
          storedBundles_.push(bundle_.bundle_id);
        }
      });

      setStoredBundles(storedBundles_);
      setStoredLists(storedLists_);
    }
    setIsAdding(false);
  };
  useEffect(() => {
    storedBundles.forEach((bundle_) => {
      var bundles_ = selectedBundles;
      bundles_.push({ _id: bundle_ });
      var cats = [] as any;
      var storedCats_ = [] as any;
      bundles.forEach((bundle, index) => {
        var test = bundles_.find((i) => i._id == bundle._id);
        if (test != undefined) {
          bundle.categories.forEach((category) => {
            category.bundle_name = bundle.name;
            category.bundle_id = bundle._id;
            category.category_id = category._id;
            cats.push(category);
            storedCats_.push(category._id);
          });
        }
      });
      setSelectedCategories(cats);
      setStoredCategories(storedCats_);
      setCategories(cats);
      setSelectedBundles(bundles_);
    });
  }, [bundles]);

  useEffectOnce(() => {
    getAutomationDetails();
  });
  const getLists = (type) => {
    switch (type) {
      case "Albums":
        (async () => await getAlbumLists(tableSearchState))();
        break;
      case "Podcasts":
        (async () => await getPodcastLists(tableSearchState))();
        break;
      case "Channels":
        (async () => await getChannelLists(tableSearchState))();
        break;
      case "Radios":
        (async () => await getRadioLists(tableSearchState))();
        break;
      case 'EpgTv':
        (async () => await getChannelLists(tableSearchState))();
        break;
      case 'EpgRadio':
        (async () => await getRadioLists(tableSearchState))();
        break;
      case "Movies":
        (async () => await getMovieLists(tableSearchState))();
        break;
      case "Shorts":
        (async () => await getShortsLists(tableSearchState))();
        break;
      case "Courses":
        (async () => await getCoursesLists(tableSearchState))();
        break;
      case "Series":
        (async () => await getSeriesLists(tableSearchState))();
        break;
      case "Cams":
        (async () => await getCamLists(tableSearchState))();
        break;
    }
  };
  const getAlbumLists = async (list: ICMS_Lists) => {
    let response = await cmsAlbumsList.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setLists(response.data.data);
    }
  };
  const getChannelLists = async (list: ICMS_Lists) => {
    let response = await cmsChannelsList.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setLists(response.data.data);
    }
  };
  const getCamLists = async (list: ICMS_Lists) => {
    let response = await cmsCamsList.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setLists(response.data.data);
    }
  };
  const getRadioLists = async (list: ICMS_Lists) => {
    let response = await cmsRadiosList.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setLists(response.data.data);
    }
  };
  const getMovieLists = async (list: ICMS_Lists) => {
    let response = await cmsMoviesList.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setLists(response.data.data);
    }
  };
  const getSeriesLists = async (list: ICMS_Lists) => {
    let response = await cmsSeriesList.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setLists(response.data.data);
    }
  };
  const getShortsLists = async (list: ICMS_Lists) => {
    let response = await cmsShortsList.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setLists(response.data.data);
    }
  };
  const getPodcastLists = async (list: ICMS_Lists) => {
    let response = await cmsPodcastsList.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setLists(response.data.data);
    }
  };
  const getCoursesLists = async (list: ICMS_Lists) => {
    let response = await cmsCoursesList.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setLists(response.data.data);
    }
  };

  const getBundles = (type) => {
    switch (type) {
      case "Albums":
        (async () => await getAlbumBundles(tableSearchState))();
        break;
      case "Podcasts":
        (async () => await getPodcastBundles(tableSearchState))();
        break;
      case "Channels":
        (async () => await getChannelBundles(tableSearchState))();
        break;
      case "Radios":
        (async () => await getRadioBundles(tableSearchState))();
        break;
      case 'EpgTv':
        (async () => await getChannelBundles(tableSearchState))();
        break;
      case 'EpgRadio':
        (async () => await getRadioBundles(tableSearchState))();
        break;
      case "Movies":
        (async () => await getMovieBundles(tableSearchState))();
        break;
      case "Shorts":
        (async () => await getShortsBundles(tableSearchState))();
        break;
      case "Courses":
        (async () => await getCoursesBundles(tableSearchState))();
        break;
      case "Series":
        (async () => await getSeriesBundles(tableSearchState))();
        break;
      case "Cams":
        (async () => await getCamBundles(tableSearchState))();
        break;
    }
  };

  const getAlbumBundles = async (list: ICMS_Lists) => {
    let response = await cmsAlbums.List_Album_Bundless(list);
    if (response && response.status_code === StatusCodes.OK) {
      setBundles(response.data);
      setPagination(response.data);
    }
  };
  const getChannelBundles = async (list: ICMS_Lists) => {
    let response = await cmsChannels.List_Channel_Bundless(list);
    if (response && response.status_code === StatusCodes.OK) {
      setBundles(response.data);
      setPagination(response.data);
    }
  };
  const getCamBundles = async (list: ICMS_Lists) => {
    let response = await cmsCams.List_Cam_Bundless(list);
    if (response && response.status_code === StatusCodes.OK) {
      setBundles(response.data);
      setPagination(response.data);
    }
  };
  const getRadioBundles = async (list: ICMS_Lists) => {
    let response = await cmsRadios.List_Radio_Bundless(list);
    if (response && response.status_code === StatusCodes.OK) {
      setBundles(response.data);
      setPagination(response.data);
    }
  };
  const getMovieBundles = async (list: ICMS_Lists) => {
    let response = await cmsMovies.List_Movie_Bundless(list);
    if (response && response.status_code === StatusCodes.OK) {
      setBundles(response.data);
      setPagination(response.data);
    }
  };
  const getSeriesBundles = async (list: ICMS_Lists) => {
    let response = await cmsSeries.List_Series_Bundless(list);
    if (response && response.status_code === StatusCodes.OK) {
      setBundles(response.data);
      setPagination(response.data);
    }
  };
  const getShortsBundles = async (list: ICMS_Lists) => {
    let response = await cmsShorts.List_Shorts_Bundless(list);
    if (response && response.status_code === StatusCodes.OK) {
      setBundles(response.data);
      setPagination(response.data);
    }
  };
  const getPodcastBundles = async (list: ICMS_Lists) => {
    let response = await cmsPodcasts.List_Podcast_Bundless(list);
    if (response && response.status_code === StatusCodes.OK) {
      setBundles(response.data);
      setPagination(response.data);
    }
  };
  const getCoursesBundles = async (list: ICMS_Lists) => {
    let response = await cmsCourses.List_Course_Bundless(list);
    if (response && response.status_code === StatusCodes.OK) {
      setBundles(response.data);
      setPagination(response.data);
    }
  };

  const setSelectedContentTypeLocal = (value) => {
    setIsLoading(true);
    setSelectedContentType(value);
    getBundles(value);
    getLists(value);
    setIsLoading(false);
  };
  const setDeselectedBundlesLocal = (bundle_id) => {
    var bundles_ = selectedBundles.filter((i) => i._id != bundle_id);
    var cats = [] as any;
    bundles.forEach((bundle) => {
      var test = bundles_.find((i) => i._id == bundle._id);
      if (test != undefined) {
        bundle.categories.forEach((category) => {
          category.bundle_name = bundle.name;
          category.bundle_id = bundle._id;
          category.category_id = category._id;
          cats.push(category);
        });
      }
    });
    setCategories(cats);
    setSelectedBundles(bundles_);
  };
  const setSelectedBundlesLocal = (bundle_id) => {
    var bundles_ = selectedBundles;
    bundles_.push({ _id: bundle_id });
    var cats = [] as any;
    bundles.forEach((bundle, index) => {
      var test = bundles_.find((i) => i._id == bundle._id);
      if (test != undefined) {
        bundle.categories.forEach((category) => {
          category.bundle_name = bundle.name;
          category.bundle_id = bundle._id;
          category.category_id = category._id;
          cats.push(category);
        });
      }
    });
    setCategories(cats);
    setSelectedBundles(bundles_);
  };

  const setSelectedListsLocal = (list_id) => {
    var lists_ = selectedLists;
    lists_.push({ list_id: list_id });
    setSelectedLists(lists_);
  };
  const setDeselectedListsLocal = (list_id) => {
    var lists_ = selectedLists.filter((i) => i.list_id != list_id);
    setSelectedLists(lists_);
  };

  const setSelectedCategoriesLocal = (index) => {
    var bundles_ = selectedCategories;
    var bundle = categories[index];
    bundles_.push(bundle);
    setSelectedCategories(bundles_);
  };
  const setDeselectedCategoriesLocal = (index) => {
    var bundles_ = selectedCategories.splice(index, 1);
    setSelectedCategories(bundles_);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-automations-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('details-automation')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('setup-an-automation-to-place-newly-added-content')}</p>
        </div>
      </div>
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('general')}</h4>
            <div className="card-bg-light pl-5 pt-5">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-6"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('automation-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('content-type')}</label>
                <Form.Item extra={t('set-the-content-type-for-your-workflow')} className="col-md-6" name={'content_type'}>
                  <Select className="default-select" onSelect={setSelectedContentTypeLocal} defaultActiveFirstOption={true} defaultValue={t('select-a-content-type')}>
                    <Select.Option value={"Albums"} key={1}>
                      {t('albums')}
                    </Select.Option>
                    <Select.Option value={"Cams"} key={2}>
                      {t('cams')}
                    </Select.Option>
                    <Select.Option value={"Channels"} key={3}>
                      {t('channels')}
                    </Select.Option>
                    <Select.Option value={"Courses"} key={4}>
                      {t('courses')}
                    </Select.Option>
                    <Select.Option value={"Movies"} key={5}>
                      {t('movies')}
                    </Select.Option>
                    <Select.Option value={"Shorts"} key={6}>
                      {t('shorts')}
                    </Select.Option>
                    <Select.Option value={"Podcasts"} key={7}>
                      {t('podcasts')}
                    </Select.Option>
                    <Select.Option value={"Radio"} key={8}>
                      {t('radio')}
                    </Select.Option>
                    <Select.Option value={"Series"} key={9}>
                      {t('series')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('content-origin')}</label>
                <Form.Item extra={t('set-the-content-type-for-your-workflow')} className="col-md-6" name={'rule_origin'}>
                  <Select className="default-select" onSelect={setSelectedRuleOrigin} defaultActiveFirstOption={true} defaultValue={t('select-a-content-origin')}>
                    <Select.Option value={"Manually"} key={1}>
                      {t('manually')}
                    </Select.Option>
                    <Select.Option value={"Workflow"} key={2}>
                      {t('workflow')}
                    </Select.Option>
                    <Select.Option value={"Import"} key={3}>
                      {t('import')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <h4 className="text-primary mt-5 mb-3">{t('matching')}</h4>
            <div className="card-bg-light pl-5 pt-5">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('match-type')}</label>
                <Form.Item extra={t('set-the-content-type-for-your-workflow')} className="col-md-6" name={'rule_type'}>
                  <Select className="default-select" onSelect={setSelectedRuleType} defaultActiveFirstOption={true} defaultValue={t('select-a-match-type')}>
                    <Select.Option value={"Tags"} key={1}>
                      {t('tags')}
                    </Select.Option>
                    <Select.Option value={"Provider"} key={2}>
                      {t('provider')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('match-values')}</label>
                <AutomationValues data={ruleValues} setTags={setRuleValues} />
              </div>
            </div>
            <h4 className="text-primary mt-5 mb-3">{t('automation-start-end')}</h4>
            <div className="card-bg-light pl-5 pt-5">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('start-date')}</label>
                <Form.Item initialValue={startDate1} className="form-group pb-0 ">
                  <DatePicker
                    value={startDate1}
                    defaultValue={startDate1}
                    showToday
                    className="form-control height-48 bg-white font-size-14 text-default"
                    onChange={(value) => {
                      setStartDate(dayjs(value).unix());
                    }}
                  />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('end-date')}</label>
                <Form.Item initialValue={endDate1} className="form-group pb-0 ">
                  <DatePicker
                    value={endDate1}
                    showToday
                    defaultValue={endDate1}
                    className="form-control height-48 bg-white font-size-14 text-default"
                    onChange={(value) => {
                      setEndDate(dayjs(value).unix());
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <h4 className="text-primary mt-5 mb-3">{t('placement')}</h4>
            <div className="card-bg-light pl-5 pt-5">
              {lists.length > 0 && (
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('list-placement')}</label>
                  <Form.Item extra={t('select-a-list-to-place-the-new-content')} className="col-md-6">
                    <Select defaultValue={storedLists} mode="multiple" className="default-select" onSelect={setSelectedListsLocal} onDeselect={setDeselectedListsLocal}>
                      {lists.map((list, index) => {
                        return (
                          <Select.Option value={list._id} key={index}>
                            {list.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              )}
              {bundles.length > 0 && (
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('bundle-placement')}</label>
                  <Form.Item extra={t('select-a-bundle-to-place-the-new-content')} className="col-md-6">
                    <Select defaultValue={storedBundles} onDeselect={setDeselectedBundlesLocal} loading={isLoading} mode="multiple" className="default-select" onSelect={setSelectedBundlesLocal}>
                      {bundles.map((bundle, index) => {
                        return (
                          <Select.Option value={bundle._id} key={index}>
                            {bundle.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              )}
              {categories.length > 0 && (
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('bundle-category')}</label>
                  <Form.Item extra={t('select-a-category-to-place-the-new-content')} className="col-md-6">
                    <Select defaultValue={storedCategories} mode="multiple" className="default-select" onDeselect={setDeselectedCategoriesLocal} onSelect={setSelectedCategoriesLocal}>
                      {categories.map((category, index) => {
                        return (
                          <Select.Option value={category._id} key={index}>
                            {category.translations ? category.translations[0].description : 'No Cats'} {t('open-parenthesis')}{category.bundle_name}{t('close-parenthesis')}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              )}
            </div>
            <h4 className="text-primary mt-5 mb-3">{t('ribbons')}</h4>
            <div className="card-bg-light pl-5 pt-5">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('enable-ribbons')}</label>
                <Form.Item extra={t('enable-ribbons-on-your-newly-added-content-ribbons-are-banner-with-texts-such-as-new-movies-or-new-episodes')} className="col-md-5" name={['ribbon_enable']} valuePropName="checked">
                  <Switch
                    className="ch-switch mt-2"
                    checked={enableRibbon}
                    defaultChecked={false}
                    onChange={() => {
                      setEnableRibbon(!enableRibbon);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('texts')}</label>
                <div className="col-md-6">
                  <Translations data={translations} setTranslations={setTranslations} service_name={deployment?.name} clientName={clientName}></Translations>
                </div>
              </div>

              <div className="row mt-5">
                <label className="col-md-3 col-form-label">{t('duration')}</label>
                <Form.Item
                  className="col-md-6"
                  extra={t('the-api-should-return-data-in-json-format')}
                  name="ribbon_days"
                  rules={[
                    {
                      required: true,
                      message: t('number-of-days-is-required'),
                    },
                  ]}
                >
                  <Input type={'number'} className="form-control bg-white font-size-14 text-default p-3rem" placeholder="3" />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-automation')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddAlbum;
