import { useTranslation } from 'react-i18next';
import { Input, Item } from '../../../../../common/components/form';
import { Communication } from '../../../../../interfaces/forms/areas/billing/communication';
import { Card } from 'antd';

export const CommunicationsDetails = ({ data }: { data: Communication }) => {
  const {t} = useTranslation();
    return (
      <>
        <Card title={t('general')}>
          <Item className="col-md-8" label={t('timestamp')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={new Date(data.timestamp * 1000).toString()} disabled />
          </Item>
          <Item className="col-md-8" label={t('source')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.source} disabled />
          </Item>
          <Item className="col-md-8" label={t('type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.type} disabled />
          </Item>
          <Item className="col-md-8" label={t('template-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.template_name} disabled />
          </Item>
          <Item className="col-md-8" label={t('template-id')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.template_id} disabled />
          </Item>
          <Item className="col-md-8" label={t('product-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.product_name} disabled />
          </Item>
          <Item className="col-md-8" label={t('product-id')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.product_id} disabled />
          </Item>
          <Item className="col-md-8" label={t('email')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.email} disabled />
          </Item>
          <Item className="col-md-8" label={t('mobile')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.mobile} disabled />
          </Item>
        </Card>
      </>
    );
  };