import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { CopyBlock, monoBlue } from 'react-code-blocks';
import { Button, Select } from 'antd';
import { useContext, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import appService from '../../../../../services/areas/appbuilder/applications/application';
import { AppBuilderContext } from '../../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../dashboard';
import { CustomNotification } from '../../../../../common/components/Notification';
import { useEffectOnce } from 'react-use';

const Authentication = () => {
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(AppBuilderContext);
  const { id } = useParams<any>();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const [versions, setVersions] = useState([
    {
      name: 'v10.2.66',
    },
  ]);
  const [code, setCode] = useState('');
  const [application, setApplication] = useState([] as any);

  const handleFormFill = async () => {
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',

        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      
      setApplication(resp);
    } else {
      CustomNotification({
        error: response.errors,
      });
    }
  };
  useEffectOnce(() => {
    (async () => await handleFormFill())();
  });

  const setSelectedVersion = (value: any) => {
    setCode(
      `<iframe sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts" autoplay fullscreen seamless allow="fullscreen" referrerpolicy="no-referrer" style="border: none; display: block;background: #000;border: none;height: 100vh;width: 100vw;" loading="lazy" id="myiframe" src="https://cloudtv.akamaized.net/donotremove/tvms/webtv/` +
        value +
      `/index.html?packageid=` +
        encodeURI(application.branding.packageid) +
        `"><p>Your browser does not support iframes.</p></iframe>` +
        `<script language="javascript"> var iframe = document.getElementById("myiframe");iframe.src = iframe.src +  window.location.search.replace('?','&');</script>`
    );
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row-md-12">
        <div className="row-md-12">
          <div className="col-md-12 mb-2">
            <Link to={`${redirectUrl}`}>Back to Applications</Link>
            <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
            <h2 className="page-title mt-4 ">WebTV</h2>
            <p>Integrate WebTV experience on your website via an iFrame.</p>
            <p className="h6 font-weight-normal line-height-26 mb-4"></p>
          </div>
        </div>
        <div className="card p-5">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                  <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                    Version
                  </h4>
                  <p>Select the version of WebTV you want to use.</p>
                  <div className="row">
                    <label className="col-md-2 col-form-label">Version</label>
                    <Select onChange={setSelectedVersion} className="default-select col-md-5" placeholder="- Select Service -">
                      {versions.map((version) => {
                        return (
                          <Select.Option value={version.name} key={version.name}>
                            {version.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            {code != '' && (
              <div className="row">
                <div className="col-md-12">
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      Test Live
                    </h4>
                    <p>Press the button to test your app via our WebTV test URL. This is not the URL to go live with its just for testing purposes.</p>
                    <div className="text-right">
                      <Button onClick={() => window.open('https://test-gui.com/?packageid=' + application.branding.packageid)} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                        Open WebTV
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {code != '' && (
              <div className="row">
                <div className="col-md-12">
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      HTML Code
                    </h4>
                    <p>Copy the code below and paste this on a dedicated page on your website.</p>
                    <div className="row-md-12 ml-2 mr-2">
                      <CopyBlock wrapLines text={code} language={'html'} theme={monoBlue} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Authentication;
