import { Form, Input, Modal } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../../pages/dashboard';
import Translations from '../Translations/Translations';
import EpisodeStreams from '../EpisodeStreams/EpisodeStreams';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { ContentContext } from '../../../../contexts/contentContext';

export type IAddSongModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  reset: boolean;
  dataIn?: [];
};
const EpisodeModel = (props: IAddSongModalProps) => {
  const { visible, onSubmit, onCancel, dataIn } = props;
  const [data, setData] = useState(dataIn) as any;
  const params = useParams<IMSNameParams>();
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [episode, setEpisode] = useState([] as any);
  const [translations, setTranslations] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();

  const handleSubmit = (values: any) => {
    var translations_ = [] as any;
    translations.map((translation: any) => {
      var new_ = {
        name: translation.name,
        description: translation.description,
      };
      translations_.push(new_);
    });
    onSubmit({
      update: data.name != undefined ? true : false,
      name: values.name,
      number: values.number,
      translations: translations_,
      duration: values.duraction ?? 0,
      images: {
         widescreen: data.name != undefined && episode.url == undefined ? episode?.replace('https://cloudtv.akamaized.net', '') : episode.url?.replace('https://cloudtv.akamaized.net', ''),
      },
      streams: {
        series: values.streams.series,
      },
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [props.reset]);

  useEffect(() => {
    

    if (data.name != undefined) {
      setEpisode(data.images.widescreen);
      setTranslations(data.translations);
      form.setFieldsValue(data);
    }
  }, [data]);

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={960} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary mb-5">{data != undefined ? "Edit" : "Add"} {t('episode')}</h4>
            <Form
              form={form}
              initialValues={{
                name: '',
                url: '',
                add_to_top: false,
              }}
              onFinish={handleSubmit}
              onFinishFailed={(errorInfo) => {}}
            >
              <label className="col-form-label">{t('name')}</label>
              <Form.Item
                className="form-group pb-0"
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('please-enter-lesson-name'),
                  },
                ]}
              >
                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('enter-episode-name')} />
              </Form.Item>

              <label className="col-form-label">{t('episode-number')}</label>
              <Form.Item
                className="form-group pb-0"
                name="number"
                rules={[
                  {
                    required: true,
                    message: t('number-is-required'),
                  },
                ]}
              >
                <Input type={'number'} className="form-control bg-white font-size-14 text-default" placeholder="0" />
              </Form.Item>

              <label className="col-form-label">{t('episode-length-seconds')}</label>
              <Form.Item
                className="form-group pb-0"
                name="duration"
                rules={[
                  {
                    required: true,
                    message: t('duration-is-required'),
                  },
                ]}
              >
                <Input min={0} type={'number'} className="form-control bg-white font-size-14 text-default" placeholder="0" />
              </Form.Item>

              <Translations data={translations} service_name={params.service_name} clientName={params.clientName} setTranslations={setTranslations} />

              <EpisodeStreams />

              <ImageUploader
                onSubmit={(values: any) => {
                  setEpisode(values);
                }}
                initialData={{
                  uploadName: t('episode-image'),
                  uploadType: 'widescreen',
                  uploadedImage: data.translations != undefined && episode ? episode : null,
                  path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES,
                }}
              />

              <div className="col-md-12 p-0 mt-3">
                {data.translations == undefined && (
                  <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('add-episode')}
                  </button>
                )}
                {data.translations != undefined && (
                  <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('update-episode')}
                  </button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EpisodeModel;
