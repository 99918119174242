import { ChartDataSeries, ChartInput } from "@mwaretv/database/build/backend/util/queryGenerator";
import axios from "axios";
import { t } from "i18next";
import dayjs from 'dayjs';

class ChartInputs_Service {

  Get_ChartInputs = async (clientName: string, deploymentName: string, serviceName: string, area: string): Promise<ChartInput[]> => {

    let jsonFileName: string;
  
    if (area === "content" || area === "apps") {
  
      jsonFileName = `dashboard_${area}_input.json`;
  
    }
    else {
  
      jsonFileName = `dashboard_${area}_${serviceName}_input.json`;
  
    }
  
    const path = 'https://cloudtv.akamaized.net/' + clientName + '/' + deploymentName + '/dashboards/' + jsonFileName;
    let { data } = await axios.get(path);

    data?.forEach(chartInput => {
      chartInput.series.forEach(seriesInstance => {
        this.formatDates(seriesInstance);
        this.translateCountAndEmptyValues(seriesInstance);
      });
    });

    data = data.sort((a, b) => {
      if (a?.order == null && b?.order == null) return 0;
      if (a?.order == null) return 1;
      if (b?.order == null) return -1;
      return a?.order - b?.order;
    });

    return data;

  };

  private formatDates = (series: ChartDataSeries): void => {

    const dateTimeGroupingUnit = series.dataKeyDateTimeFormat;

    if (dateTimeGroupingUnit === "dayOfMonth") {
      series.data?.forEach(item => {
        item.key = dayjs(item.key).format('D MMM YYYY');
      });
    }
    else if (dateTimeGroupingUnit === "week") {
      series.data?.forEach(item => {
        const year = item.key.substring(0, 4);
        const week = parseInt(item.key.substring(5));

        item.key = `${t('week')} ${week} ${year}`;
      });
    }
    else if (dateTimeGroupingUnit === "month") {
      series.data?.forEach(item => {
        item.key = dayjs(item.key).format('MMM YYYY');
      });
    }

  }

  private translateCountAndEmptyValues = (series: ChartDataSeries): void => {

    series.data?.forEach(data => {

      if (data.key === "@count") {
        data.key = t('chart-design-total');
      }
      else if (data.key == null || data.key.length === 0) {
        data.key = t('empty');
      }
    });

  }

}

export default new ChartInputs_Service();
