import React from 'react';

import { FormSection, Input, Item } from '../../../../../../../../common/components/form';
import { noSpecialCharacters, noWhiteSpaces, serviceNameValidator } from '../../../../../../../../utils/validators';
import { useTranslation } from 'react-i18next';

const InstanceSettings: React.FC = () => {
  const {t} = useTranslation();
  return (
    <FormSection title={t('setup-names')}>
      <div className="row-md-12">
        <h5 style={{ marginTop: 10 }}>Explanation:</h5>
        <p>
          {t('an-instance-consists-of-two-parts-a-deployment-and-services')}
          <br />
          <br />
          {t('deployment-content-and-apps')}
          <br />
          {t('services-customers-billing-marketing-and-reports')}
          <br />
          <br />
          {t('you-can-add-mulitple-services-that-all-share-the-same-deployment-content-and-apps')}
          <br />
        </p>
      </div>
      <div className="row pt-5">
        <label className="col-md-3 col-form-label">{t('deployment-name')}</label>
        <Item
          name={['name']}
          className="col-md-5"
          rules={[
            { required: true, message: t('deployment-name-is-required') },
            { max: 15, message: t('please-choose-a-shorter-name') },
            { validator: serviceNameValidator, message: t('deployment-name-can-only-contain-alphanumeric-characters-and-hyphens-a-z-a-z-zero-nine')},
          ]}
          extra={t('name-can-be-like-usa-content-only-alphanumeric-characters-and-hyphens-are-allowed')}
        >
          <Input />
        </Item>
      </div>
      <div className="row pt-3">
        <label className="col-md-3 col-form-label">{t('service-name')}</label>
        <Item
          name={['serviceName']}
          className="col-md-5"
          rules={[
            { required: true, message: t('service-name-is-required') },
            { max: 15, message: t('please-choose-a-shorter-name') },
            { validator: serviceNameValidator, message: t('service-name-can-only-contain-alphanumeric-characters-and-hyphens-a-z-a-z-zero-nine') },
          ]}
          extra={t('name-can-be-like-usa-customers-only-alphanumeric-characters-and-hyphens-are-allowed')}
        >
          <Input />
        </Item>
      </div>
    </FormSection>
  );
};

export default InstanceSettings;
