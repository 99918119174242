import axiosInstance from '../../../utils/axios';
import { API_ENDPOINTS } from '../../_APIConstants';
import { Product } from '../../../interfaces/forms/areas/billing/products/product';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';
import { Customer } from '../../../interfaces/forms/areas/customers/customers/customer';
import { CustomerLog } from '../../../interfaces/forms/areas/customers/customerLog';
import { Order } from '../../../interfaces/forms/areas/billing/order';

import { RuleGroupType } from 'react-querybuilder';
import { Ticket } from '../../../interfaces/forms/areas/customers/tickets/ticket';

import axios from 'axios';
import { Transaction } from '../../../interfaces/forms/areas/billing/transaction/transaction';
import { Communication } from '../../../interfaces/forms/areas/billing/communication';

const { CUSTOMERS: CUSTOMER_ENDPONTS } = API_ENDPOINTS;
const { CUSTOMERS: ENDPOINTS } = CUSTOMER_ENDPONTS;

type Params = { instance: string; cms: string; crm: string; path?: string };

type PaginationParams = {
  page?: number;
  limit?: number;
  offset?: number;
};

type ResponseFormat<T> = {
  docs: T;
};

type PaginatedResults<T> = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  page: number;
  pagingCounter: number;
  prevPage: null | number;
  totalDocs: number;
  totalPages: number;
} & ResponseFormat<T>;

type ListParams = {
  params: Params & PaginationParams;
  query: string | RuleGroupType;
};

export const list = ({ params, query }: ListParams): Promise<AxiosResponse<PaginatedResults<Customer[]>>> => {
  return axiosInstance.get<PaginatedResults<Customer[]>>(ENDPOINTS.LIST, { params });
};

export type AddParams = { params: Params } & { data: Customer };

export type PutParams = { params: Params } & { data: Product; id: string };

export type PatchParams = { params: Params } & { data: Partial<Customer>; id: string };

type DeleteParams = { params: Params; id: string };

type GetParams = { params: Params; id: string };

export const useCustomerOrders = ({ params, id }: { params: Params & PaginationParams; id: string }) => useQuery(['customers', 'get', 'orders', params, id], () => axiosInstance.get<PaginatedResults<Order[]>>(`${ENDPOINTS.GET}/${id}/orders`, { params }));

export const useCustomerTickets = ({ params, id }: { params: Params & PaginationParams; id: string }) => useQuery(['customers', 'get', 'tickets', params, id], () => axiosInstance.get<PaginatedResults<Ticket[]>>(`${ENDPOINTS.GET}/${id}/tickets`, { params }));

export const useCustomerLogs = ({ params, id }: { params: Params & PaginationParams; id: string }) => useQuery(['customers', 'get', 'logs', params, id], () => axiosInstance.get<PaginatedResults<CustomerLog[]>>(`${ENDPOINTS.GET}/${id}/logs`, { params }));

export const useCustomerSearch = ({ params, query }: ListParams) => useQuery(['customers', 'list', params, query], () => axiosInstance.post<PaginatedResults<Customer[]>>(ENDPOINTS.SEARCH, { query }, { params }));

export const useCustomer = ({ params, id }: GetParams) => useQuery(['customers', 'list', params, id], () => axiosInstance.get<Customer>(`${ENDPOINTS.GET}/${id}`, { params }));

export const useDeleteMutation = ({ params }: Omit<DeleteParams, 'id'>, options?: Omit<UseMutationOptions<any, unknown, string, unknown>, 'mutationFn'>) => useMutation((id: string) => axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params }), options);

export const useAddMutation = ({ params }: Omit<AddParams, 'data'>, options?: Omit<UseMutationOptions<any, unknown, Customer, unknown>, 'mutationFn'>) => useMutation((values) => axiosInstance.post<Customer>(ENDPOINTS.ADD, values, { params }), options);

export const useImportMutation = ({ params }: Omit<AddParams, 'data'>, options?: Omit<UseMutationOptions<any, unknown, Customer, unknown>, 'mutationFn'>) => useMutation((values) => axiosInstance.post<Customer>(ENDPOINTS.IMPORT, values, { params }), options);

export const usePatchMutation = ({ params, id }: Omit<PatchParams, 'data'>, options?: Omit<UseMutationOptions<any, unknown, Customer, unknown>, 'mutationFn'>) => useMutation((values) => axiosInstance.patch<Customer>(`${ENDPOINTS.PATCH}/${id}`, values, { params }), options);

export const getTransactions = (client: string, deployment: string, service: string, customerId: string, page: number, limit: number) =>
  axiosInstance.get<PaginatedResults<Transaction[]>>(`${ENDPOINTS.GET}/${customerId}/transactions`, {
    params: { instance: client, cms: deployment, crm: service, page, limit },
  });

export const getCommunications = (client: string, deployment: string, service: string, customerId: string, page: number, limit: number) =>
  axiosInstance.get<PaginatedResults<Communication[]>>(`${ENDPOINTS.GET}/${customerId}/communications`, {
    params: { instance: client, cms: deployment, crm: service, page, limit },
  });

const toAlphaNumeric = (input) => {
  if (input === '' || input == null) {
    return '';
  }
  input = input.toString().replace(/\s/g, '');
  return input.toString().replace(/[^A-Za-z0-9]/g, '');
};

export const getDevices = async (client: string, deployment: string, service: string, username: string) => {
  let path = 'https://cloudtv.akamaized.net/' + client + '/' + deployment + '/' + service + '/users/' + toAlphaNumeric(username).split('').join('/') + '/devices.json';
  let response = await fetch(path);
  if (response.status === 200) {
    let data = await response.json();
    return data;
  } else {
    return [];
  }
};
