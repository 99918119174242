import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { IMSNameParams } from '../../../../../dashboard';
import cmsService from '../../../../../../services/areas/content/workflows/workflows';
import { StatusCodes } from 'http-status-codes';
import { Spin } from '../../../../../../common/components/spinner';
import { Button, DatePicker, Form, Input, InputRef, Select, Switch, Tag, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { ContentContext } from '../../../../../../contexts/contentContext';
import dayjs from 'dayjs';
import { Timezone } from '../../../../../../common/components/form';
import Paths from '../../../../../../common/components/Content/Paths/Paths';

const UserInterfaceAppDetails = () => {
  const { clientName } = useParams<IMSNameParams>();
  const params = useParams<IMSNameParams & { deployment: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const [selectedContentType, setSelectedContentType] = useState('');
  const [selectedWorkflowType, setSelectedWorkflowType] = useState('');
  const { deployment } = useContext(ContentContext);
  const [data, setData] = useState([] as any);
const {t} = useTranslation();
  const [mappingsData, setMappingsData] = useState([] as any);
  const [form] = useForm();

  const history = useHistory();
  const [basePath, setBasePath] = useState('');
  const [iterationPath, setIterationPath] = useState('');

  const handleFormFill = async () => {
    setIsLoading(true);
    const response = await cmsService.Details_Workflow({
      query_params: {
        instance: clientName,
        cms: params.deployment ?? '',
      },
      _id: params._id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      const resp = response.data as any;
      setData(resp);

      form.setFieldsValue(resp);
      setSelectedWorkflowType(resp.workflow_type);
      setSelectedContentTypeMappings(resp.content);
      setSelectedContentType(resp.content);

      if (resp.mappings != undefined) {
        var mappingsJson = JSON.parse(resp.mappings);
        setMappingsData(mappingsJson);
      }

      if (resp.iteration_path != undefined) {
        setIterationPath(resp.iteration_path);
      }

      await loadApiForMapping(resp.api, resp.base);
    }
  };

  const testWorkflow = async () => {
    const response = await cmsService.Test_Workflow({
      query_params: {
        instance: clientName,
        cms: params.deployment ?? '',
      },
      _id: params._id,
    });
    if (response && response.status_code === StatusCodes.OK) {
    }
  };

  useEffectOnce(() => {
    (async () => await handleFormFill())();
  });

  const handleSubmit = async (values: any) => {
    options.forEach((option) => {
      if (option.name == "Monetizing") {
        setMappings({
          ...mappings,
          vast_url_overide: values.vast_url_overide,
        });
      }
      if (option.name == "Security") {
        setMappings({
          ...mappings,
          drm_enabled_overide: values.drm_enabled_overide,
        });

        setMappings({
          ...mappings,
          flussonic_token_override: values.flussonic_token_override,
        });

        setMappings({
          ...mappings,
          legacy_token_override: values.legacy_token_override,
        });

        setMappings({
          ...mappings,
          drm_provider_override: values.drm_provider_override,
        });
      }
      if (option.name == "Widescreen & Backdrop Images") {
        setMappings({
          ...mappings,
          prefix_url: values.prefix_url,
        });
      }
      if (option.name == "Parental Control") {
        setMappings({
          ...mappings,
          parental_control_overide: values.parental_control_overide,
        });
      }
      if (option.name == "Placement") {
        setMappings({
          ...mappings,
          placement_end_overide: values.placement_end_overide,
        });

        setMappings({
          ...mappings,
          placement_start_overide: values.placement_start_overide,
        });

        setMappings({
          ...mappings,
          placement_supress_on_trial_override: values.placement_supress_on_trial_override,
        });
      }
    });
    setIsLoading(true);
    values = {
      name: values.name,
      schedule: values.schedule ?? '',
      api: values.api,
      base: basePath,
      iteration_path: iterationPath,
      content: selectedContentType,
      workflow_type: selectedWorkflowType, //hour, day, week, month
      workflow_value: values.workflow_value ?? '',
      workflow_timezone: values.timezone,
      mappings: JSON.stringify(mappings),
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      _id: params._id,
    };
    let response = await cmsService.Update_Workflow(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsLoading(false);
  };

  const [options, setOptions] = useState([] as any);
  const [keys, setKeys] = useState([] as any);
  const [options1, setOptions1] = useState([] as any);
  const [keys1, setKeys1] = useState([] as any);
  const [options2, setOptions2] = useState([] as any);
  const [keys2, setKeys2] = useState([] as any);

  const [tab1, setTab1] = useState('');
  const [tab2, setTab2] = useState('');
  const [tab3, setTab3] = useState('');

  const [channelKeys] = useState([{ name: t('name')}, { name: t('number')}, { name: t('notes')}, { name: t('tags') }]);
  const [channelOptions] = useState([{ name: t('monetizing')}, { name: t('placement')}, { name: t('security')}, { name: t('rtv-streams')}, { name: t('guide')} , { name: 'InteractiveTV' }, { name: t('rtv-images')}, { name: t('parental-control')} ]);
  const [camKeys] = useState([{ name: t('name')}, { name: t('number')}, { name: t('notes')}, { name: t('tags') }]);
  const [camOptions] = useState([{ name: t('monetizing')}, { name: t('placement')}, { name: t('security')}, { name: t('rtv-streams')} , { name: 'InteractiveTV' }, { name: t('rtv-images')}, { name: t('parental-control') }]);
  const [radioKeys] = useState([{ name: t('name')}, { name: t('number')}, { name: t('notes')}, { name: t('tags') }]);
  const [radioOptions] = useState([{ name: t('placement')}, { name: t('security')}, { name: t('rtv-streams')}, { name: t('guide')}, { name: t('radio-images') }]);

  const [albumKeys] = useState([{ name: t('name')}, { name: t('artist')}, { name: t('tags') }]);
  const [albumOptions] = useState([{ name: t('security')}, { name: t('icon-images')}, { name: t('monetizing')}, { name: t('placement')}, { name: t('descriptions') }]);
  const [songKeys] = useState([{ name: t('album')}, { name: t('name')}, { name: t('producer')}, { name: t('writer')}, { name: t('duration') }]);
  const [songOptions] = useState([{ name: t('song-stream-url') }]);

  const [podcastKeys] = useState([{ name: t('name')}, { name: t('creator')}, { name: t('tagline')}, { name: t('release-period')}, { name: t('average-length')}, { name: t('language')}, { name: t('tags')}]);
  const [podcastOptions] = useState([{ name: t('security')}, { name: t('icon-images')}, { name: t('monetizing')}, { name: t('placement')}, { name: t('descriptions') }]);
  const [podsKeys] = useState([{ name: t('podcast')}, { name: t('name')}, { name: t('producer')}, { name: t('writer')}, { name: t('duration') }]);
  const [podsOptions] = useState([{ name: t('podcast-stream-url')}]);

  const [seriesKeys] = useState([{ name: t('name')}, { name: t('tags') }]);
  const [seriesOptions] = useState([{ name: t('series-image')}, { name: t('monetizing')}, { name: t('security')}, { name: t('parental-control')}, { name: t('placement') }]);
  const [seasonsKeys] = useState([{ name: t('series')} , { name: '_Id' }, { name: t('name')}, { name: t('number')}, { name: t('rating')}, { name: t('released')}, { name: t('language')}, { name: t('studio')}, { name: t('actors')}, { name: t('tags') }]);
  const [seasonOptions] = useState([{ name: t('poster-and-backdrop-images')}, { name: t('descriptions') }]);
  const [episodeKeys] = useState([{ name: t('season')} , { name: '_Id' }, { name: t('name')}, { name: t('number')}]);
  const [episodeOptions] = useState([{ name: t('episode-image')}, { name: t('episode-stream-url')}, { name: t('description-episodes')}]);

  const [courseKeys] = useState([{ name: t('name')}, { name: t('released')}, { name: t('difficulty')}, { name: t('language')}, { name: t('teachers') }]);
  const [courseOptions] = useState([{ name: t('monetizing')}, { name: t('security')}, { name: t('parental-control')}, { name: t('placement')}, { name: t('poster-backdrop-and-widescreen-images')}, { name: t('descriptions') }]);
  const [lessonKeys] = useState([{ name: t('course')}, { name: t('name')}, { name: t('number')}, { name: t('duration') }]);
  const [lessonOptions] = useState([{ name: t('lesson-stream-url')}, { name: t('lesson-image')}, { name: t('descriptions') }]);

  const [movieKeys] = useState([{ name: t('name')}, { name: t('released')}, { name: t('runtime')}, { name: t('writers')}, { name: t('language')}, { name: t('studio')}, { name: t('rating')}, { name: t('actors')}, { name: t('tags') }]);
  const [movieOptions] = useState([{ name: t('monetizing')}, { name: t('security')}, { name: t('poster-and-backdrop-images')}, { name: t('parental-control')}, { name: t('placement')}, { name: t('movie-streams')}, { name: t('descriptions') }]);

  const [shortsKeys] = useState([{ name: t('name')}, { name: t('studio')}, { name: t('rating')}, { name: t('tags')} ]);
  const [shortsOptions] = useState([{ name: t('monetizing')}, { name: t('security')}, { name: t('widescreen-and-backdrop-images')}, { name: t('parental-control')}, { name: t('placement')}, { name: t('short-streams')}, { name: t('descriptions') }]);

  const [columns1, setColumns1] = useState([] as any);
  const [path, setPath] = useState([] as any);
  const [response, setResponse] = useState([] as any);
  const [mappings, setMappings] = useState([] as any);

  const setSelectedContentTypeMappings = (type: any) => {
    switch (type) {
      case "Movies":
        setKeys(movieKeys);
        setOptions(movieOptions);
        setTab1(t('movies'));
        setTab2('');
        setTab3('');
        break;
      case "Shorts":
        setKeys(shortsKeys);
        setOptions(shortsOptions);
        setTab1(t('shorts'));
        setTab2('');
        setTab3('');
        break;
      case "Series":
        setKeys(seriesKeys);
        setOptions(seriesOptions);
        setKeys1(seasonsKeys);
        setOptions1(seasonOptions);
        setKeys2(episodeKeys);
        setOptions2(episodeOptions);
        setTab1(t('series'));
        setTab2(t('seasons'));
        setTab3(t('episodes'));
        break;
      case "Seasons":
        setKeys(seasonsKeys);
        setOptions(seasonOptions);
        setKeys1(episodeKeys);
        setOptions1(episodeOptions);
        setTab1(t('seasons'));
        setTab2(t('episodes'));
        setTab3('');
        break;
      case "Albums":
        setKeys(albumKeys);
        setOptions(albumOptions);
        setKeys1(songKeys);
        setOptions1(songOptions);
        setTab1(t('albums'));
        setTab2(t('songs'));
        setTab3('');
        break;
      case "Podcasts":
        setKeys(podcastKeys);
        setOptions(podcastOptions);
        setKeys1(podsKeys);
        setOptions1(podsOptions);
        setTab1(t('podcasts'));
        setTab2(t('pods'));
        setTab3('');
        break;
      case "Channels":
        setKeys(channelKeys);
        setOptions(channelOptions);
        setTab1(t('channels'));
        setTab2('');
        setTab3('');
        break;
      case "Courses":
        setKeys(courseKeys);
        setOptions(courseOptions);
        setKeys1(lessonKeys);
        setOptions1(lessonOptions);
        setTab1(t('courses'));
        setTab2(t('lessons'));
        setTab3('');
        break;
      case "Radio":
        setKeys(radioKeys);
        setOptions(radioOptions);
        setTab1(t('radios'));
        setTab2('');
        setTab3('');
        break;
      case "Cams":
        setKeys(camKeys);
        setOptions(camOptions);
        setTab1(t('cams'));
        setTab2('');
        setTab3('');
        break;
    }
  };

  const getDefaulValueJsonColumn = (value: any) => {
    if (data.mappings != undefined) {
      // var search = data.mappings.find((m) => m.key == value);
      // if (search != undefined) {
      //   
      //   return search.value;
      // }
    }
  };

  const loadApiForMapping = async (api, base) => {
    let response = await fetch(api);
    if (response.status == 200) {
      let data = await response.json();

      if (base != undefined && base != '') {
        setBasePath(base);
        base = base.slice(0, -1);
        setResponse(data[base]);
        var paths_ = Object.keys(data[base]);
        setPath(paths_);
      } else {
        var paths = Object.keys(data);
        setPath(paths);
        setResponse(data);
      }
      setIsLoading(false);
    }
  };

  const optionsDivs = (key_: any, index: any, columns: any, addition: string) => {
    switch (key_.name) {
      case "Guide":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>


              <div key={index} className="row pb-5">
                <label className="col-md-3 col-form-label"></label>
                <div className="col-md-5">
                  <Form.Item name={'epg_url_id' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div key={index} className="row pb-5">
                <label className="col-md-3 col-form-label">{t('surpress-playing-on-trials')}</label>
                <div className="col-md-5">
                  <Form.Item name={'epg_url_channel' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );

      case "Placement":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('start-time')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'placement_start_overide' + addition} initialValue={getDefaulValueJsonColumn('placement_start_overide')}>
                      <DatePicker value={dayjs()} defaultValue={dayjs()} showToday className="form-control height-48 bg-white font-size-14 text-default" />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('end-time')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'placement_end_overide' + addition} initialValue={getDefaulValueJsonColumn('placement_end_overide')}>
                      <DatePicker value={dayjs().add(99, 'year')} defaultValue={dayjs().add(99, 'year')} showToday className="form-control height-48 bg-white font-size-14 text-default" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('surpress-playing-on-trials')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'placement_supress_on_trial_override' + addition} initialValue={getDefaulValueJsonColumn('placement_supress_on_trial_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          </div>
        );
      case "Monetizing":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              {selectedContentType != "Albums" && selectedContentType != "Podcasts" && selectedContentType != "Radio" && (
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('vast-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'vast_url_overide' + addition} initialValue={getDefaulValueJsonColumn('vast_url_overide')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com" />
                    </Form.Item>
                  </div>
                </div>
              )}
            </>
          </div>
        );
      case "Security":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-akamai-token')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'akamai_token_override' + addition} initialValue={getDefaulValueJsonColumn('akamai_token_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-flussonic-token')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'flussonic_token_override' + addition} initialValue={getDefaulValueJsonColumn('flussonic_token_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div key={index} className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-legacy-token')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'legacy_token_override' + addition} initialValue={getDefaulValueJsonColumn('legacy_token_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-drm')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'drm_enabled_overide'} initialValue={getDefaulValueJsonColumn('drm_enabled_overide')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('drm-provider')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'drm_provider_override' + addition} initialValue={getDefaulValueJsonColumn('drm_provider_override')}>
                      <Select className="default-select" placeholder={t('select-provider')} defaultActiveFirstOption={true}>
                        <Select.Option key={'buydrm'} value={'buydrm'}>
                          BuyDRM
                        </Select.Option>
                        <Select.Option key={'irdeto'} value={'irdeto'}>
                          {t('irdeto')}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          </div>
        );
      case "RTV Streams":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">"Standard"</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream_Standard'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">iOS/tvOS</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream_Apple'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">Tizen/Webos</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream_SmartTV'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
            </>
          </div>
        );
      case "Movie Streams":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('stream-url')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={t('stream')} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
            </>
          </div>
        );
      case "Short Streams":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('stream-url')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={t('stream')} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
            </>
          </div>
        );

      case "Lesson Stream Url" :
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('stream-url')}</label>
              <div className="col-md-9">
                <Paths setMappingPath={setMappingPathLocal} mapping={t('stream')} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case "Episode Stream Url":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('stream-url')}</label>
              <div className="col-md-9">
                <Paths setMappingPath={setMappingPathLocal} mapping={t('stream')} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case "Song Stream Url":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('stream-url')}</label>
              <div className="col-md-9">
                <Paths setMappingPath={setMappingPathLocal} mapping={t('stream')} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case "Podcast Stream Url":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('stream-url')}</label>
              <div className="col-md-9">
                <Paths setMappingPath={setMappingPathLocal} mapping={t('stream')} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case "Description Episodes":
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              {/* {extraSheets.length == 0 && (
                  <div key={index} className="row pb-5">
                    <div className="row-md-12">
                      <h4 className="text-primary">{key_.name}</h4>
                      <p>Should the offered description in the Excel not match the needed Languages in the Deployment we will use this description and your chosen language to translate to the correct description, this only works if you have the AI Translation option switch on, else we will use the first description and import this.</p>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <label className="col-md-3 col-form-label">Description Episodes</label>
                        <div className="col-md-5">
                          <Form.Item name={'description_episodes' + addition}>
                            <Select className="default-select" defaultActiveFirstOption={true}>
                              {columns1.map((el: any, index: any) => {
                                return (
                                  <Select.Option value={index} key={index}>
                                    {el}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <label className="col-md-3 col-form-label">Description Language</label>
                        <div className="col-md-5">
                          <Form.Item name={'description_language_episodes' + addition}>
                            <Select className="default-select" defaultActiveFirstOption={true}>
                              {languagesDeployment.map((el: any, index: any) => {
                                return (
                                  <Select.Option value={el.name} key={el.name}>
                                    {el.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
              {/* {extraSheets.length > 0 && (
                  <div className="row">
                    <div className="col-md-9">
                      <h4 className="text-primary">{key_.name}</h4>
                      <p>No mapping needed, the importer will automatically read your second excel sheet for the available languages and import the descriptions accordingly. Should the offered description in the Excel not match the needed languages in the Deployment we will use the first description and translate to the correct language, this is if you have the AI Translation option switch on, else we will use the first description and import this.</p>
                    </div>

                  </div>
                )} */}
            </div>
          </>
        );

      case "Descriptions" :
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('descriptions')}</label>
              <div className="col-md-9">
                <Paths setMappingPath={setMappingPathLocal} mapping={t('description')} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case 'InteractiveTV':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-catchuptv')}</label>
                <div className="col-md-5">
                  <Form.Item name={'catchuptv_enabled' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={t('use-override')} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'catchuptv_enabled_overide' + addition}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-recordings')}</label>
                <div className="col-md-5">
                  <Form.Item name={'recordings_enabled' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={t('use-override')} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'recordings_enabled_overide' + addition}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('transcoder-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_url' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={t('use-override')} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_url_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://transcoder.com" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('transcoder-user')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_user' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={t('use-override')} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_user_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('transcoder-pass')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_pass' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={t('use-override')} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_pass_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('transcoder-user')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_retention' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={t('use-override')} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_retention_overide' + addition}>
                      <Input type={'number'} className="form-control bg-white font-size-14 text-default" placeholder="365" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-pausetv')}</label>
                <div className="col-md-5">
                  <Form.Item name={'pausetv_enabled' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={t('use-override')} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'pausetv_enabled_overide' + addition}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('alternative-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'alternative_url' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={t('use-override')} key={999999}>
                        Use_Override
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'alternative_url_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com" />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          </div>
        );
      case "RTV Images":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('square-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={t('square')} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('tile-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={t('tile')} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case "Radio Images":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('square-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={t('square')} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('tile-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={t('tile')} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('background-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={t('background')} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')} initialValue={getDefaulValueJsonColumn('prefix_url')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case "Poster & Backdrop Images":
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('poster-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={t('poster')} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('background-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={t('background')} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>

                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );

      case "Widescreen & Backdrop Images":
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={t('widescreen')} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('background-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={t('background')} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>

                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')} initialValue={getDefaulValueJsonColumn('prefix_url')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );

      case "Poster, Backdrop & Widescreen Images":
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('poster-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={t('poster')} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('background-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={t('background')} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={t('widescreen')} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );

      case "Icon Images":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('square-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={t('square')} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case "Level Images":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={t('widescreen')} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div key={index} className="row pb-5">
                <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case "Year Images":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={t('widescreen')} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case "Series Images":
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('widscreen-image')}</label>
                  <div className="col-md-5">
                    <Paths setMappingPath={setMappingPathLocal} mapping={t('widescreen')} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('poster-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={t('poster')} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );
      case "Lesson Images":
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={t('widescreen')} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );
      case "Episode Images":
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('image')}</label>
                  <div className="col-md-5">
                    <Paths setMappingPath={setMappingPathLocal} mapping={t('widescreen')} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );
      case "Parental Control":
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('parental-control')}</label>
              <div className="col-md-5">
                <div>
                  <Form.Item name={'parental_control_overide' + addition} initialValue={getDefaulValueJsonColumn('parental_control_overide')}>
                    <Switch className="ch-switch" defaultChecked={false} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const setMappingPathLocal = (mapping) => {
    setMappings({
      ...mappings,
      ...mapping,
    });
  };

  const setMappingBasePathLocal = (mapping) => {
    setBasePath(mapping.Base);
    var base = mapping.Base;
    base = base.slice(0, -1);
    setMappingsData([]);
    setIterationPath('');
    setResponse(response[base]);
    var paths = Object.keys(response[base]);
    setPath(paths);
  };

  const setMappingIterationPathLocal = (mapping) => {
    setIterationPath(mapping.IterationPath);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}`}>{t('back-to-workflow-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('workflow-details')}</h2>
          <p className="h6 font-weight-normal line-height-26"></p>
        </div>
      </div>

      <div className="row">
        {isLoading && (
          <div className="row text-center h-100 w-100">
            <div className="col-12 align-self-center">
              <Spin size="large" spinning={true} />
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="col-12 mt-3">
            <Form
              form={form}
              onFinish={(values: any) => {
                handleSubmit(values);
              }}
              onFinishFailed={(errorInfo) => {
                
              }}
            >
              <div className="card mt-4 pb-4">
                <div className="card-body card-ch-padding">
                  <h4 className="text-primary mt-5 mb-3">{t('workflow')}</h4>
                  <div className="card-bg-light pl-5 pt-3">
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('name')}</label>
                      <Form.Item
                        className="col-md-6"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t('name-is-required'),
                          },
                        ]}
                      >
                        <Input className="form-control bg-white font-size-14 text-default" placeholder={t('workflow-name')} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('api-url')}</label>
                      <Form.Item
                        className="col-md-6"
                        extra={t('the-api-should-return-data-in-json-format')}
                        name="api"
                        rules={[
                          {
                            required: true,
                            message: t('api-url-is-required'),
                          },
                        ]}
                      >
                        <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="https://yourcontentapi.com" />
                      </Form.Item>
                    </div>
                    <div className="row mb-5">
                      <label className="col-md-3 col-form-label">{t('api-base-path')}</label>
                      <div className="col-md-9">
                        <Paths setMappingPath={setMappingBasePathLocal} mapping={t('base')} response={response} path={path} datas={{ Base: basePath?.slice(0, -1) }}></Paths>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <label className="col-md-3 col-form-label">{t('api-iteration-path')}</label>
                      <div className="col-md-9">
                        <Paths setMappingPath={setMappingIterationPathLocal} mapping={'IterationPath'} response={response} path={path} datas={{ IterationPath: iterationPath?.slice(0, -1) }}></Paths>
                      </div>
                    </div>

                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('content-type')}</label>
                      <Form.Item extra={t('set-the-content-type-for-your-workflow')} className="col-md-6" name={'content'}>
                        <Select className="default-select" onSelect={setSelectedContentType} defaultActiveFirstOption={true}>
                          <Select.Option value={"Albums"} key={9}>
                            {t('albums')}
                          </Select.Option>
                          <Select.Option value={"Cams"} key={3}>
                            {t('cams')}
                          </Select.Option>
                          <Select.Option value={"Channels"} key={1}>
                            {t('channels')}
                          </Select.Option>
                          <Select.Option value={"Courses"} key={7}>
                            {t('courses')}
                          </Select.Option>
                          <Select.Option value={"Movies"} key={4}>
                            {t('movies')}
                          </Select.Option>
                          <Select.Option value={"Shorts"} key={4}>
                            {t('shorts')}
                          </Select.Option>
                          <Select.Option value={"Podcasts"} key={10}>
                            {t('podcasts')}
                          </Select.Option>
                          <Select.Option value={"Radio"} key={2}>
                            {t('radio')}
                          </Select.Option>
                          <Select.Option value={"Series"} key={5}>
                            {t('series')}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('workflow-scheduler')}</label>
                      <Form.Item className="col-md-6" name={'workflow_type'}>
                        <Select className="default-select" onSelect={setSelectedWorkflowType} defaultActiveFirstOption={true}>
                          <Select.Option value={"Day"} key={'Day'}>
                            {t('every-day-of-the-week')}
                          </Select.Option>
                          <Select.Option value={"Week"} key={'Week'}>
                            {t('every-week')}
                          </Select.Option>
                          <Select.Option value={"Month"} key={'Month'}>
                            {t('each-month')}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="row">
                      {selectedWorkflowType == "Week" && (
                        <>
                          <label className="col-md-3 col-form-label">{t('workflow-scheduler')}</label>
                          <Form.Item extra={t('which-day-in-the-week-should-the-workflow-run')} className="col-md-6" name={'workflow_value'}>
                            <Select className="default-select" defaultActiveFirstOption={true}>
                              <Select.Option value={'1'} key={'Sunday'}>
                                {t('sunday')}
                              </Select.Option>
                              <Select.Option value={'2'} key={'Monday'}>
                                {t('monday')}
                              </Select.Option>
                              <Select.Option value={'3'} key={'Tuesday'}>
                                {t('tuesday')}
                              </Select.Option>
                              <Select.Option value={'4'} key={'Wednesday'}>
                                {t('wednesday')}
                              </Select.Option>
                              <Select.Option value={'5'} key={'Thursday'}>
                                {t('thursday')}
                              </Select.Option>
                              <Select.Option value={'6'} key={'Friday'}>
                                {t('friday')}
                              </Select.Option>
                              <Select.Option value={'7'} key={'Saturday'}>
                                {t('saturday')}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </>
                      )}
                      {selectedWorkflowType == "Month" && (
                        <Form.Item
                          extra={t('which-day-in-the-month-should-the-workflow-run')}
                          className="col-md-6"
                          name="workflow_value"
                          rules={[
                            {
                              required: true,
                              message: t('day-is-required'),
                            },
                          ]}
                        >
                          <Input type={'number'} min={1} max={31} className="form-control bg-white font-size-14 text-default" placeholder="1-31" />
                        </Form.Item>
                      )}
                    </div>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('workflow-timezone')}</label>
                      <div className="col-md-6">
                        <Timezone name={['timezone']} extra={t('select-the-timezone-you-want-to-use-to-run-this-workflow')} />
                      </div>
                    </div>
                    {!isLoading && basePath != '' && (
                      <div>
                        <div className="card-bg-light p-5 mb-5 mr-5 bg-white">
                          <div className="row ">
                            <div className="col-md-6">
                              <h4 className="text-primary">{t('general')}</h4>
                            </div>
                          </div>
                          <div>
                            {keys.map((key: any, index: any) => {
                              return (
                                <div className="row" key={key.name}>
                                  <label className="col-md-3 col-form-label">{key.name}</label>
                                  <div className="col-md-9">
                                    <Paths setMappingPath={setMappingPathLocal} mapping={key.name} response={response} path={path} datas={mappingsData}></Paths>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {options.map((key_: any, index: any) => {
                          return optionsDivs(key_, index, columns1, '');
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-right">
                <Button onClick={() => testWorkflow()} disabled={isLoading} loading={isLoading} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                  {t('test-workflow')}
                </Button>
                <Button htmlType="submit" disabled={isLoading} loading={isLoading} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                  {t('update-workflow')}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserInterfaceAppDetails;
