import { Button, notification, Tooltip, Image, Col, Modal } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import ReactBCTable from '../../ReactBCTable';
import { IReactBCTableColumn } from '../../ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import confirm from 'antd/lib/modal/confirm';
import { faFilePlus, faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cmsCourses from '../../../../services/areas/content/series/series';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ICMS_Lists } from '../../../../interfaces/forms/common/ICMS_Lists';
import { TVMSRoles } from '../../../../constants/TVMSRoles';
import { AuthContext } from '../../../../context_api/AuthContext';

const SeasonAdd = (params: { dataIn; visible; onCancel; service_name; clientName }) => {
  const { visible, onCancel, dataIn } = params;
  const { url } = useRouteMatch();
  const baseUrl = `${url.split('/').slice(0, -3).join('/')}`;
  const [data, setData] = useState([] as any);

  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;

  const filterProvider = {
    id: 'provider.name',
    value: { filterValue: AuthState.user?.name ?? 'jasdfklafijaskdflas', filterType: 'text' },
  } as any;

  const filterValidationAccepted = {
    id: 'provider_status',
    value: { filterValue: 'accepted', filterType: 'text' },
  } as any;

  const filterValidationEmpty = {
    id: 'provider_status',
    value: { filterValue: '', filterType: 'text' },
  } as any;

  const filterValidationUndefined = {
    id: 'provider_status',
    value: { filterValue: 'undefined', filterType: 'undefined' },
  } as any;

  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);
  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 0,
    pageSize: 50,
    filters: isProvider ? [filterProvider] : [filterValidationUndefined, filterValidationEmpty, filterValidationAccepted],
    query_params: {
      cms: params.service_name ?? '',
      instance: params.clientName ?? '',
    },
  });

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const getSeasons = async (list: any) => {
    let response = await cmsCourses.List_Seasons({
      ...list,
      query_params: {
        instance: params.clientName,
        cms: params.service_name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      var seasons = [] as any;
      for (var season of response.data.data) {
        if (season) {
          // eslint-disable-next-line no-loop-func
          var test = dataIn?.seasons?.find((s) => s === season._id);
          if (test == undefined) {
            seasons.push(season);
          }
        }
      }

      setData(seasons);
      setPagination(response.data);
    }
  };
  useEffectOnce(() => {
    getSeasons(tableSearchState);
  });

  const updateSeries = async (season: any) => {
    var newSeasons = [...dataIn.seasons, season._id];
    let response = await cmsCourses.Update_Series({
      query_params: {
        instance: params.clientName,
        cms: params.service_name ?? '',
      },
      ...dataIn,
      seasons: newSeasons,
    });
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      var filteredSeasons = data.filter(s => s._id !== season._id);
      setData(filteredSeasons)
      dataIn.seasons.push(season)
      setIsLoading(false);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const columnsSeasons: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.poster}></Image>
        </div>
      ),
    },
    {
      title: t('season-name'),
      dataKey: 'name',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('season-number'),
      dataKey: 'number',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('actions'),
      dataKey: 'action',
      width: '20%',
      cell: (row: any, index: any) => (
        <Col style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip placement="top" title={`Delete Season`} key={'Delete'}>
            <Button
              className="mx-2 cursor-pointer"
              onClick={() =>
                confirm({
                  title: 'Are you sure want to add ' + row.name + ' Season ?',
                  okText: 'Yes',
                  okType: 'primary',
                  cancelText: 'No',

                  async onOk() {
                    await updateSeries(row);
                  },
                  onCancel() {},
                })
              }
              type="text"
            >
              <FontAwesomeIcon className="action-icon" icon={faFilePlus} />
              <div>Add Season</div>
            </Button>
          </Tooltip>
        </Col>
      ),
    },
  ];

  return (
    <Modal visible={visible} style={{ borderRadius: 5 }} width={960} footer={false} closable={true} onCancel={onCancel}>
      <div>
        <div className="row mb-4">
          <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start">
            <span className="ml-3 ml-md-4 "></span>
          </div>
        </div>
        {!isLoading && <ReactBCTable onChange={(pageNumber, pageSize, pageSort, filters) => getSeasons({ pageNumber, pageSize, pageSort, filters })} totalRecords={pagination.count} pageSize={pagination.pageSize} currentPage={pagination.pageNumber} tableId={'user_interfaces_tables'} columns={columnsSeasons} data={data} isLoading={isLoading} />}
      </div>
    </Modal>
  );
};
export default SeasonAdd;
